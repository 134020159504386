import React, { useState, useEffect } from 'react';
import { openLoginModal } from '../../store/actions/LoginModalActions';
import { useDispatch } from "react-redux";
import { Link } from 'react-router-dom';
import AxiosService from '../../utils/AxiosService';
import history from '../../history';
import styles from './RegistrationConfirmation.module.scss';
import { Container, Card, Row, Col } from 'react-bootstrap';
import Login from '../Login/Login';
import { toast } from 'react-toastify';

function RegistrationConfirmation(){

    const apiService = new AxiosService();
    const dispatch = useDispatch();
    const params = new URLSearchParams(window.location.search);
    const token = params.get("token");
    const [isLoading, setIsLoading] = useState(false);
    const [errorResponseStatus, setErrorResponseStatus] = useState(200);
    const [resendMailSuccess, setResendMailSuccess] = useState(false);
    const [isResendLoading, setIsResendLoading] = useState(false);

    function resendVerificationLink(){
        setIsResendLoading(true);
        apiService.resendVerificationLink(token).then(response => {
            setIsResendLoading(false);
            setResendMailSuccess(true)
        }).catch(error => {
            setIsResendLoading(false);
            setResendMailSuccess(false)
            toast.error(error.response.data.message,  {
                autoClose: 3000,
            })
        })
    }

    function errorResponseHandler(){
        if(errorResponseStatus === 400){
            return <>
                <h5 className='text-center my-4'>Oops! Invalid link.</h5>
                <button onClick={() => {history.push("/home")}}>Go to Search</button>
            </>
        }
        else if(errorResponseStatus === 406){
            return <>
                <h5 className='text-center my-4'>Link expired!</h5>
                <button onClick={() => resendVerificationLink()}>Resend verification link</button>
            </>
        }
        else{
            return <>
                <h5 className='text-center my-4'>Something went wrong.</h5>
                <button onClick={() => {history.push("/home")}}>Go to Search</button>
            </>
        }
    }

    useEffect(() => {
        setIsLoading(true);
        function getConfirmation(){
            apiService.registerConfirm(token).then((response) => {
                setIsLoading(false);
                if(response.data === "Successfully Registered"){
                    history.push('/home');
                }
                setErrorResponseStatus(200);
            }).catch((error) => {
                setIsLoading(false)
                const response = error.response;
                setErrorResponseStatus(response.status);
                toast.error(error?.response?.data?.message,  {
                    autoClose: 3000,
                })

            })
        }
        getConfirmation();
    }, [token])

    return( 

        <Container fluid >
            <Row className='vh-100 align-items-center'>
                <Col md={{offset: 4, span: 4}}>
                    <Card className='p-3 my-3'>

                        <img src={process.env.PUBLIC_URL + '/logos/aeLogo.png'} alt='OrthoPublish logo'></img>
                        
                        {isLoading ? 
                            <h5 className='text-center my-4'>Authenticating, Please wait...</h5>
                            : errorResponseStatus === 200 ? 
                                <>
                                    <h5 className='text-center my-4'>Thank you for registering!</h5> 
                                    <button onClick={() => dispatch(openLoginModal())}>Login to continue</button>
                                </>
                                : isResendLoading ? <h5 className='text-center my-4'>Loading... Please wait</h5> : resendMailSuccess ? <h5 className='text-center my-4'>Verification email is on the way. Make sure to check your junk mail if in case you don’t receive it to your inbox.</h5> : errorResponseHandler()}
                    
                    </Card>
                </Col>
            </Row>
            <Login />
        </Container>
    )
}

export default RegistrationConfirmation;