import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { toast } from "react-toastify";
import { ProgressLoader } from "../../../../../components/Loader/CommonLoader";
import RemirrorEditor from "../../../../../components/RemirrorEditor/RemirrorEditor";
import AxiosService from "../../../../../utils/AxiosService";
import "./StepFiveForm.scss";
import TooltipButton from "../../../../../components/TooltipButton/TooltipButton";


const StepFiveForm = ({manuscriptId, handleEdit, stepStatus, journalTitle, step, editorChanged, setEditorChanged}) => {
    const [previewData, setPreviewData] = useState([]);
    const apiService = new AxiosService();
    const [isLoading, setIsLoading] = useState(false);
    const urlPath = window.location.pathname.split("/").filter(v => v==="manuscript-preview")
    const [abstractData, setAbstractData] = useState('')
    const getPreviewData = () => {
        setIsLoading(true);
        apiService.fetchPreviewManuscript(manuscriptId).then(res => {
            setPreviewData(res);
            setAbstractData({
                content: res?.jsonAbstract,
                type: "doc"
            })
            setIsLoading(false);

        }).catch(err => {            
            setIsLoading(false)
			toast.error(err?.response?.data?.message,  {
                autoClose: 3000,
            })
        })
    }

    const getLanguageName = (lang) => {
        if (lang === "en_GB") {
            return "UK English"
        } else if (lang === "en_US") {
            return "US English"
        } else {
            return "Language not found"
        }
    }

    useEffect(()=>{
        // console.log(stepStatus)
        if(step === 6 || stepStatus === 6){
            getPreviewData();
        }
    },[])           
    const EditSection = ({title, id, queryParam}) =>(
        <div className="d-flex justify-content-between editHeader mb-3 py-4 border-bottom">
            <h6 className="mb-0">{title}</h6>
            { stepStatus >= id && 
                <p className="mb-0 editLink" onClick={()=> handleEdit(id, queryParam)}>
                    Edit this section 
                </p>
            }
        </div>
    )
    
    const DataNotFound = () => <tr>
    <td colSpan={4} className="text-center">Data not found</td>
    </tr>

    const TableItem = ({file, index}) => {
        const [showFullCaption, setShowFullCaption] = useState(false)
        const [showFullFileName, setShowFullFileName] = useState(false)
        return(
            <tr key={index} className="border-bottom">
                <td>{index+1}</td>
                <td>{file.fileName && file.fileName.length > 0 ? 
                        <div className="d-flex flex-column">
                            <p className="m-0">
                                {typeof file.fileName === "string" ?
                                    file.fileName.length > 56 ? 
                                        <>
                                            {showFullFileName ? file.fileName : `${file.fileName.substring(0,56)+"..."}`}
                                        </>
                                        :
                                        file.fileName
                                    :
                                        ""
                                }
                                {typeof file.fileName === "object" ?
                                    file.fileName.join(", ").length > 56 ? 
                                        <>
                                            {showFullFileName ? file.fileName.join(", ") : `${file.fileName.join(", ").substring(0,56)+"..."}`}
                                        </>
                                        :
                                        file.fileName
                                    :
                                        ""
                                }
                            </p>
                            {file.fileName.join(", ").length > 56 && <button className="btn btn-sm btn-link p-0 align-self-start" onClick={() => {setShowFullFileName(!showFullFileName)}}>{showFullFileName ? "Hide" : "View more"}</button>}
                        </div> 
                    : 
                    "---"
                    }			
                </td>
                <td>{file.fileDesignation}</td>
                {/* <td>{file.caption ?
                        <div className="d-flex flex-column">
                        <p className="m-0">
                            {file?.caption.length > 100 ? 
                                <>
                                    {showFullCaption ? file?.caption : `${file?.caption.substring(0,100) + "..."}`}
                                </>
                                :
                                file?.caption
                            }
                        </p>
                        {file?.caption.length > 100 && <button className="btn btn-sm btn-link p-0 align-self-start" onClick={() => {setShowFullCaption(!showFullCaption)}}>{showFullCaption ? "Hide" : "View more"}</button>}
                        </div> 
                    : 
                    "---" 
                }
                </td> */}
            </tr>
        )
    }

    return (
        <div className="previewSection">
           	<div className="d-flex justify-content-between">
				<div>
                    <h5>Manuscript preview</h5>
                    <p className="">Review your manuscript and verify all details.</p>
                </div>
                {urlPath[0]==="manuscript-preview" &&<div>
                    Journal: <span className="journalTitleStyle">{journalTitle}</span>
                </div>}
			</div>
            <div className="my-3 border rounded px-3">           
                <EditSection title="SUMMARY" id={1} />
                <div className="py-2">
                    <div className="row">
                        <span className="col-md-6">
                            <label>Article Type</label>
                            <p className="font-weight-semi-bold">
                                { isLoading ?
                                    <ProgressLoader />
                                : 
                                    previewData.manuscriptType ? previewData.manuscriptType : ""
                                }
                            </p>
                        </span>
                        <span className="col-md-6">
                            <label>Study Type</label>
                            <p className="font-weight-semi-bold">
                                { isLoading ?
                                    <ProgressLoader />
                                : 
                                    previewData?.studyType ? previewData?.studyType : "" 
                                }
                            </p>
                        </span>
                        <span className="col-md-6">
                            <label>Reference format</label>
                            <p className="font-weight-semi-bold">
                                { isLoading ?
                                    <ProgressLoader />
                                : 
                                    previewData?.referenceType ? previewData?.referenceType : "" 
                                }
                            </p>
                        </span>
                        <span className="col-md-6">
                            <label>Language</label>
                            <p className="font-weight-semi-bold">
                                { isLoading ?
                                    <ProgressLoader />
                                : 
                                    previewData.language ? getLanguageName(previewData.language) : ""
                                }
                            </p>
                        </span>
                    </div>
                </div>
            </div>
            <div className="my-3 border rounded px-3 stepFiveForm">
                <EditSection title="MAIN DOCUMENT" id={2} />
                <p className="p-2">
                    { isLoading ?
                        <ProgressLoader />
                    :
                        previewData?.jsonAbstract && 
                        <div className="titleAstractDiv p-2 border rounded py-3">
                            <RemirrorEditor data={abstractData} isEditable={false} editorChanged={editorChanged} setEditorChanged={setEditorChanged}/>
                        </div>
                    }
                </p>
            </div>
            <div className="my-3 border rounded px-3">
                <EditSection title="FILES" id={3} />
                <Table responsive borderless>
                    <thead className="border-bottom">
                        <tr>
                            <th width="1%">ORDER</th>
                            <th width="50%">FILE NAME</th>
                            <th width="30%">FILE DESIGNATION</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading?
                        <tr>
                            <td colSpan={4}><ProgressLoader/></td>
                        </tr>
                        : 
                        previewData?.files?.length ? previewData.files.map((file,index)=>(
                           <TableItem file={file} index={index}/> 
                        )): 
                        <DataNotFound />
                        }
                    </tbody>
                </Table>
            </div>
            <div className="my-3 border rounded px-3">
                <EditSection title="KEYWORDS" id={4} />
                <div className="keywordSection mt-3 mb-3">
                    {isLoading ?
                    <div><ProgressLoader/></div>
                    : previewData.keywords?.length 
                    ? previewData.keywords.map(keyword => (
                        <TooltipButton tooltipMessage={keyword.name}>
                            <span className="p-2 m-1 keywordSpan text-truncate" key={keyword.cid}>{keyword.name}</span> 
                        </TooltipButton>
                    ))
                    : <div className="text-center w-100">Data not found</div>}
                </div>
            </div>
            <div className="my-3 border rounded px-3">
                <EditSection title="AUTHORS" id={5} />
                <Table responsive borderless>
                    <thead className="border-bottom">
                        <tr>
                            <th width="20%">AUTHOR NAME</th>
                            <th width="40%">DETAILS</th>
                            <th width="40%">CONTACT</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading?
                        <tr>
                            <td colSpan={2}><ProgressLoader/></td>
                        </tr>
                        :
                        previewData.authors?.length ? previewData.authors.map((author,index) => (
                            <tr key={index} className="border-bottom">
                                <td>
                                    <p className="mb-1 text-secondary">{author.firstName || ""} {author.lastName || ""}</p>
                                    {/* {author.orcid && <p className="lightText mb-0"><strong>ORCID ID: </strong>{author.orcid || ""}</p>} */}
                                    {author.authorType && <p className="mb-1 text-secondary">{author.authorType.join(", ")}</p>}
                                </td>
                                {/* <td>
                                    {author.authorType && <p className="mb-1">Role: {author.authorType}</p>}
                                    {author.emailId && <p className="mb-1">Email: {author.emailId}</p>}
                                </td> */}
                                <td className="align-middle">
                                    {/* {author.authorType && <p className="mb-1">Role: {author.authorType}</p>} */}
                                    {/* {author.emailId && <p className="mb-1">Email: {author.emailId}</p>} */}
                                    {author.affilliation && <p className="mb-1 text-secondary">Company/Institution: {author.affilliation}</p>}
                                    {/* {author.phone && <p className="mb-1">Phone: {author.phone}</p>} */}
                                    {author.contribution && <p className="mb-1 text-secondary">Contribution: {author.contribution.join(", ")}</p>}
                                    {author.orcid && <p className="mb-1 text-secondary">ORCID ID: {author.orcid || ""}</p>}
                                </td>
                                <td>
                                    {author.emailId && <p className="mb-1 text-secondary">Email: {author.emailId}</p>}
                                    {author.phone && <p className="mb-1 text-secondary">Phone: {author.phone}</p>}
                                </td>
                            </tr>
                        )): <DataNotFound /> }
                    </tbody>
                </Table>

                <h6 className="editHeader">Statements</h6>
                <p className="text-secondary">
                    { isLoading ? 
                        <ProgressLoader />
                    : 
                        previewData.statements ? 
                            previewData.statements.split("\n").map((line) => {
                                return(
                                    <p>{line}</p>
                                )
                            })
                        : 
                            "" 
                    }
                </p>
            </div>

            <div className="my-3 border rounded px-3">
                <EditSection title="REVIEWERS" id={5} queryParam="section=reviewer"/>
                <Table responsive borderless> {/* Reviewer's Table */}
                    <thead className="border-bottom">
                        <tr>
                            <th width="20%">REVIEWER NAME</th>
                            <th width="40%">DETAILS</th>
                            <th width="40%">CONTACT</th>
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading?
                        <tr>
                            <td colSpan={2}><ProgressLoader/></td>
                        </tr>
                        :
                        previewData.reviewers?.length ? previewData.reviewers.map((reviewer,index) => (
                            <tr key={index} className="border-bottom">
                                <td>
                                    <p className="mb-1 text-secondary">{reviewer.firstName || ""} {reviewer.lastName || ""}</p>
                                </td>
                                <td className="align-middle">
                                    {reviewer.affilliation && <p className="mb-1 text-secondary">Institution Name: {reviewer.affilliation}</p>}
                                    {reviewer.departmentName && <p className="mb-1 text-secondary">Department Name: {reviewer?.departmentName}</p>}
                                    {(reviewer.preference !== undefined || reviewer.preference !== null) && <p className="mb-1 text-secondary"><span>Preference: </span>{reviewer.preference === true ? "I recommend this reviewer" : "I oppose this reviewer"}</p>}
                                </td>
                                <td>
                                    {reviewer.emailId && <p className="mb-1 text-secondary">Email: {reviewer.emailId}</p>}
                                    {reviewer.phone && <p className="mb-1 text-secondary">Phone: {reviewer.phone}</p>}
                                </td>
                            </tr>
                        )): <DataNotFound /> }
                    </tbody>
                </Table>
            </div>

        </div>
    );
}

export default StepFiveForm;