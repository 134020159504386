import { faArrowLeftLong, faMagnifyingGlass, faRotate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Col, Form, FormControl, Row } from "react-bootstrap";
import { Link, useHistory, useLocation} from "react-router-dom";
import { dataDisplayRequest } from "../../../utils/auth_service/AuthServiceHelper";
import JournalInfo from "../../../components/Journals/JournalInfo";
import Pagination from "../../../components/PaginationComponent/Pagination";
import { CircleLoader, FieldLoader, ProgressLoader } from "../../../components/Loader/CommonLoader";
import "./SelectJournal.scss";
import AxiosService from "../../../utils/AxiosService";
import { toast } from "react-toastify";
import DuplicateCheckModal from "../DuplicateCheckModal/DuplicateCheckModal";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import Facets from "../../../components/Facets/Facets";

const SelectJournal = () => {
	const search = useLocation().search;
	const queryParams = new URLSearchParams(window.location.search);
	const [searchResult, setSearchResult] = useState([]);
	const journal = queryParams.get('journal')? queryParams.get('journal'): "";
	const [journalTitle, setJournalTitle] = useState(journal);
	const [totalRecords, setTotalRecords] = useState(0);
	const [loader, setLoader]= useState({isSearch: false, isLoading: false, isDraftBtn: false, isCreateBtn: false, isClone:false, isDefault:false, isManuscriptTitle: false});
	const [force, setForce]= useState(false);
	const history = useHistory();
	const apiService = new AxiosService()
	const [manuscriptDetails, setManuscriptDetails] = useState([]);
	const [show, setShow] = useState({isduplicateCheck:false, isConfirmationCheck: false});
	const [activeJournal, setActiveJournal] = useState({journalTitle: "", journalId: ""});
	const [manuscriptTitle, setManuscriptTitle] = useState("");
	const [facetsRefresh, setFacetsRefresh] = useState(false);
	const [accessTypeFacets, setAccessTypeFacets] = useState([]);
	const [didYouMeanList, setDidYouMeanList] = useState([]);
	const [templateType, setTemplateType] = useState(0)


	const manuscriptId = queryParams.get("manuscriptId");
	const clone = queryParams.get('clone')
	const cloneTemplateType = queryParams.get('templateType')
	const appliedFacets = queryParams.get("facets")? JSON.parse(queryParams.get("facets")): [] ;
	const currentPage = queryParams.get('from') ? parseInt(queryParams.get('from')) : 1;
	const offset = queryParams.get('offset') ? queryParams.get('offset') : 10;
	const sortType = queryParams.get('sort') ? queryParams.get('sort') : "";
	const [sort, setSort] = useState(sortType)
	const [pageNo, setPageNo] = useState(currentPage)
	

	const getManuscriptData = (lexiconId, force) => {
		const body = {
			"journalId": lexiconId,
			"force": force
		}
		apiService.createManuscript(body).then(res => {
			setLoader({...loader, isCreateBtn: false, isDefault: false});
			toast.success("Created successfully.",  {
				autoClose: 3000,
			});
			history.push(`/manuscript-submission/${res.manuscriptId}`);
		}).catch(e=> {
			setLoader({...loader, isCreateBtn: false, isDefault: false})
			if(e.response.status === 409) {
				setManuscriptDetails(e.response.data)
				setShow({...show, isduplicateCheck: true});
			}else {
				toast.error(e?.response?.data?.message, {autoClose: 3000})
			}
		});
	}

	const handleConfirmationModal = (journal) => {
		setActiveJournal({journalTitle: journal.journal_title, journalId: journal.lexicon_id});
		setShow({...show, isConfirmationCheck: true});
	}

	const createDefaultManuscript = () => {
		setForce(false);
		setLoader({...loader, isDefault:true});
		getManuscriptData(activeJournal.journalId, false);
	}

	const handleClone = () => {
		setLoader({...loader, isClone: true })
		apiService.cloneManuscript({"id": manuscriptId, "journalId": activeJournal.journalId}).then(res=>{
			toast.success(res.result,  {
				autoClose: 3000,
			});
			setLoader({...loader, isClone: false });
			history.push(`/manuscript-submission/${res.manuscriptId}`)
		}).catch(err=>{
			setLoader({...loader, isClone: false })
			toast.error("Journal Not Found!", {autoClose: 3000})
		})
	}

	const getSearchResults = () => {
		setPageNo(currentPage)
		setLoader({...loader,isLoading: true});
		let data = {}
		data["journal_title"] = journalTitle?.trim();
		data["keywords"] = [];
		// data["impact_factor"] = [0, 91];
		if(templateType == "1") {
			data["formatting"]= 1;
		}
		if(appliedFacets.length){
			data["acess_type"] = appliedFacets;
		}
		const start = (currentPage - 1) * offset;
	
		const config = {
			method: "post",
			url: `/api/v1/serch-service/findJournal?from=${start}&offset=${offset}&operator=AND`,
			data: (sortType ? {...data, 'sort': sortType === "Rank" ? "desc" : "asc", 'field': sortType } : data)
		}
		if (sortType === "journal_title_asc") {
			config.data = {...data, 'sort': 'asc', 'field': 'journal_title'}
		} else if (sortType === 'journal_title_desc') {
			config.data = {...data, 'sort': 'desc', 'field': 'journal_title'}
		} else if (sortType === "impact_factor_asc") {
			config.data = {...data, 'sort': 'asc', 'field': 'impact_factor'}
		} else if (sortType === "impact_factor_desc") {
			config.data = {...data, 'sort': 'desc', 'field': 'impact_factor'}
		}
		dataDisplayRequest(config).then(response => {
			setLoader({...loader, isSearch: false, isLoading: false});
			setSearchResult(response.data)
			setAccessTypeFacets(response.acess_type)
			setTotalRecords(response.total_count);
		}, error => {
			setLoader({...loader, isSearch: false, isLoading: false}); 
			console.log(error.response.status, error.response)
			if(error.response.status ===500){
				toast.error(error.response.statusText, {autoClose: 3000})
			}
		})
	}

	function getAppliedFilters(getAppliedFacets){
		setPageNo(1)
		if(manuscriptId) {
			history.push("/select-journal?manuscriptId="+manuscriptId +"&Journal="+ journalTitle +"&facets=" + encodeURIComponent(JSON.stringify(getAppliedFacets)) + "&sort=" + sort+ `&templateType=${cloneTemplateType}&clone=${clone}`)
		}else{
			history.push("/select-journal?Journal="+ journalTitle +"&facets=" + encodeURIComponent(JSON.stringify(getAppliedFacets))+"&sort=" + sort)
		}
	}

	const handleSearch = async(e) => {
		e.preventDefault();
		if(sortType !== "Rank") {
			setSort("")
		}
		if(sortType === "" || sortType === "Rank"){
			getSearchResults();
		} 
		if(manuscriptId) {
			history.push(`/select-journal?manuscriptId=${manuscriptId}&journal=${journalTitle}&sort=Rank&templateType=${cloneTemplateType}&clone=${clone}`)
		}else {
			history.push(`/select-journal?journal=${journalTitle}&sort=Rank`)
		}
		// handleDidYouMean(journalTitle)
		setLoader({...loader, isSearch: true});

	}

	const handleReset = (e) =>{
		e.preventDefault();
		setPageNo(1)
		if(manuscriptId) {
			history.push(`/select-journal?manuscriptId=${manuscriptId}&sort=Rank&templateType=${cloneTemplateType}&clone=${clone}`)
		}else {
			history.push(`/select-journal?sort=Rank`)
		}
		window.location.reload();
	}

	function setCurrentPage(page){
		setPageNo(page)
		if(manuscriptId) {
			history.push("/select-journal?manuscriptId="+ manuscriptId + "&journal=" + journalTitle + "&from=" + page + "&offset=" + offset + "&operator=and" + "&facets=" + encodeURIComponent(JSON.stringify(appliedFacets))+"&sort=" + sort + `&templateType=${cloneTemplateType}&clone=${clone}`);
		}else{
			history.push("/select-journal?journal=" + journalTitle + "&from=" + page + "&offset=" + offset + "&operator=and" + "&facets=" + encodeURIComponent(JSON.stringify(appliedFacets))+"&sort=" + sort);
		}
	}

	function handleSort(event){
		setSort(event.target.value);
		setPageNo(1)
		if(manuscriptId) {
			history.push("/select-journal?manuscriptId="+ manuscriptId + "&journal=" + journalTitle + "&from=" + 1 + "&offset=" + offset + "&operator=and" + "&facets=" + encodeURIComponent(JSON.stringify(appliedFacets))+"&sort=" + event.target.value + `&templateType=${cloneTemplateType}&clone=${clone}`);
		}else{
			history.push("/select-journal?journal=" + journalTitle + "&from=" + 1 + "&offset=" + offset + "&operator=and"+ "&facets=" + encodeURIComponent(JSON.stringify(appliedFacets)) + "&sort=" + event.target.value);
		}
	}

	const getPreviewData = () => {
		setLoader({...loader, isManuscriptTitle: true});
		apiService.fetchPreviewManuscript(manuscriptId).then(res => {
			setLoader({...loader, isManuscriptTitle: false});
			setManuscriptTitle(res.title);
		}).catch(err => {setLoader({...loader, isManuscriptTitle: true})
		toast.error(err?.response?.data?.message, {autoClose: 3000})
	})
	}

	const handleCurrentPage = (page) => {
		setCurrentPage(page)
		setPageNo(page)
	}

	function handleGoToPagination() {
		let totalPage= Math.ceil(totalRecords / offset)
		if(pageNo>0 && pageNo<=totalPage){
			setCurrentPage(pageNo)
		}
		else {
			toast.error("Please enter valid page number!", {autoClose: 3000})
		}
	}

	function handleDidYouMeanSearch(word) {
		setJournalTitle(word);
		if(manuscriptId) {
			history.push(`/select-journal?manuscriptId=${manuscriptId}&journal=${word}`)
		}else {
			history.push(`/select-journal?journal=${word}`)
		}
		window.location.reload()
	}

	function handleDidYouMean(word) {
		apiService.getDidYouMean({"query": word}).then(res=> {
			setDidYouMeanList(res);
		}).catch(err=> {
			toast.error(err?.response?.data?.message, {autoClose: 3000})
		})
	}

	useEffect(()=>{
		if(manuscriptId){
			getPreviewData()
		}
	},[manuscriptId])

	useEffect(() => {
		getSearchResults();
		// handleDidYouMean(journal)
	}, [currentPage, JSON.stringify(appliedFacets), sortType])


	return(
		<div className="container-fluid my-3 px-0">
			<Link to="/my-dashboard" className="text-secondary"><FontAwesomeIcon icon={faArrowLeftLong} /> Back to My Dashboard</Link>
			<div className="my-3 bg-white p-3 rounded border">
				<h4>{manuscriptId? "Format manuscript for another journal" : "Select Journal"}</h4>
				<p className="border-bottom pb-3">Select a journal from the list below or search using the search box</p>
				<Row>
					<Col md={6}>
						<Form className="pb-3">
						<label>Journal name</label>
						<FormControl
							placeholder="Enter journal name"
							type="text"
							value={journalTitle}
							onChange={(e)=> setJournalTitle(e.target.value)}
							className="mb-2"
						/>
						{/* <input
							className="mr-2"
							type="checkbox"
							onChange={(e) => {e.target.checked ? setTemplateType(1) : setTemplateType(0)}}
						/>
						<label>Display journals with templates only</label> */}
						<section className="d-flex mt-3">
							{loader.isSearch ?
								<FieldLoader isLoading={loader.isSearch} className="px-5" />
							:
								<button className="px-5" onClick={(e)=> handleSearch(e)}>Search <FontAwesomeIcon icon={faMagnifyingGlass} className="h6 mb-0 pb-0 ml-2"/></button>
							}
							<button className="btnReset" onClick={handleReset}> Reset search</button>
						</section>
						</Form>
					</Col>
					{manuscriptId && <Col md={6} className="py-3">
						<label>Manuscript being formatted</label>
					   {loader.isManuscriptTitle?
						<p><ProgressLoader /></p> 
					   :<p className="font-weight-bold text-secondary">{manuscriptTitle}</p>}
					</Col>}
				</Row>
				
			</div>
			<div className="mt-4">
			{loader.isLoading
			?<div className="text-center"><CircleLoader /></div>
			:searchResult.length ?
			<>
			<Row>
				<Col md={3}></Col>
				<Col md={9}>
					{/* {didYouMeanList.length >0 && <Col md={12} className="mt-2">
						<div>
							<span className='h6 text-muted'>Did you mean:</span> {didYouMeanList.map(word=> <span value={word} className='mx-2 linkText' onClick={(e)=> handleDidYouMeanSearch(word)}>{word}</span>)}
						</div>
						<hr />
					</Col>} */}
				<div className="d-flex justify-content-between row mx-0">
					<h5 className="col-sm-12 col-md-8 px-0">Showing {totalRecords} results</h5>
					<div className='sort d-flex col-sm-12 col-md-4 px-0'>
						<label className="w-25 my-1 text-dark h6">Sort by:</label>
						<select className="form-control-sm form-control" value={sortType} onChange={(e)=> handleSort(e)} >
							<option value="Rank">Relevance</option>
							{/* <option value="newest">Newest first</option> */}
							<option value="journal_title_asc">Journal title (A-Z)</option>
							<option value="journal_title_desc">Journal title (Z-A)</option>
							<option value="impact_factor_asc">Impact factor &#8593;</option>
							<option value="impact_factor_desc">Impact factor &#8595;</option>
						</select>
					</div>
						{/* Showing {totalRecords} results</p> */}
				</div>
				</Col>
			</Row>
			<Row>
				<Col md={3} >
				<Facets isloading={loader.isLoading} isRefreshed={facetsRefresh} oaFacets={accessTypeFacets} filtersApplied={getAppliedFilters} filterType="ACCESS TYPE" />
				</Col>
				<Col md={9}>
				<div className="">
				{searchResult.map((data, index)=>
					manuscriptId?
					<div className="journalCard bg-white mt-1 mb-3 rounded" key={index} >                    
						<JournalInfo journalInfo={data} onClickChange={handleConfirmationModal} manuscriptId={manuscriptId} handleClone={handleClone}/>
					</div>
					:<div className="journalCard bg-white mt-1 mb-3 rounded" >                    
						<JournalInfo journalInfo={data} onClickChange={handleConfirmationModal} />
					</div>
				)}
				<Pagination
					className="p-2 mb-0 mt-2"
					currentPage={currentPage}
					totalCount={totalRecords}
					pageSize={offset}
					onPageChange={(page) => setCurrentPage(page)}
					handleGoToPagination={handleGoToPagination}
					pageNo={pageNo}
					setPageNo={setPageNo}
				/>
			</div>
				</Col>
			</Row>
			</>
			:<div>
				{/* {didYouMeanList.length >0 && <Col md={12} className="mt-2 px-0">
					<div>
						<span className='h6 text-muted'>Did you mean:</span> {didYouMeanList.map(word=> <span value={word} className='mx-2 linkText' onClick={(e)=> handleDidYouMeanSearch(word)}>{word}</span>)}
					</div>
					<hr />
				</Col>} */}
				<div className="text-center notFound d-flex justify-content-center align-items-center text-muted h4">No results found!</div>
			</div>
			}

			<DuplicateCheckModal
			show={show.isduplicateCheck}
			handleShow={setShow}
			getManuscriptData={getManuscriptData}
			manuscriptDetails={manuscriptDetails}
			journalTitle={activeJournal.journalTitle}
			force={force}
			handleForce={setForce}
			lexiconId={activeJournal.journalId}
			/>

			<ConfirmationModal
			show={show}
			setShow={setShow}
			loader={loader}
			createDefaultManuscript={createDefaultManuscript}
			handleClone={handleClone}
			manuscriptId={manuscriptId}
			/>
			</div>
		</div>
	)
}

export default SelectJournal;