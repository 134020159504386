import classNames from 'classnames'
import React, { useEffect, useState } from 'react'
import styles from "../../components/Filter/Filter.module.scss";
import style from "./AddNewJournal.module.scss"
import CardBody from './CardBody';
import EditMetaData from './EditMetaData';
import AxiosService from '../../utils/AxiosService'
import { toast } from 'react-toastify'
import { CircleLoader } from '../../components/Loader/CommonLoader'
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeftLong } from '@fortawesome/free-solid-svg-icons';
import styless from '../../pages/JournalDetails/JournalDetails.module.scss';
import SubmissionTypes from './SubmissionTypes';
import JournalGuidelines from './JournalGuidelines';
import { Modal } from 'react-bootstrap';

const AddNewJournal = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const journalProjectId = queryParams.get('projectId')
    const tabs = queryParams.get('tab');
    const tab = tabs === "Submission Types " ? "Submission Types & Authorship" : tabs
    const history = useHistory();
    const [status, setStatus] = useState(tab);
    const [adminGuidelinesData, setAdminGuidelinesData] = useState(null);
    const [adminGuidelinesMataData, setAdminGuidelinesMataData] = useState([]);
    const [adminGuidelinesInfo, setAdminGuidelinesInfo] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isMetaDataLoading, setIsMetaDataLoading] = useState(false);
    const [isInfoLoading, setIsInfoLoading] = useState(false);
    const [isBlocking, setIsBlocking] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [nextLocation, setNextLocation] = useState(null);
    const [journalName, setJournalName] = useState("");
    const [projectId, setProjectId] = useState("");
    const [metaDataProjectId,setMetaDataProjectId] =useState("")
    const apiService = new AxiosService()
    
    function getAdminMetaData() {
        setIsMetaDataLoading(true);
        apiService.getJournalMetaData(journalProjectId).then((res) => {
            setAdminGuidelinesMataData(res.form);
            setProjectId(res.projectId);
            setJournalName(res?.form[0].value);
            setMetaDataProjectId(res.projectId)
            setIsMetaDataLoading(false);
        }).catch(err => {
            setIsMetaDataLoading(false);
            toast.error(err.response.data.message, {
                autoClose: 3000,
            })
        })
    }

    function getAdminGuidelinesData() {
        setIsLoading(true);
        setAdminGuidelinesData(null)
        apiService.getAdminGuidelines(journalProjectId).then((res) => {
            setAdminGuidelinesData(res);
            setIsLoading(false);
        }).catch(err => {
            setIsLoading(false);
            toast.error(err.response.data.message, {
                autoClose: 3000,
            })
        })
    }
    function getAdminGuidelinesInfo() {
        setIsInfoLoading(true);
        setAdminGuidelinesInfo(null);
        apiService.getAdminGuidelinesInfo(metaDataProjectId).then((res) => {
            setAdminGuidelinesInfo(res);
            setIsInfoLoading(false);
        }).catch(err => {
            setIsInfoLoading(false);
            toast.error(err.response.data.message, {
                autoClose: 3000,
            })
        })
    }

    const handleCheckedValue = (arr) => {
        const checkMandatory = arr.filter(item => item.dataType === "Array" ? item.value.length !== 0 || item?.checked : item.value !== "" || item?.checked);
        return checkMandatory.length;
    }
    useEffect(() => {
        // Block navigation if there are unsaved changes
        const unblock = history.block((location) => {
            if (isBlocking) {
                setShowModal(true);
                setNextLocation(location);
                return false;
            }
            return true;
        });

        return () => {
            unblock();
        };
    }, [isBlocking, history]);
    const handleCancel = () => {
        setShowModal(false);
    };

    const handleDiscardAndLeave = (event) => {
        event.preventDefault();
        event.stopPropagation();
        history.block(() => {
            return true;
        });
        let searchParams = new URLSearchParams(nextLocation.search);
        const tabs = searchParams.get('tab');
        const tab = tabs === "Submission Types " ? "Submission Types & Authorship" : tabs
        history.push(nextLocation.pathname + nextLocation.search);
        setIsBlocking(false);
        setShowModal(false);
        setStatus(tab);
    };

    const handleModal = () => {
        setIsBlocking(false);
        setShowModal(false);
        history.block(() => {
            return true;
        });
        history.push(nextLocation.pathname + nextLocation.search);
        
    }
    const handleTabChange = (tab) => {
        if(!isBlocking){
            setStatus(tab);
        }
        history.push(`/edit-journal?projectId=${journalProjectId}&tab=${tab}`)
    }
    useEffect(() => {
        getAdminGuidelinesData();
        getAdminMetaData()
    }, [])

    useEffect(() => {
        if (metaDataProjectId !== "") {
            getAdminGuidelinesInfo();
        }
    }, [metaDataProjectId])
    return (
        <div>
            <div>
                <h4>{journalName} - Edit the Metadata and Rules</h4>
            </div>
            <button className={styless.customLink} onClick={() => history.push("/admin-dashboard")}>
                <FontAwesomeIcon icon={faArrowLeftLong} className='mr-2' />
                Back to admin dashboard
            </button>
            <div className='row mt-2'>
                <div className='col-md-3'>
                    <div className={classNames(styles.filterSection)}>
                        <div className='card'>

                            <h6 className='px-2 pt-3'>Journal Details</h6>
                            <div className={status === "journal-metadata" ? classNames(style.Active, "px-3 py-2") : "px-3 py-2 cursor-pointer"} onClick={() => handleTabChange("journal-metadata")}>Journal Metadata</div>
                            <div className={status === "journal-guidelines" ? classNames(style.Active, "px-3 py-2") : "px-3 py-2 cursor-pointer"} onClick={() => handleTabChange("journal-guidelines")}>Journal Guidelines</div>
                            <h6 className='px-2 pt-3'>Manuscript Checks</h6>
                            {
                                isLoading ? <div className="text-center my-4"><CircleLoader /></div> : adminGuidelinesData !== null && Object.keys(adminGuidelinesData.sections).map((key, i) => (
                                    <div className={status === key ? classNames(style.Active, "d-flex justify-content-between align-items-center px-3 py-2 cursor-pointer") : "d-flex justify-content-between align-items-center  px-3 py-2 cursor-pointer"} onClick={() => handleTabChange(key)}>
                                        <span className='text-capitalize w-50'> {key}
                                        </span>
                                        <span className={status === key ? "fw-bolder w-50" : "text-muted w-50"} >{handleCheckedValue(adminGuidelinesData.sections[key])} / {adminGuidelinesData.sections[key].length} rules active</span>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                </div>

                <div className='col-md-9 card'>
                                      
                    { (status === "Submission Types & Authorship") && (adminGuidelinesData === null ?<div className="text-center mt-4"><CircleLoader /></div> :<SubmissionTypes title={status} guidelinesData={adminGuidelinesData.sections["Submission Types & Authorship"]} getAdminGuidelinesData={getAdminGuidelinesData} isLoading={isLoading} handleModal={handleModal} setIsBlocking={setIsBlocking} metaDataProjectId={metaDataProjectId}/>) }
                    {
                         adminGuidelinesData !== null && Object.keys(adminGuidelinesData.sections).map((key, i) => (
                            <div key={i}>
                                {status === key && status !== "Submission Types & Authorship" && (adminGuidelinesData === null ?<div className="text-center mt-4"><CircleLoader /></div> :<CardBody title={status} guidelinesData={adminGuidelinesData.sections[key]} handleCheckedValue={handleCheckedValue} adminGuidelinesData={adminGuidelinesData} getAdminGuidelinesData={getAdminGuidelinesData} isLoading={isLoading} handleModal={handleModal} setIsBlocking={setIsBlocking} />)}
                            </div>
                        ))
                    }
                    {status === "journal-metadata" && <EditMetaData projectId={projectId}getAdminMetaData={getAdminMetaData} journalName ={journalName}adminGuidelinesData={adminGuidelinesMataData} isLoading={isMetaDataLoading}/>}
                    {status === "journal-guidelines" && (adminGuidelinesInfo === null ? <div className="text-center mt-4"><CircleLoader /></div> : <JournalGuidelines isInfoLoading={isInfoLoading} adminGuidelinesInfo={adminGuidelinesInfo} getAdminGuidelinesInfo={getAdminGuidelinesInfo} />)}
                </div>
            </div>
            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    Warning!
                    
                </Modal.Header>
                <Modal.Body>
                    <p>You are leaving the document you are working on. </p>
                    <button onClick={handleCancel} className="w-100 mt-2 btnOutline">Cancel</button>
                    <button onClick={handleDiscardAndLeave} className="w-100 mt-2">Discard changes & leave</button>
                </Modal.Body>


            </Modal>
        </div>
    )
}

export default AddNewJournal
