import React, {useEffect} from 'react';
import { Route } from 'react-router-dom';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Login from '../Login/Login';
import { useDispatch } from 'react-redux';
import LocalStorageService from '../../utils/auth_service/AuthServiceStorage';
import { openSignUpAlertModal } from '../../store/actions/AlertModalActions';
import { Helmet } from "react-helmet";
import { Container } from 'react-bootstrap';
import ScrollToTop from '../ScrollToTop/ScrollToTop';
import styles from './DefaultLayout.module.scss';
import classNames from 'classnames';
import { useState } from 'react';

const DefaultLayout = ({ component: Component, ...rest }) => {

  const dispatch = useDispatch();
  const localService = LocalStorageService.getService();
  const [exitFunction, setExitFunction] = useState()
  const [stepOneInfo, setStepOneInfo] = useState()

  useEffect(() => {
    const timer = setTimeout(() => { if(!localService.checkCookie("orthopub_user")){
      dispatch(openSignUpAlertModal());
    }}, 3000);
    return () => clearTimeout(timer);
  }, [])
  
  return (
    <Route {...rest} render={matchProps => (
      <div id="layout" style={{backgroundColor: "#FBFBFB"}}>
        <Header exitFunction={exitFunction} stepOneInfo={stepOneInfo}/>
        
        <Helmet>
              <title>AuthorEase</title>
        </Helmet>
        
        <Container fluid className={window.location.pathname.includes("home") ? 'm-0 p-0 pt-2 mt-5' : "pt-2"}>
          <div className={window.location.pathname.includes("home") ? classNames(styles.centeredWidth, "p-0 m-0 maxWidthHome") : classNames(styles.centeredWidth, "container-xl")}>
            <Component {...matchProps} setExitFunction={setExitFunction} setStepOneInfo={setStepOneInfo}/>
            <Login />
            <ScrollToTop />
          </div>
        </Container>
        
        <Footer />
        <div className={classNames(styles.copyRightText, "w-100")}>
            <p className="m-0 p-0">Copyright Ⓒ 2024 Molecular Connections Pvt. Ltd. | All Rights Reserved</p>
          </div>
      </div>
    )} />
  )
};

export default DefaultLayout;