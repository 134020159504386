import React, { useState } from 'react'
import { Card, Col, Form, FormControl, InputGroup, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap'
import AxiosService from '../../utils/AxiosService';
import { toast } from 'react-toastify';
import ArrayInput from './ArrayInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { CircleLoader } from '../../components/Loader/CommonLoader';

const CardBody = ({ title, guidelinesData, handleCheckedValue, adminGuidelinesData,getAdminGuidelinesData,isLoading,handleModal,setIsBlocking }) => {

    const [isSaveLoading, setIsSaveLoading] = useState(false);
    const [formValues, setFormValues] = useState({});
    const [addValidated, setAddValidated] = useState(false);
    const [data, setData] = useState(guidelinesData)
    const [inputValue, setInputValue] = useState('');
    const apiService = new AxiosService()

    const handleChange = (event) => {
        const obj = { ...formValues }
        event.preventDefault();
        setIsBlocking(event.target.value.length > 0);
        const { name, value } = event.target;
        if (obj.hasOwnProperty(name)) {
            obj[name] = value;
        } else {
            obj[name] = value;
        }
        setFormValues(obj)
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if(Object.keys(formValues).length===0){
            event.stopPropagation();
            toast.warning("No changes, please make changes", {
                autoClose: 3000,
            })
        }else{
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setAddValidated(true);
            toast.warning("Please fill in all the required fields.", {
                autoClose: 3000,
            })
        } else { 
            setIsSaveLoading(true)
            apiService.getAdminGuidelinesSave(adminGuidelinesData.projectId, formValues).then((res) => {
                setTimeout(() => {
                toast.success("Saved successfully", {
                    autoClose: 3000,
                })
                getAdminGuidelinesData()
                handleModal()
                setFormValues({})
                setIsSaveLoading(false);
            }, [3000]) 
            }).catch(err => {
                setIsSaveLoading(false);
                toast.error(err.response.data.message, {
                    autoClose: 3000,
                })
            })
        }
    }
    }
    function handleChecked(value, type) {
        if (type === "Array") {
            if (value.length !== 0) {
                return true;
            }
        } else {
            if (value !== "") {
                return true;
            }
        }
        return false
    }

    const fetchOptions = async (projectId, inputValue) => {
        const reqBody = {
            "searchTerm": inputValue,
            "start": 0,
            "limit": 10
        }
        try {
            const options = await apiService.getAdminGuidelinesAutoSuggestion(projectId, reqBody);
            return options.map(option => ({
                value: option.value,
                label: option.value
            }));
        } catch (err) {
            toast.error(err.response.data.message, {
                autoClose: 3000,
            })
            return [];
        }
    };

    const loadOptions = (projectId, inputValue, callback) => {
        fetchOptions(projectId, inputValue).then(options => {
            callback(options);
        });
    };
    const handleInputChange = (inputValue, actionMeta) => {
        setInputValue(inputValue);
    };
    const handleCheckBoxChange = (e, index) => {
        const isChecked = e.target.checked
        const updateData = data.map((item, i) => {
            if (i === index) {
                if (!isChecked) {
                    return { ...item, value: "", checked: isChecked }
                }
                return { ...item, checked: isChecked }
            }
            return item;
        })
        setData(updateData)
    }
    const handleArrayChange = (attribute, value) => {
        const obj = { ...formValues }
        if (obj.hasOwnProperty(attribute)) {
            obj[attribute] = value;
        } else {
            obj[attribute] = value;
        }
        setFormValues(obj)
    }

    function handleMultiSelect(newValue, attribute, projectId) {
        setIsBlocking(newValue.length > 0)
        const value= newValue.value
        if(newValue.__isNew__){
            const reqBody = {
                searchTerm:value
            }
            apiService.saveAdminGuidelinesAutoSuggestion(projectId, reqBody).then((res) => {
                toast.success(res.message, {
                    autoClose: 3000,
                })
            }).catch(err => {
                toast.error(err.response.data.message, {
                    autoClose: 3000,
                })
            })
        }
        const obj = { ...formValues }
        if (obj.hasOwnProperty(attribute)) {
            obj[attribute] = value;
        } else {
            obj[attribute] = value;
        }
        setFormValues(obj)
    }
   
    return (
        <div>
            <section className="row d-flex justify-content-between align-items-center border-bottom p-3 ">
                <div ><h4 className='text-capitalize'>{title}</h4>
                    <p>Individual rules can be made active or inactive based on journal guidelines. </p></div>
                <div>
                    {/* <Form.Check
                        type="switch"
                        label={`${handleCheckedValue(data)} / ${data.length} rules active`}
                        checked={handleCheckedValue(data) !== 0}
                        className='mb-2'
                    /> */}
                </div>
            </section>
            <Form noValidate validated={addValidated} onSubmit={handleSubmit}>
                <Row className='mt-3'>
                    {
                       isLoading ? <div className="text-center mt-4 col"><CircleLoader /></div> : data.map((rules, i) => (<>
                            <Form.Group as={Col} md="6" controlId={`validationCustom${i}`} key={i}>
                                {/* <Form.Check
                                    type="switch"
                                    label={rules.name}
                                    defaultChecked={handleChecked(rules.value,rules.dataType)}
                                    className='mb-2'
                                    onChange={(e)=>handleCheckBoxChange(e,i)}
                                /> */}
                                <Form.Label className='mr-2'>{rules.name}{rules.mandatory && <span className="text-danger">*</span>}
                                {
                                    rules.toolTip && <OverlayTrigger
                                        key="journalKeywords"
                                        placement="top"
                                        overlay={
                                            <Tooltip id={`tooltip-journal`}>
                                                {rules.toolTip}
                                            </Tooltip>
                                        }
                                    >
                                        <FontAwesomeIcon icon={faInfoCircle} className='ml-2'/>
                                    </OverlayTrigger>
                                }
                                </Form.Label>
                                {rules.lexcionDropDown ? <AsyncCreatableSelect
                                    isClearable
                                    placeholder={rules.name}
                                    onChange={(e) => handleMultiSelect(e, rules.attribute,rules.lexcionDropDownProjectId)}
                                    onInputChange={handleInputChange}
                                    loadOptions={(inputValue, callback) => loadOptions(rules.lexcionDropDownProjectId, inputValue, callback)}
                                    // inputValue={inputValue}
                                    defaultInputValue={rules.value}
                                /> : rules.dataType === "Array" && rules.attribute!=="referenceFormat" && rules.attribute!=="languages"?
                                    <ArrayInput rules={rules} handleArrayChange={handleArrayChange} intialValue={rules.value} isDisable={false} setIsBlocking={setIsBlocking}/>
                                    :
                                    rules.type === "textarea" ?
                                        <FormControl
                                            required={rules.mandatory}
                                            aria-label={rules.name}
                                            placeholder={rules.name}
                                            name={rules.attribute}
                                            defaultValue={rules.value}
                                            aria-describedby={`basic-addon${i}`}
                                            as="textarea"
                                            onChange={(e) => handleChange(e)}
                                        // disabled={!rules?.checked && rules.value===""}
                                        /> : rules.dataType === "Number" ? <FormControl
                                            required={rules.mandatory}
                                            aria-label={rules.name}
                                            placeholder={rules.name}
                                            name={rules.attribute}
                                            defaultValue={rules.value}
                                            aria-describedby={`basic-addon${i}`}
                                            type={"number"}
                                            noValidate
                                            min={rules.min}
                                            max={rules.max}
                                            onChange={(e) => handleChange(e)}
                                            // disabled={!rules?.checked && rules.value===""}
                                            step="1"
                                        /> : rules.hasOwnProperty("source")||rules.attribute==="referenceFormat"||rules.attribute==="languages" ? <Form.Control as="select"
                                            onChange={(e) => handleChange(e)}
                                            name={rules.attribute}
                                            // disabled={!rules?.checked && rules.value===""}
                                            defaultValue={rules.value}>
                                            <option value=" " hidden>Please select</option>
                                            {rules.source.map((item, i) => <React.Fragment key={i}> <option value={item}>{item}</option></React.Fragment>)}
                                        </Form.Control> : <FormControl
                                            required={rules.mandatory}
                                            aria-label={rules.name}
                                            placeholder={rules.name}
                                            name={rules.attribute}
                                            defaultValue={rules.value}
                                            aria-describedby={`basic-addon${i}`}
                                            type={rules.type}
                                            onChange={(e) => handleChange(e)}
                                        // disabled={!rules?.checked && rules.value===""}
                                        />
                                }
                                <Form.Control.Feedback type='invalid'>
                                    {`Number should be non decimal values or range (${rules.min}-${rules.max}).`}
                                </Form.Control.Feedback>

                            </Form.Group>
                            {rules.name==="Manuscript Sections" && formValues.hasOwnProperty(rules.attribute) && <Col>
                            <label>Preview</label>
                            <Card>
                                <Card.Body>
                                <ul>
                                    {
                                        formValues[rules.attribute].map((item,i)=>
                                            <li>{item}</li>
                                        )
                                    }
                                </ul>
                                </Card.Body>
                            </Card>
                            </Col>}
                            </>
                        ))
                    }
                </Row>
                {
                    !isSaveLoading ? <button type="submit" className='my-3'>Save Journal Rules</button> : (
                        <button disabled={isSaveLoading} className='my-3'>
                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            <span>Saving...</span>
                        </button>)
                }
            </Form>
        </div>
    )
}

export default CardBody
