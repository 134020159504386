import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Col, Form, FormControl, Row } from "react-bootstrap";
import AxiosService from "../../../../../utils/AxiosService";
import { toast } from "react-toastify";
import { FieldLoader } from "../../../../../components/Loader/CommonLoader";

const ReviewerForm = ({submitButtonText, modal, manuscriptId, getReviewerList}) => {

    const [formValues, setFormValues] = useState({
        firstName: "",
        lastName: "",
        emailId: "",
        departmentName: "",
        affilliation:"",
        phone: "",
        preference: ""
    })

    const [formErrors, setFormErrors] = useState({
        firstName: "",
        lastName: "",
        emailId: "",
        departmentName: "",
        affilliation:"",
        phone: "",
        preference: ""
    })

    const [preference, setPreference] = useState("")
    const [loaderControl, setLoaderControl] = useState({submitButon: false})
    const [reviewerId, setReviewerId] = useState(modal?.prefillData?.reviewerId?.length > 0 ? modal?.prefillData?.reviewerId.toString() : "")

    const validEmailRegex = RegExp(/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i)
    const validText = RegExp(/^([^\s\d]+)+(\s{1}[^\s\d]+)*$/)
	const validPhoneNo = RegExp(/\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/)
    const noChar = RegExp(/^[^a-zA-Z]*$/)
    const apiService = new AxiosService()
    
    const submitReviewer = (e) => {
        e.preventDefault()
        let hasError = false
        let formErrArr = Object.keys(formErrors)
        let body = {}
        let formValuesArr = Object.keys(formValues)
        let errorlist = {}
        for(let i=0; i < formErrArr.length; i++) {
            let value = typeof formValues[formValuesArr[i]] === "boolean" ? formValues[formValuesArr[i]] : formValues[formValuesArr[i]].trim()
            switch (formValuesArr[i]) {
                case "emailId":
                    errorlist.emailId = value.length > 0 ? validEmailRegex.test(value) ? "" : "* Please enter a valid Email" : "* Email is required"
                    hasError = errorlist.emailId.length <= 0 && !hasError ? false : true
                    break;
                case "departmentName":
                    errorlist.departmentName = value.length > 0 ? "" : "* Department name is required"
                    hasError = errorlist.departmentName.length <= 0 && !hasError ? false : true
                    break;
                case "firstName":
                    errorlist.firstName = value?.length ===0 ? "* First Name is required": value?.length > 0 && value?.length < 2 ?  "First Name must be min. 2 characters.": !validText.test(value.trim()) ? "Numericals or white space not accepted":value?.trim().length>15? "First Name must be max. 15 characters": ""
                    hasError = errorlist.firstName.length <= 0 && !hasError ? false : true
                    break;
                case "lastName":
                    errorlist.lastName = value?.length === 0 ? "* Last Name is required": !validText.test(value.trim()) ? "Numericals or white space not accepted": value?.trim().length>15? "Last Name must be max. 15 characters": ""
                    hasError = errorlist.lastName.length <= 0 && !hasError ? false : true
                    break;
                case "affilliation":
                    errorlist.affilliation = value?.trim().length > 0 ? value?.trim().length > 2 ? "" : "Institution Name must be min. 3 characters." : "* Institution Name is required"
                    hasError = errorlist.affilliation.length <= 0 && !hasError ? false : true
                    break;
                case "phone":
                    let trimedPhoneVal = formValues?.phone?.split('').filter(v=> v !="-" && v!="+").join('')
                    errorlist.phone = trimedPhoneVal.length > 0 && trimedPhoneVal.length < 7 ?  
                        "Phone number must be min. 7 digit." 
                    : 
                        trimedPhoneVal.length > 0 ? 
                            !validPhoneNo.test(trimedPhoneVal) ? 
                                "Enter valid phone number" 
                            : 
                                !noChar.test(trimedPhoneVal) ? 
                                    "Phone numbers must not include any alphabetical characters" 
                                : 
                                    "" 
                        : 
                            "* Phone Number is required"
                    hasError = errorlist.phone.length <= 0 && !hasError ? false : true
                    break;
                case "preference":
                    errorlist.preference = value.length <= 0 ? "* Preference is required" : ""
                    hasError = errorlist.preference.length <= 0 && !hasError ? false : true
                    break;
                default:
                    break;
            }
            body[formValuesArr[i]] = value
        }
        if(hasError) {
            setFormErrors({...formErrors, ...errorlist})
            toast.error("Form submission contains errors - please check and correct.", {autoClose: 3000})
            return
        }
        setLoaderControl({...loaderControl, submitButon: true})
        if (modal?.isModal) {
            apiService.editReviewer(manuscriptId, reviewerId, body).then((res) => {
                toast.success(res.result, {autoClose: 3000})
                setLoaderControl({...loaderControl, submitButon: false})
                getReviewerList()
            }).catch((err) => {
                toast.error(err?.response?.data?.message, {autoClose: 3000})
                setLoaderControl({...loaderControl, submitButon: false})
                getReviewerList()
            })
        } else {
            apiService.addReviewer(manuscriptId, body).then((res) => {
                toast.success(res.result, {autoClose: 3000})
                setLoaderControl({...loaderControl, submitButon: false})
                setFormValues({
                    firstName: "",
                    lastName: "",
                    emailId: "",
                    departmentName: "",
                    affilliation:"",
                    phone: "",
                    preference: ""
                })
                setPreference("")
                getReviewerList()
            }).catch((err) => {
                toast.error(err?.response?.data?.message, {autoClose: 3000})
                setLoaderControl({...loaderControl, submitButon: false})
                getReviewerList()
            })
        }
        if(modal?.isModal) {
            modal.visibilityControl.setVisible(false)
        }
    }

    const handleChange = (e) => {
        e.preventDefault()
        let {name, value} = e.target
        setFormValues({...formValues, [name]: value})
    }

    const handleValidation = (e) => {
        e.preventDefault()
        let {name, value} = e.target
        switch (name) {
            case "emailId":
                setFormErrors({...formErrors,
                    emailId: value.length > 0 ? validEmailRegex.test(value) ? "" : "* Please enter a valid Email" : "* Email is required"
                });
                break;
            case "departmentName":
                setFormErrors({...formErrors,
                    departmentName: value.length > 0 ? "" : "* Department name is required"
                });
                break;
            case "firstName":
                setFormErrors({...formErrors,
                    firstName: value?.length ===0 ? "* First Name is required": value?.length > 0 && value?.length < 2 ?  "First Name must be min. 2 characters.": !validText.test(value.trim()) ? "Numericals or white space not accepted":value?.trim().length>15? "First Name must be max. 15 characters": ""
                });
                break;
            case "lastName":
                setFormErrors({
                    ...formErrors,
                    lastName:   value?.length === 0 ? "* Last Name is required": !validText.test(value.trim()) ? "Numericals or white space not accepted": value?.trim().length>15? "Last Name must be max. 15 characters": ""
                });
                break;
            case "affilliation":
                setFormErrors({...formErrors,
                    affilliation: value?.trim().length > 0 ? value?.trim().length > 2 ? "" : "Institution Name must be min. 3 characters." : "* Institution Name is required"
                });
                break;
            case "phone":
                let trimedPhoneVal = formValues?.phone?.split('').filter(v=> v !="-" && v!="+").join('')
                setFormErrors({...formErrors,
                    phone: trimedPhoneVal.length > 0 && trimedPhoneVal.length < 7 ?  
                        "Phone number must be min. 7 digit." 
                    : 
                        trimedPhoneVal.length > 0 ? 
                            !validPhoneNo.test(trimedPhoneVal) ? 
                                "Enter valid phone number" 
                            : 
                                !noChar.test(trimedPhoneVal) ? 
                                    "Phone numbers must not include any alphabetical characters" 
                                : 
                                    "" 
                        : 
                            "* Phone Number is required"
                });
                break;
            case "preference":
                setFormErrors({...formErrors,
                    preference: value.length <= 0 ? "* Preference is required" : ""
                })
                break;
            default:
                break;
        }
    }

    useEffect(() => {
        if(preference === "true" || preference === "false") {
            setFormValues({...formValues, preference: preference === "true"})
        }
    }, [preference])

    useEffect(() => {
        if (modal?.prefillData) {
            setFormValues({
                affilliation: modal?.prefillData?.affilliation?.length > 0 ? modal?.prefillData?.affilliation : "",
                departmentName: modal?.prefillData?.departmentName?.length > 0 ? modal?.prefillData?.departmentName : "",
                emailId: modal?.prefillData?.emailId?.length > 0 ? modal?.prefillData?.emailId : "",
                firstName: modal?.prefillData?.firstName?.length > 0 ? modal?.prefillData?.firstName : "",
                lastName: modal?.prefillData?.lastName?.length > 0 ? modal?.prefillData?.lastName : "",
                phone: modal?.prefillData?.phone?.length > 0 ? modal?.prefillData?.phone : "",
                preference: modal?.prefillData?.preference?.length > 0 ? modal?.prefillData?.preference : "",
            })
            setPreference(modal?.prefillData?.preference.toString())
            setReviewerId(modal?.prefillData?.reviewerId?.length > 0 ? modal?.prefillData?.reviewerId.toString() : "")
        }
    }, [modal])

    return (
        <>
            <Form onSubmit={(e) => {submitReviewer(e)}}>
                <Row>
                    <Col md={6}>
                        <div className="my-3">
                            <label>First Name <span className="text-danger">*</span></label>
                            <FormControl
                                type="text"
                                placeholder="Enter first name"
                                value={formValues.firstName}
                                onChange={(e) => {handleChange(e)}}
                                onBlur={(e) => {handleValidation(e)}}
                                aria-label="First Name"
                                name="firstName"
                            />
                            {formErrors.firstName && <span className="text-danger">{formErrors.firstName}</span>}
                        </div>
                        <div className="my-3">
                            <label>Email ID <span className="text-danger">*</span></label>
                            <FormControl
                                type="email"
                                placeholder="Enter email ID "
                                value={formValues.emailId}
                                onChange={(e) => {handleChange(e)}}
                                onBlur={(e) => {handleValidation(e)}}
                                aria-label="Email ID"
                                name="emailId"
                            />
                            {formErrors.emailId && <span className="text-danger">{formErrors.emailId}</span>}
                        </div>
                        <div className="my-3">
                            <label>Department Name <span className="text-danger">*</span></label>
                            <FormControl
                                type="text"
                                placeholder="Enter department name"
                                value={formValues.departmentName}
                                onChange={(e) => {handleChange(e)}}
                                onBlur={(e) => {handleValidation(e)}}
                                aria-label="Department Name"
                                name="departmentName"
                            />
                            {formErrors.departmentName && <span className="text-danger">{formErrors.departmentName}</span>}
                        </div>
                        <div className="my-3">
                            <label>Preference <span className="text-danger">*</span></label>
                            <Form.Check
                                type="radio"
                                value={"true"}
                                onChange={(e) => {setPreference(e.target.value)}}
                                onBlur={(e) => {handleValidation(e)}}
                                aria-label="Preference"
                                name="preference"
                                label="I recommend this reviewer"
                                checked={preference === "true"}
                            />
                            <Form.Check
                                type="radio"
                                value={"false"}
                                onChange={(e) => {setPreference(e.target.value)}}
                                onBlur={(e) => {handleValidation(e)}}
                                aria-label="Preference"
                                name="preference"
                                label="I oppose this reviewer"
                                checked={preference === "false"}
                            />
                            {formErrors.preference && <span className="text-danger">{formErrors.preference}</span>}
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="my-3">
                            <label>Last Name <span className="text-danger">*</span></label>
                            <FormControl
                                type="text"
                                placeholder="Enter last name"
                                value={formValues.lastName}
                                onChange={(e) => {handleChange(e)}}
                                onBlur={(e) => {handleValidation(e)}}
                                aria-label="Last Name"
                                name="lastName"
                            />
                            {formErrors.lastName && <span className="text-danger">{formErrors.lastName}</span>}
                        </div>
                        <div className="my-3">
                            <label>Institution Name <span className="text-danger">*</span></label>
                            <FormControl
                                type="text"
                                placeholder="Enter institution name"
                                value={formValues.affilliation}
                                onChange={(e) => {handleChange(e)}}
                                onBlur={(e) => {handleValidation(e)}}
                                aria-label="Institution Name"
                                name="affilliation"
                            />
                            {formErrors.affilliation && <span className="text-danger">{formErrors.affilliation}</span>}
                        </div>
                        <div className="my-3">
                            <label>Phone Number <span className="text-danger">*</span></label>
                            <FormControl
                                type="text"
                                placeholder="Enter Phone Number"
                                value={formValues.phone}
                                onChange={(e) => {handleChange(e)}}
                                onBlur={(e) => {handleValidation(e)}}
                                aria-label="Phone Number"
                                name="phone"
                            />
                            {formErrors.phone && <span className="text-danger">{formErrors.phone}</span>}
                        </div>
                    </Col>
                </Row>
                <div className="d-flex w-100">
                    {loaderControl.submitButon ?
                        <FieldLoader className="mt-2 mb-4" isLoading={loaderControl.submitButon} message={modal? "Updating..." : "Adding..."} /> 
                    :
                        <button type="submit" className="mt-2 mb-4 defaultBtnWidth">{submitButtonText ? submitButtonText : "Submit"}</button>
                    }
                </div>
            </Form>
        </>
    )
}

export default ReviewerForm