import {createStore, combineReducers} from 'redux';
import loginModalReducer from './reducers/LoginModalReducer';
import alertModalReducer from './reducers/AlertModalReducer';
import refreshTokenReducer from './reducers/RefreshTokenReducer'
import sortConfigReducer from './reducers/sortConfigReducer';
import filterConfigReducer from './reducers/filterConfigReducer';
import sortFilterReducer from './reducers/SortFilterReducer';
import loaderReducer from './reducers/LoaderReducer';
import navigateAwayAlertReducer from './reducers/NavigateAwayAlertReducer';

const rootReducer = combineReducers({
    login: loginModalReducer,
    alert: alertModalReducer,
    token: refreshTokenReducer,
    sortConfig: sortConfigReducer,
    filterConfig: filterConfigReducer,
    sortFilter: sortFilterReducer,
    searchLoader: loaderReducer,
    navigateAwayAlert: navigateAwayAlertReducer
});

const store = createStore(rootReducer);
export default store;