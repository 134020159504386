import React from "react";
import { Carousel, Modal, ProgressBar, Spinner } from "react-bootstrap";
import styles from './CommonLoader.module.scss';
import classNames from "classnames";

export const FieldLoader = ({isLoading, className, message="Loading"}) => {
	return(
		<button className={className} disabled={isLoading}>
			<span>
				<Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
				<Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
				<Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
			</span> 
		</button>
	)
}

export const CircleLoader = ({size}) => <Spinner animation="grow" size={size} />

export const ProgressLoader = () => <ProgressBar animated now={100} variant="secondary" className='mb-2'/>

export const FullLoader = ({loaderMessage}) => {
	let message = []
	if(typeof loaderMessage === "string") {
		message = [loaderMessage]
	} else if (typeof loaderMessage === "object") {
		message = loaderMessage
	}
	return(
		<Modal show={true} contentClassName={classNames(styles.fullPageLoader, "text-center")} backdropClassName={styles.loaderBackdrop} centered size="xl">
			<div className={classNames(styles.loaderDiv, "d-flex flex-row justify-content-center align-items-end")}>
				<Spinner animation="grow" className={styles.growSize}/>
				<Spinner animation="grow" className={styles.growSize}/>
				<Spinner animation="grow" className={styles.growSize}/>
			</div>
			{message.length ? 
				<div className={classNames(styles.loaderDiv, "mt-3 d-flex flex-row justify-content-center align-items-end")}>
					<Carousel controls={false} indicators={false} interval={3000} defaultActiveIndex={0} keyboard={false} pause={false} touch={false} wrap={true}>
						{message.map((item, index) => {
							return (
								<Carousel.Item key={index}>
									<p className="h3 font-weight-normal">{item}</p>
								</Carousel.Item>
							)
						})}
					</Carousel>
				</div>
			:
				""
			}
		</Modal>
	)
}