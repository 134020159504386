import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import styles from "./StepTwoForm.module.scss"

import styled from 'styled-components';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowUp } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import { useEffect } from "react";

const getColor = (props) => {
  if (props.isDragAccept) {
      return '#00e676';
  }
  if (props.isDragReject) {
      return '#ff1744';
  }
  if (props.isFocused) {
      return '#2196f3';
  }
  return '#eeeeee';
}

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: dashed;
  height: 120px;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
`;


function DragDropFile({myFiles, setMyFiles, name,fileError, validateError, loading, removeBtnLoading}) {
  const [errors, setErrors] = useState("");
  let maxSize = 52428800

  function fileSizeValidator(file) {
    if (file.size > maxSize) {
      return {
        code: "file-size-too-large",
        message: `Please check file size. Max. file size 50MB is allowed.`
      };
    }
  
    return null
  } 
  const onDrop = useCallback(
    (acceptedFiles) => {
      setMyFiles([...acceptedFiles]);
    },
    [myFiles]
  );
  
  const { acceptedFiles, getRootProps, getInputProps,fileRejections,
    isFocused,
    isDragAccept,
    isDragReject } = useDropzone({
    onDrop,
    maxFiles: 1,
    maxSize: 52428800, 
    accept: {"image/*":[], "text/*": [".doc", ".docx", ".pdf"], "video/*":[]},
    validator: fileSizeValidator
  });

  const fileRejectionItems = fileRejections.map(({ file, errors }) => (
    <p key={file.path} className="mb-0 pt-2 text-danger">
        {errors.map(e => (
          e.code === "file-size-too-large"? e.message : null
        ))}
    </p>
  ));

  const removeFile = (file) => () => {
    acceptedFiles.splice(0, acceptedFiles.length)
    setMyFiles([]);
    setErrors("")
  };

  const files = acceptedFiles.map((file) => (
    <p key={file.path} className="pt-2 mb-0">
      {file.path} 
      <button onClick={removeFile(file)} className={classNames(styles.deleteBtn, "ml-2")}>X</button>
    </p>
  ))


  useEffect(()=> {
    if(loading || removeBtnLoading) {
      setMyFiles([])
      acceptedFiles.splice(0, acceptedFiles.length)
    }
  }, [loading, removeBtnLoading])


  return (

    <section className="container px-0">
        <Container {...getRootProps({isFocused, isDragAccept, isDragReject})}>
        <input {...getInputProps()} name={name} />
        <p className={styles.textLight}>
            <FontAwesomeIcon icon={faCloudArrowUp} />
            <span className={styles.textLink}> Click to upload </span> 
            or drag file here.
        </p>
      </Container>
      <aside className="row mx-0">
        <div className="col-md-6 px-0">
        {files}
        {fileRejectionItems}
        </div>
        <div className="col-md-6 pt-2 pl-0 pr-2 text-right">{fileError}</div>
      </aside>
    </section>
  );
}

export default DragDropFile;
