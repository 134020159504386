import React, { useEffect } from 'react';
import {PageView} from '../../tracking';
import {Row, Col} from 'react-bootstrap';
import classNames from 'classnames';

function AboutOrthopub(){

    useEffect(() => {
        PageView();
    }, [])

    return(
        <Row className={classNames("mt-3 bg-white mx-0 rounded border")}>
            <Col md={12} className="p-4">
                <h5 className=''>About AuthorEase</h5><hr/>
                <p>
                    Transform your manuscript journey with AuthorEase. Our unique journal search and formatting tool simplifies the process of preparing your manuscript for submission. With the 'Journal Finder,' effortlessly identify the best publication for your work. Just use 'Format manuscript' to select the desired journal template, and let AuthorEase's intuitive formatting tool guide your manuscript through quick steps. In no time, you'll have a polished file ready to download and submit to your chosen journal. 
                </p>
                <div>
                    <h6>How to use AuthorEase</h6>
                    <ol>
                        <li>
                            <strong>Discover the Perfect Journal: </strong>
                            <span className='m-0 p-0'>Utilize the free Journal Finder to pinpoint the ideal journal for your manuscript. Alternatively, choose the template corresponding to your preferred publication.</span>
                        </li>
                        <li>
                            <strong>Formatting Made Easy: </strong>
                            <span className='m-0 p-0'>Allow AuthorEase to streamline the formatting process for your manuscript. Follow the intuitive prompts to ensure your document meets the requirements seamlessly.</span>
                        </li>
                        <li>
                            <strong>Manage Your Journey: </strong>
                            <span className='m-0 p-0'>Access your personalized dashboard anytime to track your manuscript’s progress. Update its status or opt for a new template to reformat it for submission to another publication effortlessly.</span>
                        </li>
                    </ol>
                </div>
                {/* <p>
                    AuthorEase is brought to you by Bone & Joint, an imprint of The British Editorial of Bone & Joint Surgery, a not-for-profit charity and Company limited by Guarantee. Bone & Joint are the publishers of The Bone & Joint Journal and Bone & Joint 360, Open Access journals Bone & Joint Research and Bone & Joint Open, and owners of the digital products OrthoSearch and OrthoMedia.
                </p> */}
                <p>
                    After formatting your manuscript, securely store it in your AuthorEase Dashboard. Revisit it later to reformat for another publication or update its publication journey for your records. Your manuscripts are confidentially kept within your Dashboard, which is password-protected and under your control. Permanently remove them or keep them on your AuthorEase Dashboard indefinitely, giving you full control over your work.
                </p>
                {/* <p>
                    After your manuscript has been formatted, you have the option to keep it in your Dashboard on AuthorEase and return to it to re-format for another publication or update its publication journey for your own reference. All your manuscripts held on AuthorEase are kept confidentially within your own Dashboard controlled by a password you set –they can be removed permanently at any time by you or kept on your AuthorEase Dashboard indefinitely.
                </p> */}
                {/* <p>
                    AuthorEase currently offers formatting templates for The Bone & Joint Journal, Bone & Joint Research and Bone & Joint Open, plus a ‘generic’ template which will allow for some formatting across most orthopaedic journals. Templates for five more specific orthopaedic journals are expected to be launched very soon!
                </p>
                <p>
                    Find out more about the pricing models for the journal specific templates and the generic template <a href='/orthopub/profile?tab=credits'>here</a>.
                </p> */}
                {/* <h5><b>Our Offices</b></h5>
                <p className="font-weight-bold">Molecular Connections,</p>
                <p className="mb-0">Heritage Building, #59/2, Kaderanahalli, 100 feet road</p>
                <p className="mb-0">Banashankari 2nd Stage, Bangalore 560070, Karnataka, India</p>
                <p className="mb-0">Tel: +91 80 2669 0145</p>
                <p>Email: <a href="mailto:info@molecularconnections.com?subject=Query about the information&body=Your query here...">info@molecularconnections.com</a></p> */}
            </Col>
        </Row>
    )
}

export default AboutOrthopub;