import React from 'react';
import store from './store';
import Routes from './Routes';
import {Provider} from 'react-redux';
import {initGA} from './tracking'; 
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
 
  initGA(); 

  return (
    <Provider store={store}>
      <Routes />
      <ToastContainer autoClose="3000"/>
    </Provider>
  );
}

export default App;
