import React, {useState} from 'react';
import { renderIFLogo } from '../../components/IFLogo/IFLogo';
import { renderOALogo } from '../../components/OpenAccessLogo/OpenAccessLogo';
import { Card, Modal, OverlayTrigger, ProgressBar, Spinner, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import styles from './JournalInfo.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faEdit } from '@fortawesome/free-solid-svg-icons';
import {renderScore} from './Score';
import imag from "../../accets/Hybrid_Access_logo.png"
import TooltipButton from '../TooltipButton/TooltipButton';
import AxiosService from '../../utils/AxiosService';
import { toast } from 'react-toastify';
import { FieldLoader } from '../Loader/CommonLoader';
import history from '../../history';
import PaymentModals from '../../pages/PaymentPages/paymentModal';
import LocalStorageService from '../../utils/auth_service/AuthServiceStorage';
const JournalInfo = ({journalInfo, onClickChange, visible=true, isloading, manuscriptId}) => {

	const pathname = window.location.pathname;
	const queryParams = new URLSearchParams(window.location.search);
	const apiService = new AxiosService()
	const [loading, setLoading] = useState(false)
	const [creditModalControl, setCreditModalControl] = useState({type: "generic", sufficientCreditModal: false, insufficientCreditModal: false})
	const [userCreditDetails, setUserCreditDetails] = useState({})
	const [genericLoading, setGenericLoading] = useState(false)
	const localStorageService = LocalStorageService.getService();
    const authDetails = JSON.parse(localStorageService.getAuthDetails());
	const [cloneModalControler, setCloneModalControler] = useState({visible: false, loading: false})

	const clone = queryParams.get('clone')
	const cloneTemplateType = queryParams.get('templateType')
	
	const handleRequestTemaplate =() => {
		setLoading(true)
		apiService.requestTemplate(journalInfo.lexicon_id).then(res=> {
			setLoading(false)
			toast.success(res.message,  {
				autoClose: 3000,
			})
		}).catch(err=> {
			setLoading(false)
            toast.error(err.response.data.message,  {
				autoClose: 3000,
			})
		})
	}

	const genericTemplateClick = (linkClick) => {
		if(clone === "true" && cloneTemplateType === "Generic") {
			if(cloneModalControler.visible) {
				setGenericLoading(true)
				apiService.cloneManuscript({"id": manuscriptId, "journalId": journalInfo.lexicon_id, "templateType":"Generic" }).then(res=>{
					toast.success(res.result,  {
						autoClose: 3000,
					});
					history.push(`/manuscript-submission/${res.manuscriptId}`)
					setGenericLoading(false)
					setCloneModalControler({...cloneModalControler, visible: true, loading: false})	
				}).catch(err=>{
					toast.error("Journal Not Found!", {autoClose: 3000})
					setGenericLoading(false)
					setCloneModalControler({...cloneModalControler, visible: true, loading: false})
				})
			} else {
				setCloneModalControler({...cloneModalControler, visible: true})
			}
		} else {
			setGenericLoading(true)
			if(clone === "true") {
					apiService.userPaymentProfile().then((res) => {
						if(true) { //Remove this when implementing payment gateway
							history.push('/details/' + journalInfo.lexicon_id + `?creditModal=generic&clone=${clone}&manuscriptId=${manuscriptId}`)
							setGenericLoading(false)
						}
						if (true) {
							history.push('/details/' + journalInfo.lexicon_id + `?creditModal=generic&clone=${clone}&manuscriptId=${manuscriptId}`)
						} else {
							toast.error("You have insufficient credits for this action. Please go to the Credits tab on your profile page to purchase more.", {autoClose: 3000})
						}
						setGenericLoading(false)
					}).catch((err) => {
						toast.error(err?.response?.data?.message)
						setGenericLoading(false)
					})
				} else if (linkClick) {
					history.push('/details/' + journalInfo.lexicon_id)
				} else {
					history.push('/details/' + journalInfo.lexicon_id + `?creditModal=generic`)
				}
		}
	} 
 
	const specificTemplateClick = (linkClick) => {
		setLoading(true)
		if(clone === "true") {
			apiService.userPaymentProfile().then((res) => {
				if(true) { //Remove this when implementing payment gateway
					history.push('/details/' + journalInfo.lexicon_id + `?creditModal=specific&clone=${clone}&manuscriptId=${manuscriptId}`)
					setGenericLoading(false)
				}
				if (true) {
					history.push('/details/' + journalInfo.lexicon_id + `?creditModal=specific&clone=${clone}&manuscriptId=${manuscriptId}`)
				} else {
					toast.error("You have insufficient credits for this action. Please go to the Credits tab on your profile page to purchase more.", {autoClose: 3000})
				}
				setLoading(false)
			}).catch((err) => {
				toast.error(err?.response?.data?.message)
				setLoading(false)
			})
		} else if (linkClick) {
			history.push('/details/' + journalInfo.lexicon_id)
		} else {
			history.push('/details/' + journalInfo.lexicon_id + `?creditModal=specific`)
		}
	}

	const linkClick = () => {
		if(journalInfo.formatting === "1") {
			specificTemplateClick(true)
		} else {
			if(clone === "true" && cloneTemplateType === "Generic") {
				history.push('/details/' + journalInfo.lexicon_id + `?creditModal=generic&clone=${clone}&manuscriptId=${manuscriptId}&templateType=${cloneTemplateType}`)
			} else {
				genericTemplateClick(true)
			}
		}
	}

	return(
		<Card id="journalInfo" className='mb-3'>
			<Card.Body>
				{isloading ?
						<>
							<ProgressBar animated now={100} variant="secondary" className='mb-2'/>
							<ProgressBar animated now={100} variant="secondary" className='mb-2'/>
							<ProgressBar animated now={100} variant="secondary" />
						</>
					:   <div className='d-flex justify-content-between align-items-center'>
					    <div>
						{ pathname.split("/")[2] === 'details' ?
							<h4>
								{journalInfo.journal_title}
								{journalInfo.acess_type ==="Open access" || journalInfo.open_acess ==="Open access" ?
									<TooltipButton key="Open access" placement="top" tooltipMessage="Open access">
										<img width="15px" height="20px" className='mx-2 h6 text-muted' src={process.env.PUBLIC_URL + '/logos/open_access_logo.svg'} alt={journalInfo.acess_type || journalInfo.open_acess} />
									</TooltipButton>
								:
									journalInfo.acess_type ==="Hybrid" || journalInfo.open_acess==="Hybrid" ? 
										<TooltipButton key="Hybrid" placement="top" tooltipMessage="Hybrid">
											<img width="15px" height="20px" className='mx-2 h6 text-muted' src={imag} alt={journalInfo.acess_type || journalInfo.open_acess} />
										</TooltipButton>
									:
										null
								}
							</h4> 
						:
							<div> 
								<h4 id='title'>
									{/* <Link to={'/details/' + journalInfo.lexicon_id} className={styles.customLink}> */}
									<Link onClick={() => {linkClick(journalInfo.lexicon_id)}} className={styles.customLink}>
										<span dangerouslySetInnerHTML={{ __html: `${journalInfo.journal_title}` }}></span>
									</Link>
									{ journalInfo.acess_type ==="Open access" || journalInfo.open_acess ==="Open access" ?
										<>
											<TooltipButton key="Open access" placement="top" tooltipMessage="Open access">
												<img width="15px" height="20px" className='mx-2 h6 text-muted' src={process.env.PUBLIC_URL + '/logos/open_access_logo.svg'} alt={journalInfo.acess_type || journalInfo.open_acess} />
											</TooltipButton>
											<span>
												{ visible ? 
													// journalInfo.formatting ==="1" ? Use this condition to have generic template buttons 
													true ?
														<button className='btn-sm border-0' onClick={()=>specificTemplateClick()}>
															{loading ?
																<>
																	Loading
																	<Spinner animation='grow' size='sm'/>
																	<Spinner animation='grow' size='sm'/>
																	<Spinner animation='grow' size='sm'/>
																</> 
															:
																<>
																	Format Manuscript 
																	<FontAwesomeIcon icon={faAngleRight} />	
																</>
															} 
														</button>
													: 
														<>
															<button className='btn-sm border-0 mr-2' onClick={()=> {genericTemplateClick()}} disabled={loading}>
																<>
																	{genericLoading ?
																		<>
																			Loading
																			<Spinner animation='grow' size='sm'/>
																			<Spinner animation='grow' size='sm'/>
																			<Spinner animation='grow' size='sm'/>
																		</> 
																	:
																		"Use generic template"
																	}
																</>
															</button>
															{ loading ? 
																<FieldLoader isLoading={loading} className={classNames(styles.secondaryColor, "btn btn-sm mr-2")} />
															:
																<>
                                                            		<TooltipButton tooltipMessage="There is no template available for this journal yet. Please click to here to notify our team so we can prioritise future development.">
																		<button className={classNames(styles.secondaryColor, "btn btn-sm mr-2")} onClick={()=> handleRequestTemaplate()} disabled={genericLoading}>
																			Request template
																		</button>
																	</TooltipButton>
																</>
															}
														</>
												:
													""
												}
											</span>
										</>
									:
										journalInfo.acess_type ==="Hybrid" || journalInfo.open_acess==="Hybrid" ? 
											<>
												<TooltipButton key="Hybrid" placement="top" tooltipMessage="Hybrid">
													<img width="15px" height="20px" className='mx-2 h6 text-muted' src={imag} alt={journalInfo.acess_type || journalInfo.open_acess} />
												</TooltipButton>
												<span>
													{ visible ? 
														// journalInfo.formatting ==="1" ? Use this condition to have generic template buttons
														true ?
															<button className='btn-sm border-0' onClick={()=>specificTemplateClick()}>
																{loading ?
																	<>
																		Loading
																		<Spinner animation='grow' size='sm'/>
																		<Spinner animation='grow' size='sm'/>
																		<Spinner animation='grow' size='sm'/>
																	</> 
																:
																	<>
																		Format Manuscript 
																		<FontAwesomeIcon icon={faAngleRight} />	
																	</>
																} 
															</button>
														:
														<>
															<button className='btn-sm border-0 mr-2' onClick={()=> {genericTemplateClick()}} disabled={loading}>
																<>
																	{genericLoading ?
																		<>
																			Loading
																			<Spinner animation='grow' size='sm'/>
																			<Spinner animation='grow' size='sm'/>
																			<Spinner animation='grow' size='sm'/>
																		</> 
																	:
																		"Use generic template"
																	}
																</>
															</button>
															{ loading ? 
																<FieldLoader isLoading={loading} className={classNames(styles.secondaryColor, "btn btn-sm mr-2")} />
															:
																<>
                                                            		<TooltipButton tooltipMessage="There is no template available for this journal yet. Please click to here to notify our team so we can prioritise future development.">
																		<button className={classNames(styles.secondaryColor, "btn btn-sm mr-2")} onClick={()=> handleRequestTemaplate()} disabled={genericLoading}>
																			Request template
																		</button>
																	</TooltipButton>
																</>
															}
														</>
													:
														""
													}
												</span>
											</>
										:
											null
									}
								</h4>
							</div>
						}
						<div id='info' className='mb-2'>
							{journalInfo.publication_year ? <span>{journalInfo.publication_year}</span> : null}
							{journalInfo.publisher ? 
								<>
									<span className='mx-2'>&bull;</span>
									<span>{journalInfo.publisher}</span>
								</>
							:
								null
							}
							{ journalInfo.issn_print ? 
								<>
									<span className='mx-2'>&bull;</span>
									<span>ISSN: {journalInfo.issn_print}</span>
								</> 
							: 
								null
							}
							
							{ journalInfo.issn_online ? 
								<>
									<span className='mx-2'>&bull;</span>
									<span>EISSN: {journalInfo.issn_online}</span>
								</> 
							: 
								null
							}

							{journalInfo.country ? 
								<>
									<span className='mx-2'>&bull;</span>
									<span>{journalInfo.country}</span>
								</>
							:
								null
							}
						</div>
						<div id='frequency' className={classNames(styles.lightDark,'mb-2')}>
							<span>{journalInfo.frequency_issues_per_year}</span>
						</div>
						<div>
							{journalInfo.impact_factor !=="0" ? renderIFLogo(journalInfo.impact_factor): null}
							{/* {journalInfo.relevancy_score !=="0" ? renderScore(journalInfo.relevancy_score): null }						 */}
							{/* {visible ? journalInfo.formatting ==="1" ? <button className='float-right btnOutline border-0 p-0' onClick={()=>onClickChange(journalInfo)}>Format Manuscript <FontAwesomeIcon icon={faAngleRight} /> </button>:loading ? <FieldLoader isLoading={loading} className="float-right btnOutline border-0 p-0" />: <button className='float-right btnOutline border-0 p-0' onClick={()=> handleRequestTemaplate()}>Request template</button>:""} */}
						</div>
						</div>
						{
							authDetails?.authorities[0]?.authority === "Admin" && <div className="text-center">
							<OverlayTrigger
								key="journalKeywords"
								placement="top"
								overlay={
									<Tooltip id={`tooltip-journal`}>
										View and edit individual journal metadata and manuscript validations
									</Tooltip>
								}
							>
								<button className={classNames(styles.buttonPrimary, "cursor-pointer mx-3")} type="button" onClick={() => history.push(`/edit-journal?projectId=${journalInfo.lexicon_id}&journalname=${journalInfo.journal_title}&tab=journal-metadata`)} ><FontAwesomeIcon icon={faEdit} /></button>
							</OverlayTrigger>
					</div>
						}
						
					</div> 
				}
			</Card.Body>
			<PaymentModals
				manuscriptId={manuscriptId}
				clone={manuscriptId ? true : false}
				journalInfo={journalInfo}
				creditModalControl={creditModalControl}
				setCreditModalControl={setCreditModalControl}
				userCreditDetails={userCreditDetails}
			/>
			<Modal show={cloneModalControler.visible} 
				onHide={() => {
					setCloneModalControler({...cloneModalControler, visible: false, loading: false})
				}}
			>
				<Modal.Header closeButton className='align-items-center'>
					<div className='d-flex flex-column'>
						<h6>Proceed to manuscript formatting for</h6>
						<span className='text-secondary small'><strong>{journalInfo.journal_title}</strong></span>
					</div>
				</Modal.Header>
				<Modal.Body>
					<p className='text-secondary'>
						Please note that you are reformatting between two journals which use AuthorEase’s generic template. No changes will be made to the manuscript and only the name of the chosen journal will be changed in the output files.
					</p>
					<div className='d-flex justify-content-center mt-3'>
						<button className='w-50' onClick={() => {
							setCloneModalControler({...cloneModalControler, loading: true})
							genericTemplateClick()
						}}>
							{cloneModalControler.loading ? <><Spinner animation='grow' size='sm'/></> : "Continue"}
						</button>
					</div>
				</Modal.Body>
			</Modal>
		</Card>
	)

}

export default JournalInfo;