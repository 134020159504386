import React,{ useEffect,useState } from "react";
import { Col,Row,Tab, Tabs} from "react-bootstrap";
import LocalStorageService from "../../utils/auth_service/AuthServiceStorage";
import history from "../../history";
import Journal from "./Journal";
import styles from '../JournalSearch/JournalSearch.module.scss';
import AddInappropriateWord from "../../components/AddInappropriateWord/AddInappropriateWord";
import classNames from "classnames";

const AdminDashboard = () => {
    const localStorageService = LocalStorageService.getService();
    const authDetail = JSON.parse(localStorageService.getAuthDetails());
    const [status, setStatus] = useState("Journals")

    useEffect(() => {
        if (authDetail?.authorities[0]?.authority !== "Admin") {
            history.push("/404")
        }
    }, [])
    return (
        <div>
            <div className="d-flex flex-column my-3 mx-2">
                <h2>Admin Dashboard</h2>
                <div className="d-flex flex-row align-content-center flex-wrap">
                    <span className="text-secondary"> Add and edit journal rules for your selected journals</span>
                </div>
            </div>
            <div className="card shadow">
                <Row>
                    <Col md={12} className="tabSection">
                        <Tabs
                            activeKey={status}
                            onSelect={(k) => setStatus(k)}
                            className={styles.customTabset}
                        >
                            <Tab eventKey="Journals" title={`Journal Configurations`} tabClassName={classNames(status === 'Journals' ? styles.customActiveTab : styles.customTab)}>
                               <Journal />
                            </Tab>
                            <Tab eventKey="InappropriateLanguageList" title={"Other Configurations"} tabClassName={classNames(status === 'InappropriateLanguageList' ? styles.customActiveTab : styles.customTab)}>
                                <AddInappropriateWord/>
                            </Tab>
                        </Tabs>
                    </Col>
                </Row>
            </div>
        </div>
    )
}



export default AdminDashboard


