import { faTrash, faInfoCircle, faSort, faChevronDown, faChevronUp, faPencil, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { useEffect, useRef, useState } from "react";
import { Card, Col, Form, FormControl, Modal, Row, Tab, Table, Tabs } from "react-bootstrap";
import { toast } from "react-toastify";
import Accordion from "react-bootstrap/Accordion"
import { CircleLoader, FieldLoader } from "../../../../../components/Loader/CommonLoader";
import Select from 'react-select';
import TooltipButton from "../../../../../components/TooltipButton/TooltipButton";
import LocalStorageService from "../../../../../utils/auth_service/AuthServiceStorage";
import AxiosService from "../../../../../utils/AxiosService";
// import  "../../MultiStepTabView.scss";
import style from "../StepFourForm/StepFourForm.module.scss"
import { CustomToggle } from "../RemirrorEditor/RemirrorEditorStep";
import AuthorForm from "./AuthorForm";
import { ToWords } from "to-words"
import ReviewerForm from "./ReviewerForm";


const StepFourForm = ({manuscriptId, handelError, journalId, mandatoryFieldSet, handleMandatoryCheck, defaultTab}) => {
		const apiService = new AxiosService();
		const [formValues, setFormValues] = useState({
			first_name: "",
			last_name: "",
			email: "",
			orcid: "",
			affiliation:"",
			authorType: [],
			phone: "",
			contribution: []
		});
		const [formErrors, setFormErrors] = useState({
			first_name: "",
			last_name: "",
			email: "",
			orcid: "",
			affiliation:"",
			authorType: [],
			phone: "",
			contribution: []
		})
		const [updateAuthor, setUpdateAuthor] = useState()
		const [updateReviewer, setUpdateReviewer] = useState()
		const validEmailRegex = RegExp(
			/^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
		);
		const [isLoading, setIsLoading] = useState({btnAddAuthor: false, btnRemove: false});
		const [isListLoading, setIsListLoading] = useState(false);
		const [authorOptions, setAuthorOptions] = useState([]);
		const [authorList, setAuthorList] = useState([]);
		const [reviewerList, setReviewerList] = useState([])
		const [reviewerListLoading, setReviewerListLoading] = useState(false)
		const [btnRemoveReviewer, setBtnRemoveReviewer] = useState(false)
		const [selectedAuthor, setSelectedAuthor] = useState();
		const [selectedReviewer, setSelectedReviewer] = useState()
		const [conflictOfInterest, setConflictOfInterest] = useState(false)	
		const [language, setLanguage] = useState("")	
		const [authorContributionOptions, setAuthorCOntributionOptions] = useState([])
		const [authorLimitExceedModal, setAuthorLimitExceedModal] = useState(false)
		const [statements, setStatements] = useState({data: "", error: ""})
		const [multivalue, setMultiValue] = useState([])
		const [editAuthorModal, setEditAuthorModal] = useState(false)
		const [editReviewerModal, setEditReviewerModal] = useState(false)
		const [authorListVisible, setAuthorListVisible] = useState()
		const [reviewerListVisible, setReviewerListVisible] = useState(false)
		const [reviewerListOpen, setReviewerListOpen] = useState(false)
		const validText = RegExp(/^([^\s\d]+)+(\s{1}[^\s\d]+)*$/);
		const validPhoneNo = RegExp(/\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/)
		const noChar = RegExp(/^[^a-zA-Z]*$/)
		const [listOpen, setListOpen] = useState(true)
		const authorRef = useRef(null)
		const toWords = new ToWords({localeCode: "en-GB"})
		const queryParams = new URLSearchParams(window.location.search)
		const active = queryParams.get('section')
		const [currentTab, setCurrentTab] = useState(active? 'reviewer' : "author")
		
		const handleChange = (event) => {
			event.preventDefault();
			let { name, value } = event.target;
			setFormValues({...formValues, [name]: value})
		}

		const handleValidation = (event) => {
			const {name, value} = event.target;
			typeof value === "string" ?
				setFormValues({...formValues, [name]: value.trim()})
			:
				setFormValues({...formValues, [name]: value})
			switch (name) {
				case "email":
					setFormErrors({...formErrors,
						email: value.length > 0 ? validEmailRegex.test(value) ? "" : "* Please enter a valid Email" : "* Email is required"
					});
					break;
				case "authorType":
					setFormErrors({...formErrors,
						authorType: value?.trim().length > 0 ? "" : "* Role is required"
					});
					break;
				case "first_name":
					setFormErrors({...formErrors,
						first_name: value?.length ===0 ? "* First Name is required": value?.length > 0 && value?.length < 2 ?  "First Name must be min. 2 characters.": !validText.test(value.trim()) ? "Numericals or white space not accepted":value?.trim().length>15? "First Name must be max. 15 characters": ""
					});
					break;
				case "last_name":
					setFormErrors({
						...formErrors,
						last_name:   value?.length === 0 ? "* Last Name is required": !validText.test(value.trim()) ? "Numericals or white space not accepted": value?.trim().length>15? "Last Name must be max. 15 characters": ""
					});
					break;
				case "affiliation":
					setFormErrors({...formErrors,
						affiliation: value?.trim().length > 0 ? value?.trim().length > 2 ? "" : "Company/Institution must be min. 3 characters." : "* Company/Institution is required"
					});
					break;
				case "phone":
					let trimedPhoneVal = formValues?.phone?.split('').filter(v=> v !="-" && v!="+").join('')
					setFormErrors({...formErrors,
						phone: trimedPhoneVal.length>0 && trimedPhoneVal.length < 7 ?  "Phone number must be min. 7 digit." : formValues?.phone?.length > 0 ? !validPhoneNo.test(trimedPhoneVal) ? "Enter valid phone number" : !noChar.test(trimedPhoneVal) ? "Phone numbers must not include any alphabetical characters" : "" : ""
					});
					break;
				case "contribution":
					setFormErrors({...formErrors,
						contribution: value.length <= 0 ? "* Contribution is required" : ""
					})
					break;
				default:
							break;
			}
		}

		const resetFormValues = () => {
			setFormValues({
				...formValues, 
				first_name: "",
				last_name: "",
				email: "",
				orcid: "",
				affiliation:"",
				authorType: [],
				phone: "",
				contribution: []
			})
			setMultiValue([])
		}

		const submitAuthor = (e) => {
			e.preventDefault();
			if(formErrors.phone || formErrors.first_name || formErrors.last_name || formErrors.email) {
				toast.error("Form submission contains errors - please check and correct.", {autoClose: 3000})
			}else{
				if(formValues.email?.trim() && validEmailRegex.test(formValues.email) && formValues.authorType?.trim() && formValues.first_name?.trim().length >1 && formValues.last_name?.trim().length >0 && formValues.affiliation?.trim().length >2 && formValues.contribution.length > 0) {	
					setIsLoading({...isLoading, btnAddAuthor :true})
					if(mandatoryFieldSet?.authors[0]?.limit && authorList.length >= mandatoryFieldSet?.authors[0]?.limit && authorLimitExceedModal === false) {
						setAuthorLimitExceedModal(true)
						return
					} else {
						let trimedFormaValue = {
							"affilliation": formValues.affiliation?.trim(),
							"authorType": formValues?.authorType ? formValues?.authorType : "",
							"emailId": formValues.email?.trim(),
							"firstName": formValues.first_name?.trim(),
							"lastName": formValues.last_name?.trim(),
							"orcid": formValues.orcid?.trim(),
							"phone": formValues.phone?.trim()? formValues.phone?.trim() : "",
							"conflictOfInterest": conflictOfInterest,
							"contribution": formValues?.contribution ? formValues?.contribution: "",
						}
						apiService.addAuthor(manuscriptId, trimedFormaValue).then(res => {
								resetFormValues();
								setConflictOfInterest(false)
								setIsLoading({...isLoading, btnAddAuthor :false});
								toast.success("Saved successfully.",  {
									autoClose: 3000,
								})
								getAuthorList();
						}).catch(e=> {
								setIsLoading({...isLoading, btnAddAuthor :false});
								if(e.response){
								toast.error(e.response.data.message,  {
									autoClose: 3000,
								})
								}
						})
					}
				} else {
					window.scrollTo({top: 0, behavior: 'smooth'});
					setIsLoading({...isLoading, btnAddAuthor :false});
					toast.error("Form submission contains errors - please check and correct.",  {
						autoClose: 3000,
					})
					setFormErrors({
						...formErrors,
						email: formValues.email.length ? validEmailRegex.test(formValues.email) ? '' : '*Enter a valid email' : '* Email is required',
						first_name: formValues.first_name?.length ===0 ? '* First Name is required': formValues.first_name?.length > 0 && formValues.first_name?.length < 2 ?  "First Name must be min. 2 characters.":  !validText.test(formValues.first_name) ? "Numericals or white space not accepted":"",
						last_name: formValues.last_name?.length === 0 ?  '* Last Name is required': !validText.test(formValues.last_name) ? "Numericals or white space not accepted": "",
						affiliation: formValues.affiliation?.trim()?.length > 0 ? formValues.affiliation?.trim()?.length > 2 ? "" : "Company/Institution must be min. 3 characters." : "* Company/Institution is required" , 
						authorType: formValues.authorType?.trim()?.length ===0 ?  '* Role is required': "",
						contribution: formValues.contribution?.length <= 0 ? "* Contribution is required": "",
					})	
				}	
			}	
		}

		
		const handleDeleteAuthor = (author) => {
			setSelectedAuthor(author);
			setIsLoading({...isLoading, btnRemove: true});
			const body = {
				"emailId": author.emailId
			}
			apiService.deleteAuthor(manuscriptId, body).then(res => {
				setIsLoading({...isLoading, btnRemove: false});
				toast.success(res.result,  {
                    autoClose: 3000,
                });
				getAuthorList();

			}).catch(e=> {
				setIsLoading({...isLoading, btnRemove: false});
				toast.error(e.message)
				toast.error(e?.response?.data?.message,  {
                    autoClose: 3000,
                })
				getAuthorList();
			})
		}

		const handleDeleteReviewer = (reviewer) => {
			setSelectedReviewer(reviewer)
			setIsLoading({...isLoading, btnRemove: true})
			const body = {
				'reviewerId': reviewer.reviewerId
			}
			apiService.deleteReviewer(manuscriptId, body).then(res => {
				setIsLoading({...isLoading, btnRemove: false})
				toast.success(res.result, {autoClose: 3000})
				getReviewerList()
			}).catch((err) => {
				setIsLoading({...isLoading, btnRemove: false})
				toast.error(err?.response?.data?.message, {autoClose: 3000})
				getReviewerList();
			})
		}
		
		const getAuthorList = () => {
				setIsListLoading(true);
				apiService.getAuthorList(manuscriptId).then(res=>{
						setIsListLoading(false);
						if(res.result.length ===0){
							setData()
							setListOpen(false)
						}
						if(res.result.length > 0) {
							setListOpen(true)
						}
						handelError();
						setAuthorList(res.result);
				}).catch(e => {
						setIsListLoading(false);
						toast.error(e?.response?.data?.message, {autoClose: 3000})
					})
		}

		const getReviewerList = () => {
			setReviewerListLoading(true)
			apiService.getReviewerList(manuscriptId).then(res => {
				setReviewerListLoading(false)
				if(res.result.length === 0) {
					setReviewerListOpen(false)
				}
				if(res.result.length > 0) {
					setReviewerListOpen(true)
				}
				handelError();
				setReviewerList(res.result)
			})
		}

		const getAuthorOptions = () => {
				apiService.getDropdownOptions("author", journalId,manuscriptId).then(res=> {
					let tempOptions = []
					res.result.map((item) => {
						let tempObj = {value: decodeHtmlEntites(item.name), label: decodeHtmlEntites(item.name)}
						tempOptions.push(tempObj)
					})
					setAuthorOptions(tempOptions);
				})
		}

		const setData = () => {
			const localStorageService = LocalStorageService.getService();

			const authDetails = JSON.parse(localStorageService.getAuthDetails());
			setFormValues({
					...authDetails.metaData,
					authorType: "",
					contribution: []
			});
			setConflictOfInterest(false)
		}

		function handleNumberChange(event){
			event.preventDefault();
			const {name, value} = event.target
			if(event.target.validity.valid){
				setFormValues({...formValues,[name]: value})
			}
			else if (value === '' || value === '-') {
				setFormValues({...formValues,[name]: value})
			}
		}

		const getData = () => {
			apiService.getStepOneDetails(manuscriptId).then(
				res => { 
					const data= res.result;
					handleMandatoryCheck(data?.manuscriptType)
					setLanguage(data?.languages?.[0])
				}).catch(e=> {
					toast.error(e?.response?.data?.message, {autoClose: 3000})
				})
		}

		const getAuthorContributions = () => {
			apiService.getDropdownOptions("authorContribution", journalId, manuscriptId).then((res) => {
				let tempOptions = []
				res.result.map((item) => {
					let tempObj = {value: decodeHtmlEntites(item.name), label: decodeHtmlEntites(item.name)}
					tempOptions.push(tempObj)
				})
				setAuthorCOntributionOptions(tempOptions)
			}, (err) => {

			})
		}

		const handleChangeOnStatements = (e) => {
			setStatements(e.target.value)
		}

		const getStatements = () => {
			apiService.getStatements(manuscriptId).then((res) => {
				setStatements(res.result)
			}, (err) => {
				console.log(err)
			})
		}

		const saveStatements = (e) => {
			e.preventDefault()
			const data = {
				statements : e.target.value
			}
			// console.log("Data from statememts: ", data)
				apiService.addStatements(manuscriptId, data).then((res) => {
					toast.success(res.result, {autoClose: 3000})
				}, (err) => {
					
				})
		}

		const decodeHtmlEntites = (rawStr) => {
			let textarea = document.createElement('textarea')
			textarea.innerHTML = rawStr
			return textarea.value
		}

		const editAuthorButtonClick = (author) => {
			setUpdateAuthor({
				id: author.authorId,
				first_name: author.firstName,
				last_name: author.lastName,
				email: author.emailId,
				orcid: author.orcid,
				phone: author.phone,
				authorType: author.authorType,
				contribution: author.contribution,
				affiliation: author.affilliation
			})
			setEditAuthorModal(true)
		}

		const editReviewerButtonClick = (reviewer) => {
			setUpdateReviewer(reviewer)
			setEditReviewerModal(true)
		}

		useEffect(()=> {
			getData()
			getAuthorList();
			getReviewerList();
			getAuthorOptions();
			getAuthorContributions();
			getStatements();
		},[])

		useEffect(() => {
			if(authorList.length && !authorListVisible) {
				setAuthorListVisible(true)
			} else {
				setAuthorListVisible(false)
			}
		}, [authorList])

		useEffect(() => {
			setCurrentTab(defaultTab)
		}, [defaultTab])

		return (
			<Tabs defaultActiveKey={currentTab} activeKey={currentTab} onSelect={(k) => setCurrentTab(k)} className={style.customTabset}>
				<Tab id="tab-author" eventKey="author" title="Author Details" tabClassName={classNames(currentTab === 'author' ? style.customActiveTab : style.customTab)}>
					<div className="mt-3">
						<section className="">
							<h5>Your details</h5>
							<p className="">Ensure all your details are correct. Click ‘Save Author’ after entering the information to add any contributors to the article title page. Your exported title page will include the title and authors. Check your chosen journal’s requirements to ensure all additional information is included.</p>
						</section>
						
						<AuthorForm 
							authorContributionOptions={authorContributionOptions}
							authorOptions={authorOptions}
							getAuthorList={getAuthorList}
							authorLimitExceedModal={authorLimitExceedModal}
							setAuthorLimitExceedModal={setAuthorLimitExceedModal}
							manuscriptId={manuscriptId}
							mandatoryFieldSet={mandatoryFieldSet}
							authorList={authorList}
							prefillData={formValues}
							modal={false} 
							submitButtonText="Save Author"
							setExceedAuthor={setFormValues}
							isModalAddAuthorBtn={isLoading?.btnAddAuthor}
							ref={authorRef}
						/>
						<div>
							<Accordion activeKey={listOpen ? "0" : undefined}>
								<Card>
									<CustomToggle eventKey="0">
										<Card.Header 
											className='flex-between cursor-pointer bg-white' 
											onClick={(e) => {
												e.preventDefault()
												setAuthorListVisible(!authorListVisible)
												setListOpen(!listOpen)
											}}
										>
											Author List {mandatoryFieldSet?.authors[0]?.limit ? `(Max: ${authorList.length}/${mandatoryFieldSet?.authors[0]?.limit})` : `(${authorList.length})`}
											<FontAwesomeIcon icon={authorListVisible ? faChevronUp : faChevronDown} />
										</Card.Header>
									</CustomToggle>
									<Accordion.Collapse eventKey="0">
										<Card.Body>
											<Table responsive className="overflow-auto mt-2 border-bottom">
												<thead className="tableHead">
													<tr>
														<th>AUTHOR NAME</th>
														<th>DETAILS</th>
														<th>CONTACT</th>
														<th className="text-right">ACTIONS</th>
													</tr>
												</thead>
												<tbody className="tBody">
														{ isListLoading ? 
															<tr>
																<td className="text-center" colSpan={3}>
																<CircleLoader />
																</td>
															</tr>  : authorList.length !==0  ?
															authorList.map(author => (
															<tr key={author?.emailId}>
																<td>
																	<p className={classNames(style.authorName, "mb-1 text-secondary")}>{author.firstName} {author.lastName}</p>
																	{author.authorType && <p className="mb-1 text-secondary">{author?.authorType.join(", ")}</p>}

																</td>
																<td className="align-middle">
																	{author.affilliation && <p className="mb-1 text-secondary">Company/Institution: {author.affilliation}</p>}
																	{author.contribution && <p className="mb-1 text-secondary">Contribution: {author?.contribution.join(", ")}</p>}
																	{author.orcid && <p className="mb-1 text-secondary"><span>ORCID ID: </span>{author.orcid}</p>}
																</td>
																<td>
																	{author.emailId && <p className="mb-1 text-secondary">Email: {author.emailId}</p>}
																	{author.phone && <p className="mb-1 text-secondary">Phone: {author.phone}</p>}
																</td>
																<td className="py-1 align-middle d-flex flex-row">
																{ isLoading.btnRemove && selectedAuthor?.emailId === author.emailId ?
																	<>
																		<button className="btn btn-md border-primary text-primary float-right my-1 mr-2"><CircleLoader size={"sm"}/></button>
																		<button className="btn btn-md border-danger text-danger float-right my-1"><CircleLoader size={"sm"}/></button>
																	</>
																:
																	<>
																		<button className={classNames(style.primaryBtn,"float-right my-1 mr-2")} onClick={()=> editAuthorButtonClick(author)}><FontAwesomeIcon  icon={faPencil} /></button>
																		<button className="btn btn-md border-danger text-danger float-right my-1" onClick={()=> handleDeleteAuthor(author)}><FontAwesomeIcon  icon={faTrash} /></button>
																	</>
																}	
																</td>
															</tr>
														)) :  
														<tr>
														<td colSpan={3} className="text-center">Data not found</td>
													</tr>
												}
												</tbody>
											</Table>
										</Card.Body>
									</Accordion.Collapse>
								</Card>
							</Accordion>
						</div>
						<div className="my-3">
							<label>
								Statements
								<TooltipButton tooltipMessage="Please copy and paste in any mandatory statements required by your chosen journal. These will be added to your title page. No changes will be made to these." placement="top">
									<FontAwesomeIcon icon={faInfoCircle} className={classNames(style.primaryFont, "mx-2 align-middle")} />
								</TooltipButton>
							</label>
							<FormControl
								as="textarea"
								value={statements}
								rows={5}
								placeholder="Please copy and paste in any mandatory statements required by your chosen journal. These will be added to your title page. No changes will be made to these."
								onChange={(e)=> handleChangeOnStatements(e)}
								onBlur={(e)=> saveStatements(e)}
								area-label="Statements"
								name="statements"
							/>
						</div>
						<Modal show={authorLimitExceedModal}  dialogClassName="" onHide={() => {
							setAuthorLimitExceedModal(false)
							setIsLoading({...isLoading, btnAddAuthor :false})
						}}>
							<Modal.Header  className="h6">Add author warning!</Modal.Header>
							<Modal.Body className="pt-0">
								<p>This journal has a limit of {mandatoryFieldSet?.authors[0]?.limit ? toWords.convert(mandatoryFieldSet?.authors[0]?.limit).toLowerCase() : ""} authors. You will exceed this number if you add further authors. </p>
								<button className="btnOutline w-100 mt-2" 
									onClick={(e) => {
										e.preventDefault()
										setIsLoading({...isLoading, btnAddAuthor :false})
										setAuthorLimitExceedModal(false)
									}}
								>
									Cancel
								</button>
								<button className="w-100 mt-2"
									onClick={(e) => {
										setAuthorLimitExceedModal(false)
										setIsLoading({...isLoading, btnAddAuthor :true})
										authorRef.current.submitAuthor(e)
									}}
								>
									Add author
								</button>
							</Modal.Body>
						</Modal>
						<Modal show={editAuthorModal} size="xl" onHide={() => {
							setEditAuthorModal(false)
						}}>
							<Modal.Header className="d-flex align-items-center">
								Update author information
								<button class="btn btn-light" onClick={() => {setEditAuthorModal(false)}}>
									<FontAwesomeIcon  icon={faXmark}/>
								</button>
							</Modal.Header>
							<Modal.Body>
								<AuthorForm 
									authorContributionOptions={authorContributionOptions}
									authorOptions={authorOptions}
									getAuthorList={getAuthorList}
									authorLimitExceedModal={authorLimitExceedModal}
									setAuthorLimitExceedModal={setAuthorLimitExceedModal}
									manuscriptId={manuscriptId}
									mandatoryFieldSet={mandatoryFieldSet}
									authorList={authorList}
									prefillData={updateAuthor}
									modal={true}
									submitButtonText="Update author"
									closeModal={() => {setEditAuthorModal(false)}}
								/>
							</Modal.Body>
						</Modal>
					</div>
				</Tab>
				<Tab id="tab-author" eventKey="reviewer" title="Reviewer Details" tabClassName={classNames(currentTab === 'reviewer' ? style.customActiveTab : style.customTab)}>
					<div className="mt-3">
						<section className="">
							<h5>Reviewer’s details</h5>
							<p className="">Please check the reviewer's details are correct. Please click ‘Save Reviewer’ after entering the information. Please check your chosen journal’s requirements to make sure you add all the additional information required.</p>
						</section>

						<ReviewerForm
							manuscriptId = {manuscriptId}
							getReviewerList = {getReviewerList}
							submitButtonText="Save Reviewer"
						/>

						<div>
							<Accordion activeKey={reviewerListOpen ? "0" : undefined}>
								<Card>
									<CustomToggle eventKey="0">
										<Card.Header 
											className='flex-between cursor-pointer bg-white' 
											onClick={(e) => {
												e.preventDefault()
												setReviewerListVisible(!reviewerListVisible)
												setReviewerListOpen(!reviewerListOpen)
											}}
										>
											Reviewer List {false ? `(Max: ${reviewerList.length}/${10})` : `(${reviewerList.length})`}
											<FontAwesomeIcon icon={reviewerListVisible ? faChevronUp : faChevronDown} />
										</Card.Header>
									</CustomToggle>
									<Accordion.Collapse eventKey="0">
										<Card.Body>
											<Table responsive className="overflow-auto mt-2 border-bottom">
												<thead className="tableHead">
													<tr>
														<th>REVIEWER NAME</th>
														<th>DETAILS</th>
														<th>CONTACT</th>
														<th className="text-right">ACTIONS</th>
													</tr>
												</thead>
												<tbody className="tBody">
														{ reviewerListLoading ? 
															<tr>
																<td className="text-center" colSpan={3}>
																<CircleLoader />
																</td>
															</tr>  : reviewerList.length !==0  ?
															reviewerList.map(reviewer => (
															<tr key={reviewer.emailId}>
																<td>
																	<p className={classNames(style.authorName, "mb-1 text-secondary")}>{reviewer.firstName} {reviewer.lastName}</p>
																</td>
																<td className="align-middle">
																	{reviewer.affilliation && <p className="mb-1 text-secondary">Institution Name: {reviewer.affilliation}</p>}
																	{reviewer.departmentName && <p className="mb-1 text-secondary">Department Name: {reviewer?.departmentName}</p>}
																	{(reviewer.preference !== undefined || reviewer.preference !== null) && <p className="mb-1 text-secondary"><span>Preference: </span>{reviewer.preference === true ? "I recommend this reviewer" : "I oppose this reviewer"}</p>}
																</td>
																<td>
																	{reviewer?.emailId && <p className="mb-1 text-secondary">Email: {reviewer?.emailId}</p>}
																	{reviewer.phone && <p className="mb-1 text-secondary">Phone: {reviewer.phone}</p>}
																</td>
																<td className="py-1 align-middle d-flex flex-row">
																{ isLoading.btnRemove && selectedReviewer.emailId === reviewer.emailId ?
																	<>
																		<button className="btn btn-md border-primary text-primary float-right my-1 mr-2"><CircleLoader size={"sm"}/></button>
																		<button className="btn btn-md border-danger text-danger float-right my-1"><CircleLoader size={"sm"}/></button>
																	</>
																:
																	<>
																		<button className={classNames(style.primaryBtn,"float-right my-1 mr-2")} onClick={()=> editReviewerButtonClick(reviewer)}><FontAwesomeIcon  icon={faPencil} /></button>
																		<button className="btn btn-md border-danger text-danger float-right my-1" onClick={()=> handleDeleteReviewer(reviewer)}><FontAwesomeIcon  icon={faTrash} /></button>
																	</>
																}	
																</td>
															</tr>
														)) :  
														<tr>
														<td colSpan={3} className="text-center">Data not found</td>
													</tr>
												}
												</tbody>
											</Table>
										</Card.Body>
									</Accordion.Collapse>
								</Card>
							</Accordion>
						</div>

						<Modal show={editReviewerModal} size="xl" onHide={() => {
							setEditReviewerModal(false)
						}}>
							<Modal.Header className="d-flex align-items-center">
								Update reviewer information
								<button class="btn btn-light" onClick={() => {setEditReviewerModal(false)}}>
									<FontAwesomeIcon  icon={faXmark}/>
								</button>
							</Modal.Header>
							<Modal.Body>
								<ReviewerForm
									manuscriptId = {manuscriptId}
									getReviewerList = {getReviewerList}
									submitButtonText="Update Reviewer"
									modal = {{isModal: true, prefillData: updateReviewer, visibilityControl: {visible: editReviewerModal, setVisible: setEditReviewerModal}}}
								/>
							</Modal.Body>
						</Modal>
					</div>
				</Tab>
			</Tabs>
		);	
}

export default StepFourForm;
