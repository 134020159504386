import React, {useState, useEffect } from 'react'
import CreatableSelect from 'react-select/creatable';
const ArrayInput = ({ rules, handleArrayChange, intialValue,isDisable,setIsBlocking }) => {
    const [inputValue, setInputValue] = useState('');
    const [value, setValue] = React.useState(intialValue.map(option=>({value:option,label:option})));
    useEffect(() => {
        const updatedValue =value.length!==0 ?value.map(item=>item.value):[]
        handleArrayChange(rules.attribute, updatedValue)
    }, [value])

    const components = {
        DropdownIndicator: null,
    };
  
    const handleKeyDown = (event) => {
      if (!inputValue) return;
      switch (event.key) {
        case 'Enter':
        case 'Tab':
          setValue((prev) => [...prev, {value:inputValue,label:inputValue}]);
          setInputValue('');
          event.preventDefault();
      }
    };
        return (
            <>
                <CreatableSelect
                    components={components}
                    inputValue={inputValue}
                    isClearable
                    isMulti
                    defaultValue={rules.source!==undefined}
                    {...(rules.source===undefined && {menuIsOpen:false})}
                    onChange={(newValue) => {setValue(newValue);setIsBlocking(true)}}
                    onInputChange={(newValue) => {setInputValue(newValue)}}
                    onKeyDown={(e)=>{handleKeyDown(e);setIsBlocking(true)}}
                    placeholder={rules.name}
                    value={value}
                    options={rules.source!==undefined?rules?.source.map(option=>({value:option.replace(/&amp;/g,'&'),label:option.replace(/&amp;/g,'&')})):[]}
                    isDisabled={isDisable && rules.attribute==="languages"}
                />
            </>
        )
    }

    export default ArrayInput
