import React, { useEffect } from "react";
import { toast } from "react-toastify";
import AxiosService from "../../utils/AxiosService";
import { useState } from "react";
import { useHistory} from 'react-router-dom';
import { Modal, Spinner } from "react-bootstrap";
import { FieldLoader } from "../../components/Loader/CommonLoader";

const PaymentModals = ({
    journalInfo, creditModalControl, clone, manuscriptId,
    setCreditModalControl, userCreditDetails, usePayment
}) => {

    const apiService = new AxiosService()
    const [promoCode, setPromoCode] = useState("")
    const [modalControl, setModalControl] = useState({forceManuscriptModal: false, createManuscriptModal: false})
    const [loader, setLoader] = useState({createManuscriptLoader: false})
    const [manuscriptDetails, setManuscriptDetails] = useState({})
	const [paymentMethod, setPaymentMethod] = useState("")
	const [codeVerified, setCodeVerified] = useState({expired: "", valid: "", utilized: "", loading: false})
    const history = useHistory();
    
    const createManuscript = (force) => {
        setLoader({...loader, createManuscriptLoader: true})
        if (clone) {
            const data = {
                id: manuscriptId,
                journalId: journalInfo.lexicon_id,
                templateType: creditModalControl.type
            }
            apiService.cloneManuscript(data).then((res) => {
                toast.success("Created successfully", {autoClose: 3000}) 
				setManuscriptDetails(res)
				setTimeout(() => {
                    history.push(`/manuscript-submission/${res.manuscriptId}`)
                }, 3000)
            }, (err) => {
                setLoader({...loader, createManuscriptLoader: false})
                if(err.response.status === 409) {
                    setManuscriptDetails(err.response.data)
                    setModalControl({...modalControl, forceManuscriptModal: true})
                } else {
                    toast.error(err?.response?.data?.message, {autoClose: 3000})
                }
            }).catch((err) => {
                setLoader({...loader, createManuscriptLoader: false})
                if(err.response.status === 409) {
                    setManuscriptDetails(err.response.data)
                    setModalControl({...modalControl, forceManuscriptModal: true})
                } else {
                    toast.error(err?.response?.data?.message, {autoClose: 3000})
                }
            })
        } else {
            const body = {
                "journalId": journalInfo.lexicon_id,
                "force": force,
                "templateType": creditModalControl.type
            }
            apiService.createManuscript(body).then((res) => {
                toast.success("Created successfully", {autoClose: 3000})
                setManuscriptDetails(res)
                setTimeout(() => {
                    history.push(`/manuscript-submission/${res.manuscriptId}`)
                }, 3000)
            }, (err) => {
                setLoader({...loader, createManuscriptLoader: false})
                if(err.response.status === 409) {
                    setManuscriptDetails(err.response.data)
                    setModalControl({...modalControl, forceManuscriptModal: true})
                } else {
                    toast.error(err?.response?.data?.message, {autoClose: 3000})
                }
            }).catch((err) => {
                setLoader({...loader, createManuscriptLoader: false})
                if(err.response.status === 409) {
                    setManuscriptDetails(err.response.data)
                    setModalControl({...modalControl, forceManuscriptModal: true})
                } else {
                    toast.error(err?.response?.data?.message, {autoClose: 3000})
                }
            })
        }
    }

	const sufficientCreditContinue = () => {
		setCreditModalControl({...creditModalControl, sufficientCreditModal: false})
		setModalControl({...modalControl, createManuscriptModal: true})
	}

	const insufficientCreditContinue = () => {
		history.push(`/profile?tab=credits&journalTitle=${journalInfo.journal_title}&journalId=${journalInfo.lexicon_id}&templateType=${creditModalControl.type}`)
	}

	const applyPromo = (e) => {
        e.preventDefault()
        if(promoCode.length > 0) {
            const body = {
                couponCode: promoCode,
                templateType: creditModalControl.type,
            }
            setCodeVerified({...codeVerified, loading: true})
            apiService.checkCoupon(body).then((res) => {
				let data = {
					journalId: journalInfo.lexicon_id,
					templateType: creditModalControl.type,
					purchaseType: "couponCode",
					couponCode: promoCode
				}
				if(res.valid === "yes" && res.utilized === "no" && res.expired === "no") {
					apiService.purchaseCredit(data).then((response) => {
						toast.success(response.message, {autoClose: 3000})
						setCodeVerified({...codeVerified, ...res, loading: false})
					}).catch((error) => {
						toast.error(error?.response?.data?.message, {autoClose: 3000})
						setCodeVerified({...codeVerified, ...res, loading: false})
					})
				} else {
					setCodeVerified({...codeVerified, ...res, loading: false})
				}
            }).catch((err) => {
                setCodeVerified({...codeVerified, loading: false})
                toast.warning(err?.response?.data?.message, {autoClose: 3000})
            })
        } else {
            toast.warning("Empty promo codes are not allowed!", {autoClose: 3000})
        }
    }

    useEffect(() => {
        if(!usePayment && (creditModalControl.sufficientCreditModal || creditModalControl.insufficientCreditModal)) {
		    setModalControl({...modalControl, createManuscriptModal: true})
        }
    }, [creditModalControl])

    return (
        <>
            {/* Sufficient credit modal */}
			<Modal show={creditModalControl.sufficientCreditModal && usePayment} 
				onHide={() => {
					setCreditModalControl({...creditModalControl, sufficientCreditModal: false})
				}}
			>
				<Modal.Header closeButton className='align-items-center'>
					<div className='d-flex flex-column'>
						<h6>Proceed to manuscript formatting for</h6>
						<span className='text-secondary small'><strong>{journalInfo.journal_title}</strong></span>
					</div>
				</Modal.Header>
				<Modal.Body>
					{/* <p className='text-secondary'>
						Selecting this template will use {creditModalControl.type === "Generic" ? <strong> 1 </strong> : <strong> 2 </strong>} of your remaining <strong>{userCreditDetails.credit} {userCreditDetails.credit > 1 ? "credits." : "credit."}</strong> Click continue to proceed.
					</p> */}
                    <p className='text-secondary'>
						Selecting this template will create new manuscript, Click continue to proceed.
					</p>
					<div className='d-flex justify-content-center mt-3'>
						<button className='w-50' onClick={() => {sufficientCreditContinue()}}>Continue</button>
					</div>
				</Modal.Body>
			</Modal>
			
			{/* Insufficient credit modal */}
			<Modal show={creditModalControl.insufficientCreditModal && usePayment} 
				onHide={() => {
					setCreditModalControl({...creditModalControl, insufficientCreditModal: false})
				}}
			>
				<Modal.Header closeButton className='align-items-center'>
					<div className='d-flex flex-column'>
						<h6>Insufficient credits</h6>
						<span className='text-secondary small'><strong>{journalInfo.journal_title}</strong></span>
					</div>
				</Modal.Header>
				<Modal.Body>
					<p className='text-secondary'>
                        You have insufficient credits for this action. Please go to the Credits tab on your profile page to purchase more.
					</p>
					<div className='d-flex justify-content-center mt-3'>
						<button className='w-50' onClick={() => {insufficientCreditContinue()}}>Continue</button>
					</div>
				</Modal.Body>
			</Modal>

			{/* Create Confirmation Modal */}
            <Modal show={modalControl.createManuscriptModal} onHide={() => {
                    if (!loader.createManuscriptLoader) {
                        setModalControl({...modalControl, createManuscriptModal: false})
                    }
                }}
                onShow={() => {setCreditModalControl({...creditModalControl, sufficientCreditModal: false, insufficientCreditModal: false})}}
            >
                <Modal.Header closeButton className='border-bottom-0'>
                    <Modal.Title className='h5'>Format manuscript?</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        You will be formatting a new manuscript for <strong>{journalInfo.journal_title}</strong>. Are you sure you want to proceed?
                    </p>
                    <section>
                        { loader.createManuscriptLoader ? 
                            <FieldLoader className="w-100 mb-2" isLoading={loader.createManuscriptLoader}/>
                        :
                            <button className="w-100 mb-2" onClick={() => createManuscript(false)}>Start now</button>
                        }
                        <button className={'w-100 mb-2 customButton'} disabled={loader.createManuscriptLoader} onClick={()=> {
                                setModalControl({...modalControl, createManuscriptModal: false})
                                history.goBack()
                            }}
                        >
                            Select a different journal
                        </button>
                    </section>
                </Modal.Body>
            </Modal>

            {/* Create Forced Confirmation Modal */}
            <Modal show={modalControl.forceManuscriptModal} onHide={() => {
                setModalControl({...modalControl, forceManuscriptModal: false, createManuscriptModal: false})
            }}>
                <Modal.Header closeButton className='border-bottom-0'>
                    <Modal.Title className='h5'>Multiple drafts alert</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        You have previously created <strong>{manuscriptDetails.count}</strong> submission drafts for this journal. Would you like to create a new submission or continue working on a previous draft?
                    </p>
                    <section>
                        <button className={'w-100 mb-2 customButton'} onClick={()=> {
                                setModalControl({...modalControl, createManuscriptModal: false, forceManuscriptModal: false})
                                history.push(`/my-dashboard?journalTitle=${encodeURIComponent(journalInfo.journal_title)}&status=Draft`)
                            }}
                        >
                            Continue working on a draft
                        </button>
                        { loader.createManuscriptLoader ? 
                            <FieldLoader className="w-100" isLoading={loader.createManuscriptLoader}/>
                        :
                            <button className="w-100" onClick={() => {createManuscript(true)}}>Create a new submission</button>
                        }   
                    </section>
                </Modal.Body>
            </Modal>

            
        </>
    )
}

export default PaymentModals