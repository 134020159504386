import React from 'react';
import styles from './SampleAbstract.module.scss';

export const SampleAbstract = (abstractDetails) => {
    let formattedAbstractDetails = abstractDetails
    let replaceList = ["Aims", "Methods", "Results", "Conclusion"]
    replaceList.map((item) => {
        let regexString = "("+item+"){1}"
        let regex = new RegExp(regexString, "g")
        formattedAbstractDetails.abstract = formattedAbstractDetails.abstract.replace(regex, item+": ").replace(": : ", ": ")
    })
    return(
        <div className='p-4 my-3 border rounded'>
            <h5 className='abstract_title'>{abstractDetails.article_title}</h5>
            <div className='mb-2 abstract_info'>
                {abstractDetails.publication_date && <span className='dateStyle'>{abstractDetails.publication_date} </span>}
                {abstractDetails.volume && <span className='dateStyle'>Vol {abstractDetails.volume} </span>}
                {abstractDetails.issue && <span className='dateStyle'>Issue {abstractDetails.issue}</span>}
                {abstractDetails.pages && <><span className='dateStyle'>Pages {abstractDetails.pages}</span></>}
            </div>
            {formattedAbstractDetails.abstract &&<div>
                <h6 className={styles.custom_title}>ABSTRACT</h6>
                {formattedAbstractDetails.abstract}
            </div>}
        </div>
    )
}

