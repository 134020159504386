import React, { useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import { PageView } from '../../tracking';
import './TermsNConditions.scss'

function TermsNConditions(){
    
    useEffect(() => {
        PageView();
    }, [])

    return(
        <Row className="mt-4 bg-white mx-0 tearmsNConditions rounded">
            <Col md={12} className="p-3">
                <h5 className="font-weight-bold">Terms and Conditions</h5><hr />
                <div>
                    <p><strong>This site will be referred to as part of the Bone & Joint site: 'Bone & Joint' - www.boneandjoint.org.uk</strong></p>
                    <p>These terms and conditions (“<strong>Terms</strong>”) apply to each of our websites: <a href='https://online.boneandjoint.org.uk/' target='_blank'>www.boneandjoint.org.uk</a>; <a href='https://www.orthomedia.org.uk/' target="_blank">www.orthomedia.org.uk</a>; <a href='https://www.orthosearch.org.uk/' target="_blank">www.orthosearch.org.uk/Home</a>; <a href='http://www.orthopublish.org.uk/' target="_blank">www.orthopublish.org.uk</a> (the <strong>“Sites”</strong>).</p>
                    <p>By using the Sites, you agree to be bound by these Terms. If you do not agree with these Terms, please do not use the Sites. In these Terms, we explain what you can expect from our Sites and what we will expect from you in relation to your use of our Sites. We explain what you can and cannot do when using the Sites and what our obligations and liabilities are to you. Please take some time to read the following terms. By using any of our Sites you agree to be bound by these Terms. Please also see our <a href='https://orthopub.molecularconnections.com/orthopub/privacy-policy' target="_blank">Privacy Notice </a> which sets out details of how we will process your personal information.</p>
                    <p>The Sites are owned by <strong>The British Editorial Society of Bone & Joint Surgery (us, our</strong>, or <strong>we</strong>). Our registered address is 22 Buckingham Street, London, WC2N 6ET. Our company registration number is 522061 and we are a company registered in England and Wales. The term you or your refers to the user or viewer or contributor to our Sites. Our VAT number is GB 23242338 01.</p>
                    <p>We may revise these Terms at any time. Any changes made to these Terms will be applicable from the date stated at the end of these Terms, please therefore ensure you read these Terms each time you access the applicable Sites. If you do not to accept any new Terms we make available then you should not use the applicable Sites.</p>
                    <h6>Operation of the site</h6>
                    <section className='pl-3'>
                        <p>1.1 We make no guarantee or warranty that the operation of the Sites will be uninterrupted or free from errors, that defects will be corrected, and that the Sites and the server that makes them available is free from viruses. While we make every effort to keep our Sites online 24 hours a day, 7 days a week, due to technical failures, routine maintenance or circumstances beyond our control, availability may be limited and/or information lost. We shall not be liable for lost information, damage, or non-availability of our Sites.</p>
                        <p>1.2 Your use of our Sites is entirely at your own risk.</p>
                    </section>
                    <h6>Use of your Username and Password</h6>
                    <section className='pl-3'>
                        <p>2.1 The Password you create is personal to you in order to facilitate your access to the Sites and must not be disclosed to any third party without our prior written consent. It is strictly prohibited for another user to enter any parts of the Sites with your Username and Password. Sharing of your Username and Password will result in your immediate suspension from the Sites.</p>
                        <p>2.2 You are solely responsible for ensuring the confidentiality of your Username and Password and for all activity that occurs under your Username and Password. We will be entitled to assume that anyone logging into any account of yours using your log in details is you. You must notify us immediately if there is any breach of security including unauthorised use of your account. We will not be held liable for any claim relating to the use or misuse of your Username and Password.</p>
                    </section>
                    <h6>Submitting Material for Publication</h6>
                    <section className='pl-3'>
                        <p>3.1 Publication of any material you submit to us will be at our discretion. We reserve the right to refuse publication, make changes to your submission prior to publication, and to maintain editorial control over the content of the material you submit.</p>
                        <p>3.2 You must not submit for publication material which is illegal, defamatory, abusive, pornographic, untrue, inaccurate, incorrect, fraudulent, discriminatory, inflammatory, racist or threatening, which contains errors or viruses, or which is otherwise actionable by law. We reserve the right to revoke your privilege to submit content to us at any time.</p>
                        <p>3.3 You further agree that in any activity undertaken following use of and related to your use of the Sites you will not in any way conduct yourself in a manner which is unlawful or which gives rise to civil or criminal liability or which is defamatory, libellous, untrue, discriminatory, obscene, inflammatory or racist.</p>
                    </section>
                    <h6>Intellectual Property Rights</h6>
                    <section className='pl-3'>
                        <p>4.1 All copyright, trade mark rights, database rights, patent rights and any other intellectual property rights on our Sites and all content, software and other materials and information including the organisation and layout of the Sites and the underlying software code published on or relating to the Sites is owned by us or our licensors.</p>
                        <p>4.2 The content, software, materials and information contained in or otherwise made available through the Sites and any information provided by us is for your personal use only and may not be used or distributed for commercial purposes without our written permission. You may only make a copy of this content for your personal non-commercial use provided that you retain all copyright and other proprietary notices contained in the materials and you agree not to modify, reproduce, republish, upload, post, distribute or otherwise transmit or use the content provided by us in any way without our prior written consent. You may not otherwise reproduce, copy, modify, alter, publish, broadcast, publish, distribute, communicate, sell or transfer any content, software, material or other information on the Sites or the underlying software code whether in whole or in part without our prior written permission.</p>
                        <p>4.3 The logo for the British volume of the journal is a registered trade mark of the British Editorial Society of Bone & Joint Surgery and other trade marks, logos and service marks displayed the Sites are trade marks of the British Editorial Society of Bone & Joint Surgery and may not be used without our written permission.</p>
                    </section>
                    <h6>Our Obligations and Liability</h6>
                    <section className='pl-3'>
                        <p>5.1 We try to make sure the content and information on the Sites is as accurate as possible. However we cannot guarantee that any content or materials provided on or via our Sites (including the content of any articles) is accurate or complete and publication by us does not mean that we endorse or recommend any opinions, specific tests, products, drug regimes or procedures that are described therein. Neither we, nor our officers, agents or employees shall be liable for any death or personal injury or other loss or damage arising out of or in connection with the Sites (including its contents) except where caused by our negligence.</p>
                        <p>5.2 Everything on the Sites is provided to you "as is" and "as available" without warranty of any kind, either express or implied, including, but not limited to, the implied warranties of satisfactory quality, fitness for a particular purpose or non-infringement. The information on the Sites is provided with the understanding that the information does not constitute any form of advice or recommendation by the Society, is not a substitute for professional medical care or advice and should be used only by qualified practitioners, trainees and medical experts as an aid in understanding current medical knowledge. A qualified physician should always be consulted in relation to any health problem or medical condition and reliance on any information provided on the Sites is entirely at your own risk.</p>
                        <p>5.3 If you find any inappropriate or inaccurate information on the Sites, please notify us immediately.</p>
                        <p>5.4 We are not responsible for and shall not be liable for any damage to or viruses that may infect your computer equipment or other property on account of your access to, use of, or browsing or your downloading of any materials or information from the Sites.</p>
                        <p>5.5 We reserve the right not to ship, honour or complete orders where we decide they are incorrect for example where the price of an article or other product or service has been incorrectly stated on the Sites. In such cases we will contact you before taking payment.</p>
                        <p>5.6 We reserve the right to monitor the use of the Sites in accordance with any applicable laws in order to (i) maintain the Sites, and (ii) to protect the rights of other users of the Sites.</p>
                        <p>5.7 We will not be liable to you for any loss or damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even if foreseeable, arising under or in connection with: (i) use of, or inability to use, any of the Sites or any content; or (ii) use of or reliance on any content.</p>
                        <p>5.8 We will not be liable to you for: (i) loss of profits, sales, business, or revenue; (ii) business interruption; (iii) loss of anticipated savings; (iv) loss of information or data; (v) loss of business opportunity; (vi) loss of goodwill or reputation; or (vii) any indirect or consequential loss or damage.</p>
                        <p>5.9 If using OrthoPublish: Formatting your manuscript using OrthoPublish does not guarantee acceptance to Bone & Joint journals, or those of any other publisher.</p>
                        <p>5.10 Nothing in these Terms excludes or limits our liability for any liability that cannot be excluded, disclaimed or limited by applicable law.</p>
                    </section>
                    <h6>Your Obligations to Us</h6>
                    <section className='pl-3'>
                        <p>6.1 You agree that you will only use our Sites in accordance with these Terms and any additional terms and conditions which we may communicate from time to time.</p>
                        <p>6.2 You agree not do anything or cause or permit anything to be done that may infringe, damage or endanger any of our intellectual property rights or any intellectual property rights of a third party or do anything that is otherwise unlawful.</p>
                        <p>6.3 You confirm that all registration details provided by you are complete and accurate and that you will not permit other people to use your email account or subscription to contact us.</p>
                        <p>6.4 The Sites are not intended for children. We do not knowingly collect or maintain the personal information of children under the age of 18. If you are under the age of 18, please do not access the Sites at any time or in any manner. We will take appropriate steps to delete the personal information of persons under the age of 18.</p>
                        <p>6.5 If you choose to purchase any materials from us you agree to be responsible for all charges resulting from such purchase and you are responsible for all charges related to your access to the Sites and any transactions which you may enter into on our Sites.</p>
                        <p>6.6 You agree not to publish, post, disseminate, distribute or otherwise transmit any defamatory, offensive, infringing, obscene, indecent or otherwise unlawful or objectionable material or information through our Sites nor use the Sites for any chain letters, junk mail, 'spamming' material or any other form of bulk communication or otherwise cause any liability for us.</p>
                        <p>6.7 Unless otherwise agreed between us, any communication or material transmitted by you to the Sites by mail or otherwise will be treated as non-confidential and non-proprietary. Anything you transmit or post may be used by us for any purpose and you agree to waive any moral rights which you may have. You also permit any other user of the Sites to access, display, view, store and reproduce such material for personal use.</p>
                        <p>6.8 If for any reason we are held responsible for loss or damage or other costs incurred by a third party as a result of any use or conduct by you on our Sites or any breach of these terms by you, you shall be responsible to us and will reimburse our costs, damages and expenses that are suffered or incurred by the third party and/or us.</p>
                        <p>6.9 In the event that we consider or determine, in our absolute discretion, that you have breached, violated or contravened these terms or have otherwise demonstrated inappropriate conduct in the use of our Sites, we reserve the absolute right to take such measures as we see fit including terminating, suspending or restricting your use of the Sites.</p>
                    </section>
                    <h6>Links to Other Sites</h6>
                    <section className='pl-3'>
                        <p>7.1 We may provide links to other websites, resources, advertisements or sponsorships either directly or through our partners. We accept no responsibility for these links. We do not endorse the contents of these sites and shall in no way be responsible or liable for such websites or the content, products or services available from such sites. If you decide to access other websites from the Sites you do so at your own risk. Any dealings you have with third parties via the Sites are your sole responsibility.</p>
                        <p>7.2 You may only link to our Sites with our express written permission. We expressly reserve the right to withdraw our consent at any time to a link which we think is inappropriate.</p>
                    </section>
                    <h6>Termination</h6>
                    <section className='pl-3'>
                        <p>8 Your access to the Sites will continue until it is terminated by us, or until you provide notice to us of your decision to terminate your usage. We may discontinue or change the Sites, including its availability to you, at any time without notice.</p>
                    </section>
                    <h6>General Provisions</h6>
                    <section className='pl-3'>
                        <p>9.1 You hereby undertake to us to indemnify and hold us harmless and defend us at your own expense against all claims, liabilities, costs and losses whatsoever and howsoever incurred by us or our staff arising out of any claim made against us in any jurisdiction in the world for infringement of any intellectual property rights of any third party caused by your use of the Sites.</p>
                        <p>9.2 These Terms shall be governed by and construed in accordance with the laws of England and Wales. You agree that the sole jurisdiction and venue for any actions that may arise under or in relation to this subject matter shall be the courts located in England.</p>
                        <p>9.3 In the event that any part of these Terms is held to be unenforceable, such part will at our option be construed as far as possible to reflect our intentions and the remainder of the provisions will remain in full force and effect.</p>
                    </section>
                    <h6>Contacting The British Editorial Society of Bone & Joint Surgery</h6>
                    <p>Any communication or information concerning these Terms, the Sites, the software or services should be sent in writing to:</p>
                    <section>
                        <h4>The British Editorial Society of Bone & Joint Surgery</h4>
                        <p>22 Buckingham Street <br /> London <br/>WC2N 6ET, UK</p>
                    </section>
                    <p>or by email to: <a href='mailto: info@boneandjoint.org.uk?subject=Query about the information&body=Your query here...'> info@boneandjoint.org.uk</a></p>
                    <p>Last revised: 7 February 2024</p>
                </div>
            </Col>
        </Row>
    )
}

export default TermsNConditions;