import React from "react";
import AxiosService from "../../utils/AxiosService";
import { useState } from "react";
import { toast } from "react-toastify";
import { useEffect } from "react";
import Pagination from "../../components/PaginationComponent/Pagination";
import {  Modal, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import styles from "./AdminDashboard.module.scss"
import style from "../../components/Journals/JournalInfo.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faList, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import history from "../../history";
import { CircleLoader } from "../../components/Loader/CommonLoader";
import * as moment from 'moment'

const Journal = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [resetClickDisable, setResetClickDisable] = useState(true);
    const [deleteLoading, setIsDeleteLoading] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [journalData, setJournalData] = useState([]);
    const [total, setTotal] = useState(0);
    const [current, setCurrent] = useState(1)
    const [totalRecords, setTotalRecords] = useState(0)
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1)
    const [searchTerm, setSearchTerm] = useState("");
    const [deleteModalBody, setDeleteModalBody] = useState({journal:"",projectId:""});
    const [sort, setSort] = useState("desc")
    const [sortKey, setSortKey] = useState("createDate")
    
    const apiService = new AxiosService()

    const fetchJournal = (term, start, limit, sort, sortKey) => {
        setIsLoading(true);
        apiService.getAdminJournal(term, start, limit, sort, sortKey).then((res) => {
            setJournalData(res.data);
            setTotal(res.total)
            setTotalRecords(res.total)
            setIsLoading(false);
        }).catch(err => {
            setIsLoading(false);
            toast.error(err.response.data.message, {
                autoClose: 3000,
            })
        })
    }
    function handleReset() {
        setSearchTerm("")
        setSort("desc")
        setSortKey("createDate")
        setCurrent(1)
        setPage(1)
        setResetClickDisable(true)
        fetchJournal("", 0, 10, "desc", "createDate")
    }
    const handleDelete = () => {
        setIsDeleteLoading(true);
        apiService.deleteAdminJournal(deleteModalBody.projectId).then((res) => {
            setTimeout(() => {
            toast.success("Deleted successfully", {
                autoClose: 3000,
            })
            setIsDeleteLoading(false);
            setDeleteModal(false);
            fetchJournal("", 0, 10, "desc", "createDate");
        }, [3000]) 
        }).catch(err => {
            setIsDeleteLoading(false);
            setDeleteModal(false)
            toast.error(err.response.data.message, {
                autoClose: 3000,
            })
        })
    }

    const handleGoToPagination = () => {
        setResetClickDisable(false)
        let totalPage = Math.ceil(totalRecords / limit)
        if (page > 0 && page <= totalPage) {
            setPage(parseInt(page))
            setCurrent(parseInt(page))
        }
        else {
            toast.error("Please enter valid page number!", { autoClose: 3000 })
        }
    }
    const handleCurrentPage = (page) => {
        setResetClickDisable(false)
        setCurrent(page)
        setPage(page)
    }
    function handleSearchTerm(event) {
        event.preventDefault();
        setSearchTerm(event.target.value)
    }
    function handleSubmit(event) {
        event.preventDefault();
        setResetClickDisable(false)
        setCurrent(1)
        fetchJournal(searchTerm, 0, limit, sort, sortKey)
    }
    function handleDeleteModal(journalDetails) {
        setDeleteModal(true)
        setDeleteModalBody(journalDetails)
    }
    function handleSortChange(e) {
        const value = e.target.value;
        setResetClickDisable(false)
        const parts = value.split('$');
        const sortOrder = parts.pop()
        setSort(sortOrder)
        setSortKey(parts)
    }
    useEffect(() => {
        fetchJournal(searchTerm, (current - 1) * limit, limit, sort, sortKey)
    }, [current, sort, sortKey])
    return (
        <>
            <div className="d-flex flex-column flex-sm-row justify-content-between my-3 bg-white p-3 border-bottom">
                <div>
                <h5>Journal ({total})</h5>
                <p>The rules of individual journals can be modified as needed. Furthermore, new journals can be created with their own set of rules.</p>
                </div>
                <div className="align-self-end align-self-sm-center">
                    <button className={classNames("cursor-pointer ml-2 text-nowrap")} type="button" onClick={() => history.push("/add-journal")}>
                        <FontAwesomeIcon icon={faPlus} /> Add a New Journal
                    </button>

                </div>
            </div>
            <div className="d-flex justify-content-between row mx-0">
                <div className="col-sm-12 col-md-8">
                    <form className="form-inline my-2 my-lg-0" onSubmit={handleSubmit}>
                        <input className="form-control mr-sm-2 w-50" type="search" placeholder="Search Journal" onChange={handleSearchTerm} value={searchTerm} />
                        <button className="my-2 my-sm-0" type="submit" disabled={searchTerm === ""}>Search</button>
                        <button className="mx-2" disabled={resetClickDisable} onClick={handleReset}>Reset</button>
                    </form>
                </div>

                <div className="sort d-flex col-sm-12 col-md-4">
                    <label className="w-25 my-1 text-dark h6">Sort by:</label><select className="form-control-sm form-control" onChange={(e) => handleSortChange(e)} value={`${sortKey}$${sort}`}>
                        <option value="createDate$desc">Newest First</option>
                        <option value="createDate$asc">Oldest First</option>
                        <option value="name_sort$asc">Journal title (A-Z)</option>
                        <option value="name_sort$desc">Journal title (Z-A)</option>
                    </select>
                </div></div>
            <hr />
            <div className="px-3 my-2">

                {
                    isLoading ? <div className="text-center my-4"><CircleLoader /></div> : journalData.length === 0 ? <div className="text-center my-4">No journal found!</div> : journalData.map((journal, i) => (
                        <div className="border-bottom mt-3 d-flex justify-content-between align-items-center"><div className="form-check" key={i}>
                            <h4>
                            </h4>
                            <div>
                            <h4 id="title" className="d-flex align-items-center"><span id="title" className={classNames(`${style.customLink} mr-2`)}>{(i+1)+(current - 1) * limit}.</span><a className={classNames(style.customLink)} href={`/details/${journal.id}`}><div dangerouslySetInnerHTML={{ __html: journal.name }} />
                            </a>
                            </h4>
                            
                            <div id="info" className="mb-3"><span style={{ fontWeight: "500" }}>Created on: </span>{moment(journal.createDate).format('DD MMMM YYYY')} | <span style={{ fontWeight: "500" }} >Last updated: </span>{moment(journal.updateDate).fromNow()}</div>
                            </div>
                        </div>
                            <div className="text-center">
                                <OverlayTrigger
                                    key="journalKeywords"
                                    placement="top"
                                    overlay={
                                        <Tooltip id={`tooltip-journal`}>
                                            Edit individual journal metadata and manuscript validations
                                        </Tooltip>
                                    }
                                >
                                    <button className={classNames(styles.buttonPrimary, "cursor-pointer mx-3")} type="button" onClick={() => history.push(`/edit-journal?projectId=${journal.id}&tab=journal-metadata`)} ><FontAwesomeIcon icon={faEdit} /></button>
                                </OverlayTrigger>
                                <OverlayTrigger
                        key="journalKeywords"
                        placement="top"
                        overlay={
                            <Tooltip id={`tooltip-journal`}>
                                Delete an existing journal
                            </Tooltip>
                        }
                    >
                        <button className={classNames(styles.redButton, "cursor-pointer my-3 my-mb-0")} type="button" onClick={() => handleDeleteModal({journal:journal.name,projectId:journal.id})} ><FontAwesomeIcon icon={faTrash} /></button>
                    </OverlayTrigger>
                            </div>
                        </div>
                    ))
                }
                <div className="float-right my-3">
                    <Pagination
                        className="p-2 mb-0 my-2"
                        currentPage={current}
                        totalCount={totalRecords}
                        pageSize={limit}
                        onPageChange={(page) => handleCurrentPage(page)}
                        handleGoToPagination={handleGoToPagination}
                        pageNo={page}
                        setPageNo={setPage}
                    />
                </div>
            </div>
            <Modal show={deleteModal} onHide={() => setDeleteModal(false)} aria-labelledby="contained-modal-title-vcenter"
                centered>
                <Modal.Header closeButton>
                    <div>
                        <h5>Delete journal</h5>
                        <p className='fs-1'>Delete an existing journal</p>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <p>Journal</p>
                        <h5>{deleteModalBody.journal}</h5>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {
                        !deleteLoading ? <button onClick={handleDelete} className={classNames(styles.redButton)}>Delete journal</button> : (
                            <button disabled={deleteLoading}>
                                <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />
                                <span>Deleting...</span>
                            </button>)
                    }
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Journal
