import React, { useState } from 'react'
import { Col, Form, Row, Spinner } from 'react-bootstrap'
import AxiosService from '../../utils/AxiosService';
import { toast } from 'react-toastify';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { CircleLoader } from '../../components/Loader/CommonLoader';

const SubmissionTypes = ({ title, guidelinesData,getAdminGuidelinesData,isLoading,handleModal,setIsBlocking,metaDataProjectId }) => {

    const [isSaveLoading, setIsSaveLoading] = useState(false);
    const [formValues, setFormValues] = useState([]);
    const [addValidated, setAddValidated] = useState(false);
    const [data, setData] = useState(guidelinesData);
    const [options,setOption]=useState([])
    const apiService = new AxiosService()

    const handleSubmit = (event) => {
        event.preventDefault();
        if(formValues.length===0){
            event.stopPropagation();
            toast.warning("No changes, please make changes", {
                autoClose: 3000,
            })
        }else{
          const errorFinder =data.map(obj=>{
            if (obj.value.length===0) {
                return { ...obj, error: true };
            }
            return { ...obj, error: false };;
        })
        const filterError =errorFinder.filter(item=>item.error)
        if (filterError.length!==0) {
            setData(errorFinder)
            toast.warning("Please fill in all the required fields.", {
                autoClose: 3000,
            })
        } else {     
            setIsSaveLoading(true)
            apiService.saveSubmission(metaDataProjectId, formValues).then((res) => {
                setTimeout(() => {
                toast.success("Saved successfully", {
                    autoClose: 3000,
                })
                getAdminGuidelinesData()
                setFormValues([])
                handleModal()
                setIsSaveLoading(false);
            }, [5000]) 
            }).catch(err => {
                setIsSaveLoading(false);
                toast.error(err.response.data.message, {
                    autoClose: 3000,
                })
            })
        }
    }
    }

    const fetchOptions = async (projectId, inputValue) => {
        const reqBody = {
            "searchTerm": inputValue,
            "start": 0,
            "limit": 10
        }
        try {
            const options = await apiService.getAdminGuidelinesAutoSuggestion(projectId, reqBody);
            return options.map(option => ({
                value: option.value.replace(/&amp;/g,'&'),
                label: option.value.replace(/&amp;/g,'&')
            }));
        } catch (err) {
            toast.error(err.response.data.message, {
                autoClose: 3000,
            })
            return [];
        }
    };

    const loadOptions = (projectId, inputValue, callback) => {
        fetchOptions(projectId, inputValue).then(options => {
            callback(options);
        });
    };


    function handleMultiSelect(newValue, attribute, projectId) {
        setIsBlocking(newValue.length > 0)
        const value = newValue.map((item,i)=>{
            if (item.__isNew__ && (newValue.length-1)===i ) {
                const reqBody = {
                    searchTerm: item.value
                }
                apiService.saveAdminGuidelinesAutoSuggestion(projectId, reqBody).then((res) => {
                    toast.success(res.message, {
                        autoClose: 3000,
                    })
                }).catch(err => {
                    toast.error(err.response.data.message, {
                        autoClose: 3000,
                    })
                })
                return item.value
            }
            return item.value
        });
        const updatedValue = data.map(obj => {
            if (obj.attribute === attribute) {
                return { ...obj, value: value };
            }
            return obj;
        }); 
        setData(updatedValue)
        setFormValues(updatedValue)
    }

    function handleInitialValue(projectId){
        loadOptions(projectId, " ", setOption) 
    }
    return (
        <div>
            <section className="row d-flex justify-content-between align-items-center border-bottom p-3 ">
                <div ><h4 className='text-capitalize'>{title}</h4>
                    <p>Individual rules can be made active or inactive based on journal guidelines. </p></div>
            </section>
            <Form noValidate validated={addValidated} onSubmit={handleSubmit}>
                <Row className='mt-3'>
                    {
                        isLoading ? <div className="col text-center mt-4"><CircleLoader /></div> :data.map((rules, i) => (
                            <Form.Group as={Col} md="6" controlId={`validationCustom${i}`} key={i}>
                                <Form.Label className='mr-2'>{rules.name}{rules.mandatory && <span className="text-danger">*</span>}</Form.Label>
                                <AsyncCreatableSelect
                                    isMulti
                                    placeholder={rules.name}
                                    onChange={(e) => handleMultiSelect(e, rules.attribute, rules.projectId)}
                                    loadOptions={(inputValue, callback) => loadOptions(rules.projectId, inputValue, callback)}
                                    defaultValue={rules?.value.map(option=>({value:option.replace(/&amp;/g,'&'),label:option.replace(/&amp;/g,'&')}))}
                                    options={options}
                                    defaultOptions
                                    cacheOptions
                                    onFocus={()=>handleInitialValue(rules.projectId)}
                                />
                            {rules?.error && <span className='text-danger'>Please add atleast one {rules.name.toLowerCase()} </span>}
                            </Form.Group>
                        ))
                    }
                </Row>
                {
                    !isSaveLoading ? <button type="submit" className='my-3'>Save Journal Rules</button> : (
                        <button disabled={isSaveLoading} className='my-3'>
                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            <span>Saving...</span>
                        </button>)
                }
            </Form>
        </div>
    )
}

export default SubmissionTypes

