import React, { useEffect, useState } from 'react'
import { Col, Form, FormControl, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap'
import AxiosService from '../../utils/AxiosService'
import { toast } from 'react-toastify'
import { CircleLoader } from '../../components/Loader/CommonLoader'
import HtmlInput from './HtmlInput'
import ArrayInput from './ArrayInput'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

const EditMetaData = ({ projectId, getAdminMetaData, journalName, adminGuidelinesData, isLoading }) => {
    const [isSaveLoading, setIsSaveLoading] = useState(false);
    const [formValues, setFormValues] = useState({});
    const apiService = new AxiosService()
    const [addValidated, setAddValidated] = useState(false);
    const [isBlocking, setIsBlocking] = useState(false);
    const regex = /[\S]{4}\-[\S]{4}/g
    const handleChange = (event) => {
        const obj = { ...formValues }
        event.preventDefault();
        const { name, value } = event.target;
        if (obj.hasOwnProperty(name)) {
            obj[name] = value;
        } else {
            obj[name] = value;
        }
        setFormValues(obj)
    }
    const handleArrayChange = (attribute, value) => {
        const obj = { ...formValues }
        if (obj.hasOwnProperty(attribute)) {
            obj[attribute] = value;
        } else {
            obj[attribute] = value;
        }
        setFormValues(obj)
    }
    function callApi() {
        setIsSaveLoading(true)
        apiService.saveMetaData(projectId, formValues).then((res) => {
            toast.success("Saved successfully", {
                autoClose: 3000,
            })
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth"
            });
            getAdminMetaData()
            setIsSaveLoading(false);
        }).catch(err => {
            setIsSaveLoading(false);
            toast.error(err.response.massage, {
                autoClose: 3000,
            })
        })
    }
    function handleOnlineRegex() {
        if (formValues.hasOwnProperty("onlineIssn")) {
            if (formValues.onlineIssn !== "") {
                if (!regex.test(formValues.onlineIssn)) {
                    return true;
                }
                return false
            }
            return false
        }
        return false
    }
    function handlePrintRegex() {
        if (formValues.hasOwnProperty("printIssn")) {
            if (formValues.printIssn !== "") {
                if (!regex.test(formValues.printIssn)) {
                    return true;
                }
                return false
            }
            return false
        }
        return false
    }
    const handleSubmit = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setAddValidated(true);
            toast.warning("Please fill in all the required fields.", {
                autoClose: 3000,
            })
        } else {
            callApi()
        }
    }

    return (
        <>
            <section className="row d-flex justify-content-between mt-3 border-bottom">
                <div className='col-md-6 col-sm-12'><h4>{journalName}</h4>
                    <p>Edit the journal metadata and rules</p></div>
            </section>
            <Form noValidate validated={addValidated} onSubmit={handleSubmit}>
                <Row className='mt-3'>
                    {
                        isLoading ? <Col className="text-center my-4"><CircleLoader /></Col> : adminGuidelinesData.map((rules, i) => (
                            <Form.Group as={Col} md={rules.dataType === "HTML" ? "12" : "6"} controlId={`validationCustom${i}`} key={i}>
                                <Form.Label className='mr-2'>{rules.name}{rules.mandatory && <span className="text-danger">*</span>}</Form.Label>
                                {
                                    rules.toolTip && <OverlayTrigger
                                        key="journalKeywords"
                                        placement="top"
                                        overlay={
                                            <Tooltip id={`tooltip-journal`}>
                                                {rules.toolTip}
                                            </Tooltip>
                                        }
                                    >
                                        <FontAwesomeIcon icon={faInfoCircle} />
                                    </OverlayTrigger>
                                }
                                {rules.dataType === "HTML" ? <HtmlInput rules={rules} handleArrayChange={handleArrayChange} intialValue={rules.value} index ={i}/>
                                    : rules.dataType === "Array" ? <ArrayInput rules={rules} handleArrayChange={handleArrayChange} intialValue={rules.value} isDisable={false} setIsBlocking={setIsBlocking} /> : rules.type === "textarea" ?
                                        <FormControl
                                            required={rules.mandatory}
                                            aria-label={rules.name}
                                            placeholder={rules.name}
                                            name={rules.attribute}
                                            defaultValue={rules.value}
                                            aria-describedby={`basic-addon${i}`}
                                            as="textarea"
                                            onChange={(e) => handleChange(e)}
                                        /> : rules.dataType === "Number" ? <FormControl
                                            required={rules.mandatory}
                                            aria-label={rules.name}
                                            placeholder={rules.name}
                                            name={rules.attribute}
                                            defaultValue={rules.value}
                                            aria-describedby={`basic-addon${i}`}
                                            type={rules.dataType}
                                            onChange={(e) => handleChange(e)}
                                            min={rules.min}
                                            max={rules.max}
                                            step="any"
                                        /> : rules.hasOwnProperty("source") ? <Form.Control as="select"
                                            onChange={(e) => handleChange(e)}
                                            name={rules.attribute}
                                            defaultValue={rules.value}>
                                            <option value=" " hidden>Please select</option>
                                            {rules.source.map((item, i) => <React.Fragment key={i}> <option value={item}>{item}</option></React.Fragment>)}
                                        </Form.Control> : <FormControl
                                            required={rules.mandatory}
                                            aria-label={rules.name}
                                            placeholder={rules.name}
                                            name={rules.attribute}
                                            defaultValue={rules.value}
                                            aria-describedby={`basic-addon${i}`}
                                            type={rules.type}
                                            onChange={(e) => handleChange(e)}
                                        />
                                }
                                <Form.Control.Feedback type='invalid'>
                                    {`Please provide a valid ${rules.name.toLowerCase()}.`}
                                </Form.Control.Feedback>
                                {rules.attribute === "onlineIssn" && handleOnlineRegex() && <p className='text-danger'>
                                    {`Please provide a valid ISSN (electronic).`}
                                </p>}
                                {rules.attribute === "printIssn" && handlePrintRegex() && <p className='text-danger'>
                                    {`Please provide a valid ISSN (print).`}
                                </p>}
                            </Form.Group>
                        ))
                    }
                </Row>
                {!isSaveLoading ? <button type='submit' className='my-3'>Save Journal Metadata</button> : (
                    <button disabled={isSaveLoading} className='my-3'>
                        <Spinner
                            as="span"
                            animation="grow"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                        <span>Saving...</span>
                    </button>)}
            </Form>
        </>
    )
}

export default EditMetaData