import React from "react";
import { Row, Col, Nav, Modal, Card, Spinner } from "react-bootstrap";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faTwitter,
  faLinkedin,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import xIcon from "./x-twitter.svg";
import styles from "./Footer.module.scss";
import { useState } from "react";
import AxiosService from "../../utils/AxiosService";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

export default function Footer() {
  const [priceInfoModal, setPriceInfoTable] = useState({
    visible: false,
    loading: true,
  });
  const [userPaymentProfile, setUserPaymentProfile] = useState([]);
  const history = useHistory();

  const apiService = new AxiosService();
  const currencySymbols = {
    USD: "$", // US Dollar
    EUR: "€", // Euro
    CRC: "₡", // Costa Rican Colón
    GBP: "£", // British Pound Sterling
    ILS: "₪", // Israeli New Sheqel
    INR: "₹", // Indian Rupee
    JPY: "¥", // Japanese Yen
    KRW: "₩", // South Korean Won
    NGN: "₦", // Nigerian Naira
    PHP: "₱", // Philippine Peso
    PLN: "zł", // Polish Zloty
    PYG: "₲", // Paraguayan Guarani
    THB: "฿", // Thai Baht
    UAH: "₴", // Ukrainian Hryvnia
    VND: "₫", // Vietnamese Dong
  };

  const fetchUserPaymentProfile = () => {
    setPriceInfoTable({ ...priceInfoModal, loading: true });
    apiService
      .getPriceTable()
      .then((res) => {
        setUserPaymentProfile(res.result);
        setPriceInfoTable({ ...priceInfoModal, loading: false });
      })
      .catch((err) => {
        toast.error(err.response.data.message, { autoClose: 3000 });
        setPriceInfoTable({
          ...priceInfoModal,
          loading: false,
          visible: false,
        });
      });
  };

  useEffect(() => {
    if (priceInfoModal.visible) {
      fetchUserPaymentProfile();
    }
  }, [priceInfoModal.visible]);

  return (
    <div
      className={
        window.location.pathname.includes("home")
          ? classNames(styles.containerClass, "container-fluid bg-white pt-3 pb-3 border-top")
          : classNames(
              styles.containerClass,
              "container-fluid bg-white mt-5 pt-3 pb-3 border-top"
            )
      }
    >
      <div className={classNames(styles.footer, "container-xl")}>
        <Row className="">
          <Col className="d-none d-md-block">
            <a
              href="https://molecularconnections.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className={classNames(styles.logo)}
                src={process.env.PUBLIC_URL + "/logos/mcImg.png"}
                alt="logo"
              />
            </a>
          </Col>
          <Col className="d-sm-block d-md-none">
            <a
              href="https://molecularconnections.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className={classNames(styles.logo, "")}
                src={process.env.PUBLIC_URL + "/logos/mcImg.png"}
                alt="logo"
              />
            </a>
          </Col>
        </Row>
        <Row>
          <Col md={7} className="mt-5 d-none d-md-block">
            <h6>Corporate Address:</h6>
            <p className={styles.footerContent}>
              Heritage Building,#59/2,
              <br />
              Kaderanahalli, 100 feet road,
              <br />
              Banashankari 2nd Stage,
              <br />
              Bangalore 560070,
              <br />
              Karnataka, India
            </p>
            <h6>Tel:</h6>
            <p>
              <a href="tel:+91 80 2669 0145" class="text-reset">
                +91 80 2669 0145
              </a>
            </p>
            <h6>Email:</h6>
            <p>
              <a href="mailto:info@molecularconnections.com" class="text-reset">
                info@molecularconnections.com
              </a>
            </p>
          </Col>

          <Col md={7} className="mt-5 d-sm-block d-md-none">
            <h6>Corporate Address:</h6>
            <p className={styles.footerContent}>
              Heritage Building,#59/2,
              <br />
              Kaderanahalli, 100 feet road,
              <br />
              Banashankari 2nd Stage,
              <br />
              Bangalore 560070,
              <br />
              Karnataka, India
            </p>
            <h6>Tel:</h6>
            <p>
              <a href="tel:+91 80 2669 0145" class="text-reset">
                +91 80 2669 0145
              </a>
            </p>
            <h6>Email:</h6>
            <p>
              <a href="mailto:info@molecularconnections.com" class="text-reset">
                info@molecularconnections.com
              </a>
            </p>
          </Col>

          <Col md={3} className="mt-5 d-none d-md-block">
            <Nav className="flex-column text-left align-items-left">
              <Nav.Item className="mt-2">
                <a
                  className="text-dark text-decoration-none"
                  href="https://molecularconnections.com/about-us/"
                  target="_blank"
                >
                  About Us
                </a>
              </Nav.Item>
              {/* <Nav.Item className="mt-2">
                <a
                  className="text-dark text-decoration-none"
                  href={process.env.PUBLIC_URL + "/pricing-info"}
                >
                  Pricing Information
                </a>
              </Nav.Item> */}
              {/* <Nav.Item className="mt-2">
                <a
                  className="text-dark text-decoration-none"
                  href={process.env.PUBLIC_URL + "/privacy-policy"}
                >
                  Privacy Policy
                </a>
              </Nav.Item> */}
              <Nav.Item className="mt-2">
                <a
                  className="text-dark text-decoration-none"
                  href={process.env.PUBLIC_URL + "/about-authorease"}
                >
                  About AuthorEase
                </a>
              </Nav.Item>
              {/* <Nav.Item className="mt-2">
                <a
                  className="text-dark text-decoration-none"
                  href={process.env.PUBLIC_URL + "/terms-and-conditions"}
                >
                  Terms & Conditions
                </a>
              </Nav.Item> */}
              {/* <Nav.Item className="mt-2">
                <a
                  className="text-dark text-decoration-none"
                  href={process.env.PUBLIC_URL + "/frequently-asked-questions"}
                >
                  FAQs
                </a>
              </Nav.Item> */}
            </Nav>
          </Col>

          <Col md={3} className="mt-5 d-sm-block d-md-none">
            <Nav className="flex-column text-left align-items-left">
              {/* <Nav.Item><h5 className="mt-2">About us</h5></Nav.Item> */}
              <Nav.Item className="mt-2">
                <a
                  className="text-dark text-decoration-none"
                  href="https://molecularconnections.com/about-us/"
                  target="_blank"
                >
                  About Us
                </a>
              </Nav.Item>
              {/* <Nav.Item className="mt-2"><a className="text-dark text-decoration-none" href={process.env.PUBLIC_URL + "/taxonomy-tagging"} >Taxonomy Tagging</a></Nav.Item> */}
              {/* <Nav.Item className='mt-2'><a className='text-dark text-decoration-none cursor-pointer' onClick={() => {setPriceInfoTable({...priceInfoModal, visible: true, loading: true})}}>Pricing Information</a></Nav.Item> */}
              {/* <Nav.Item className="mt-2">
                <a
                  className="text-dark text-decoration-none"
                  href={process.env.PUBLIC_URL + "/pricing-info"}
                >
                  Pricing Information
                </a>
              </Nav.Item> */}
              <Nav.Item className="mt-2">
                <a
                  className="text-dark text-decoration-none"
                  href={process.env.PUBLIC_URL + "/privacy-policy"}
                >
                  Privacy Policy
                </a>
              </Nav.Item>
              <Nav.Item className="mt-2">
                <a
                  className="text-dark text-decoration-none"
                  href={process.env.PUBLIC_URL + "/about-authorease"}
                >
                  About AuthorEase
                </a>
              </Nav.Item>
              <Nav.Item className="mt-2">
                <a
                  className="text-dark text-decoration-none"
                  href={process.env.PUBLIC_URL + "/terms-and-conditions"}
                >
                  Terms & Conditions
                </a>
              </Nav.Item>
            </Nav>
          </Col>

          <Col md={2} className="mt-5 d-none d-md-block">
            <h6 className="text-secondary text-small">Follow Us: </h6>
            <div className="d-flex flex-row align-items-center">
              <Nav className="my-3">
                <Nav.Item>
                  <a
                    href="https://www.youtube.com/channel/UCesUFzA8D8us3A__S73MrRQ"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mr-3"
                  >
                    <FontAwesomeIcon
                      icon={faYoutube}
                      size="lg"
                      color="#4A4A4A"
                    />
                  </a>
                </Nav.Item>
                <Nav.Item>
                  <a
                    href="https://twitter.com/mc_innohub?lang=en"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mr-3"
                  >
                    <img src={xIcon} alt="Twitter icon" />
                  </a>
                </Nav.Item>
                <Nav.Item>
                  <a
                    href="https://in.linkedin.com/company/molecular-connections"
                    target="_blank"
                    rel="noopener noreferrer"
                    className=""
                  >
                    <FontAwesomeIcon
                      icon={faLinkedin}
                      size="lg"
                      color="#4A4A4A"
                    />
                  </a>
                </Nav.Item>
              </Nav>
            </div>
          </Col>

          <Col md={2} className="mt-5 d-sm-block d-md-none">
            <h6 className="text-secondary text-small">Follow Us: </h6>
            <div className="d-flex flex-row align-items-center">
              <Nav className="my-3">
                <Nav.Item>
                  <a
                    href="https://www.facebook.com/BoneJointJournal"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mr-3"
                  >
                    <FontAwesomeIcon
                      icon={faFacebook}
                      size="lg"
                      color="#4A4A4A"
                    />
                  </a>
                </Nav.Item>
                <Nav.Item>
                  <a
                    href="https://twitter.com/BoneJointPortal"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mr-3"
                  >
                    <img src={xIcon} alt="Twitter icon" />
                  </a>
                </Nav.Item>
                <Nav.Item>
                  <a
                    href="https://uk.linkedin.com/company/the-british-editorial-society-of-bone-&-joint-surgery"
                    target="_blank"
                    rel="noopener noreferrer"
                    className=""
                  >
                    <FontAwesomeIcon
                      icon={faLinkedin}
                      size="lg"
                      color="#4A4A4A"
                    />
                  </a>
                </Nav.Item>
              </Nav>
            </div>
          </Col>
        </Row>
      </div>
      {/* <Modal
        show={priceInfoModal.visible}
        size="lg"
        onHide={() => {
          setPriceInfoTable({ ...priceInfoModal, visible: false });
        }}
      >
        <Modal.Header closeButton className="h6">
          Pricing Information
        </Modal.Header>
        <Modal.Body>
          <Card>
            <Card.Body className="m-0 p-0">
              {priceInfoModal.loading ? (
                <div className="d-flex flex-row justify-content-center">
                  <Spinner animation="grow" className="m-3" />
                </div>
              ) : (
                <>
                  <div className="d-flex flex-row justify-content-between aling-items-center">
                    <h5 className="text-secondary mt-3 ml-3">
                      Cost of credits
                    </h5>
                    <div className="d-flex flex-column my-2 mx-3">
                      <span className="text-secondary font-weight-bold ml-auto">
                        Generic template = 1 credit
                      </span>
                      <span className="text-secondary font-weight-bold ml-auto mb-1">
                        Journal-specific template = 2 credits
                      </span>
                    </div>
                  </div>
                  <table class="table">
                    <tr>
                      <th>Credits</th>
                      {userPaymentProfile[0].prices.map((item) => {
                        return (
                          <>
                            <th>
                              {item.currency} (
                              {currencySymbols[item.currency.toUpperCase()]})
                            </th>
                          </>
                        );
                      })}
                    </tr>
                    {userPaymentProfile.map((priceDetail) => {
                      return (
                        <>
                          {priceDetail.credit !== 2 ? (
                            <tr>
                              <td>
                                {priceDetail.credit} <br />
                                {priceDetail?.discount ? (
                                  <span className="small text-secondary">
                                    ({priceDetail.discount}% disc)
                                  </span>
                                ) : (
                                  ""
                                )}
                              </td>
                              {priceDetail.prices.map((price) => {
                                return (
                                  <>
                                    <td>
                                      {price.amount
                                        ? currencySymbols[
                                            price.currency.toUpperCase()
                                          ] + price.amount
                                        : "N/A"}{" "}
                                      <br />
                                      {price?.listPrice ? (
                                        <span className="small text-secondary">
                                          (list price:{" "}
                                          {currencySymbols[
                                            price.currency.toUpperCase()
                                          ] + price.listPrice}
                                          )
                                        </span>
                                      ) : (
                                        ""
                                      )}
                                    </td>
                                  </>
                                );
                              })}
                            </tr>
                          ) : (
                            <></>
                          )}
                        </>
                      );
                    })}
                  </table>
                </>
              )}
            </Card.Body>
          </Card>
          <div className="d-flex flex-row justify-content-end">
            <button
              onClick={() => {
                history.push("/profile?tab=credits");
                setPriceInfoTable({ ...priceInfoModal, visible: false });
              }}
              disabled={priceInfoModal.loading}
              className="btn-lg mt-3 col-md-3 col-sm-12"
            >
              Purchase now
            </button>
          </div>
        </Modal.Body>
      </Modal> */}
    </div>
  );
}
