import React, { useState, useEffect, useRef } from 'react';
import { Link, Prompt } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { openLoginModal } from '../../store/actions/LoginModalActions';
import { openAlertModal } from '../../store/actions/AlertModalActions';
import { tokenExpired } from '../../store/actions/RefreshTokenAction';
import LocalStorageService from "../../utils/auth_service/AuthServiceStorage";
import { Dropdown, Modal, InputGroup, FormControl, Spinner, Button, ButtonGroup } from 'react-bootstrap';
import { authLogout } from "../../utils/auth_service/AuthServiceHelper";
import history from '../../history';
import classNames from 'classnames';
import styles from './Header.module.scss';
import AxiosService from '../../utils/AxiosService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser, faSquarePlus, faSignOut, faUser, faHome, faMagnifyingGlassArrowRight, faMagnifyingGlass, faDashboard, faIdBadge } from '@fortawesome/free-solid-svg-icons';
import { setDiscardTrue, setGateClose, setGateOpen } from '../../store/actions/NavigateAwayAlertAction';
import { FieldLoader } from '../Loader/CommonLoader';
import { faFile, faFileAlt, faNewspaper } from '@fortawesome/free-regular-svg-icons';
import loginIcon from '../../pages/Home/assets/homepage icon - login.svg'

function Header({exitFunction, stepOneInfo}) {

    const dispatch = useDispatch();
    const loginModalToggle = useSelector(state => state.login.loginModalToggle);
    const navigateAwayStatus = useSelector(state => state.navigateAwayAlert.gateStatus)
    const discardButtonStatus = useSelector(state => state.navigateAwayAlert.discardButton)
    const [loggedIn, setLoggedIn] = useState(false);
    const [userName, setUserName] = useState("");
    const [flag, setFlag] = useState(false);
    const [exitButtonLoading, setExitButtonLoading] = useState(false)
    const params = new URLSearchParams(window.location.search);

    const path = window.location.pathname.split('/')[1];
    const localStorageService = LocalStorageService.getService();

    const validEmailRegex = RegExp(
        /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
    );
    const [formValues, setFormValues] = useState({name: "", email: "", type: "", description: ""});
    const [formErrors, setFormErrors] = useState({ email: "", description: ""});
    const [isLoading, setIsLoading] = useState(false);
    const [logoutLoader, setLogoutLoader] = useState(false)
    const [modalToggle, setModalToggle] = useState(false);
    const [reportFlag, setReportFlag] = useState(false);
    const [mobileToggle, setMobileToggle] = useState(false);
    const [navigationGate, setNavigationGate] = useState(false)
    const [shouldAllowNavigation, setShouldAllowNavigation] = useState(false)
	const [blockedRoute, setBlockedRoute] = useState(false)
    const [blockNavigationModal, setBlockNavigationModal] = useState(false)
    const [logoutClicked, setLogoutClicked] = useState(false)
    const [smallScreenModal, setSmallScreenModal] = useState({visible: false})
    const apiService = new AxiosService();
    const burgerRef = useRef()

    function onLogout(event) {
        event.preventDefault();
        const params = new URLSearchParams();
        params.append("access_token", localStorageService.getAccessToken());
        const config = {
            url: "api/v1/auth/getLogout",
            method: "post",
            data: params
        };
        setLogoutLoader(true)
        authLogout(config).then(response => {
            localStorageService.removeTokens();
            toast.success("Logged out!",  {
                autoClose: 3000,
            });
            dispatch(tokenExpired());
            setLoggedIn(false);
            if(path === 'profile'){
                history.push('/search');
            }
            setLogoutLoader(false)
        }).catch(error => {
            toast.error("Something went wrong!",  {
                autoClose: 3000,
            });
            setLogoutLoader(false)
        });
    }

    function openForm(){
        const authDetails = JSON.parse(localStorageService.getAuthDetails());
        if (localStorageService.getAccessToken()) {
            setFormValues({...formValues, name: authDetails.metaData.first_name + " " + authDetails.metaData.last_name, email: authDetails.userName})
            setModalToggle(true);
        }
        else{
            setReportFlag(true);
            dispatch(openLoginModal());
        }
    }

    function onHideForm(){
        setModalToggle(false);
        setFormValues({name: "", email: "", type: "", description: ""});
        setFormErrors({ email: "", description: ""});
    }

    function handleChange(event){
        event.preventDefault();
        const { name, value } = event.target;
        setFormValues({ ...formValues, [name]: value });
    }

    function handleValidation(event){
        const {name, value} = event.target;
        switch (name) {
            case "email":
                setFormErrors({
                    ...formErrors,
                    email:
                        value.length > 0
                            ? validEmailRegex.test(value)
                                ? ""
                                : "* Please enter a valid Email"
                            : "* Email is required"
                });
                break;
            case "description":
                setFormErrors({
                    ...formErrors,
                    description:   value.trim().length > 0 ? "" : "* Query is required"
                });
                break;
            default:
                break;
        }
    }

    function isValidate(){
        return formValues.email && formValues.description.trim().length;
    }

    function submitResponse(event){
        event.preventDefault();
        setIsLoading(true);
        const query = formValues.type ? formValues.type + ": " : "";
        const data = {
            user: formValues.name,
            email: formValues.email,
            query: query + formValues.description
        }
        apiService.reportIssue(data).then(response => {
            setIsLoading(false);
            setModalToggle(false);
            toast.success(response.data.message,  {
                autoClose: 3000,
            });
        }).catch(error => {
            setIsLoading(false);
            setModalToggle(false);
            toast.error(error.response.data.message,  {
                autoClose: 3000,
            });
        })
        setFormValues({ name: "", email: "", type: "", description: ""});
        setFormErrors({ email: "", description: ""});
    }

    function logoutBtnClick (event) {
        event.preventDefault()
        if(window.location.href.includes("step")) {
            setLogoutClicked(true)
            history.push("/home")
        } else {
            onLogout(event)
            history.push("/home")
        }
    }

    function navigateTo(uri) {
        history.push(uri)
    }

    useEffect(() => {
        if(localStorageService.getAccessToken()){
            setLoggedIn(true);
            if(flag){
                dispatch(openAlertModal(''));    
                setFlag(false);
            }
            if(reportFlag){
                openForm();
                setReportFlag(false);
            }
        }
        else{
            setLoggedIn(false);
        }
    }, [localStorageService.getAccessToken()])

    useEffect(() => {
        const authVal = localStorageService.getAuthDetails();
        if (authVal) {
            let metaData = JSON.parse(authVal).metaData;
            if(metaData){
                setUserName(metaData);
            }
            else{
                let userEmail = JSON.parse(authVal).userName;
                userEmail = userEmail.split('@')[0]
                setUserName(userEmail)
            }
        }
    }, [loginModalToggle]);

    useEffect(() => {
        if(window.location.href.includes("step=")) {
            if(navigateAwayStatus === false) {
                dispatch(setGateClose())
            }
            setNavigationGate(true)
            setShouldAllowNavigation(false)
        } else {
            if(navigateAwayStatus === true) {
                dispatch(setGateOpen())
            }
            setNavigationGate(false)
            setShouldAllowNavigation(true)
        }
    }, [window.location.href])

    useEffect(() => {
        if(!navigateAwayStatus) {
            setNavigationGate(false)
            setShouldAllowNavigation(true)
        }
    }, [navigateAwayStatus])

    useEffect(() => {
        if (window.screen.width < 576) {
            if(window.location.href.includes("step")) {
                setSmallScreenModal({...smallScreenModal, visible: true})
            }
        } 
    }, [window.location.href])

    return (
        <div className={classNames('container-fluid container-xl', styles.containerClass)}>

       
            <div className={classNames("d-flex justify-content-between align-items-center container-xl py-2")}>

                <div className={classNames(styles.navigation, styles.menuToggle)}>
                    <input type="checkbox" onChange={($event) => setMobileToggle($event.target.checked)} ref={burgerRef}/>
                    <span></span>
                    <span></span>
                    <span></span>
                    <ul className={styles.menu}>
                        <li>
                            <Button onClick={() => {navigateTo("/search?type=journal"); burgerRef.current.click()}} className={path === "search" ? classNames(styles.newManuscriptButton, "mx-3", "rounded btn-light text-left") : classNames(styles.headerButton, "mx-3 btn-light text-left")}>
                                {/* <FontAwesomeIcon icon={faMagnifyingGlass} className='mr-2'/> */}
                                Journal Finder
                            </Button>
                        </li>
                        {/* <li>
                            <Button 
                                    onClick={() => {
                                    if(window.location.pathname === "/orthopub/select-journal") {
                                        window.location.search = "sort=Rank"
                                    } else {
                                        navigateTo("/select-journal?sort=Rank")
                                    }
                                    burgerRef.current.click()
                                }} 
                                className={path === "select-journal" ? classNames(styles.newManuscriptButton, "mx-3", "rounded btn-light text-left") : classNames(styles.headerButton, "mx-3 btn-light text-left")}
                            >
                                Add New Manuscript
                            </Button>
                        </li> */}
                        <li>
                            <Button onClick={() => {navigateTo("/my-dashboard"); burgerRef.current.click()}} className={path === "my-dashboard" ? classNames(styles.newManuscriptButton, "mx-3", "rounded btn-light text-left") : classNames(styles.headerButton, "mx-3 btn-light text-left")}>
                                {/* <FontAwesomeIcon icon={faDashboard} className='mr-2'/> */}
                                My Dashboard
                            </Button>
                        </li>
                        {/* <li>
                            <Button onClick={() => {navigateTo("/pricing-info"); burgerRef.current.click()}} className={path === "pricing-info" ? classNames(styles.newManuscriptButton, "mx-3", "rounded btn-light text-left") : classNames(styles.headerButton, "mx-3 btn-light text-left")}>
                                Pricing Information
                            </Button>
                        </li> */}
                        { JSON.parse(localStorageService.getAuthDetails()) !== null && JSON.parse(localStorageService.getAuthDetails())?.authorities[0]?.authority === "Admin" &&
                            <li>
                                <Button onClick={() => {navigateTo("/admin-dashboard"); burgerRef.current.click()}} className={path === "admin-dashboard" ? classNames(styles.newManuscriptButton, "mx-3", "rounded btn-light text-left") : classNames(styles.headerButton, "mx-3 btn-light text-left")}>
                                    {/* <FontAwesomeIcon icon={faIdBadge} className='mr-2'/> */}
                                    Admin Dashboard
                                </Button>
                            </li>
                        }
                    </ul>
                </div>
                
                <Link to="/home" className="d-flex">
                    <img className={styles.logo} src={process.env.PUBLIC_URL + '/logos/aeLogo.png'} alt="logo" />
                </Link>  
                
                <div className={styles.action}>
                    <div className="d-flex justify-content-around align-items-center">
                        <Button onClick={() => {navigateTo("/search?type=journal")}} className={path === "search" ? classNames(styles.newManuscriptLink, "mx-3", "rounded btn-light lato-regular") : classNames(styles.headerButton, styles.headerLink, "mx-3 btn-light lato-regular")}>Journal Finder</Button>
                        {/* <Button onClick={() => {
                            if(window.location.pathname === "/orthopub/select-journal") {
                                window.location.search = "sort=Rank"
                            } else {
                                navigateTo("/select-journal?sort=Rank")
                            }
                        }} className={path === "select-journal" ? classNames(styles.newManuscriptLink, "mx-3", "rounded btn-light") : classNames(styles.headerButton, styles.headerLink, "mx-3 btn-light")}>Add New Manuscript</Button> */}
                        <Button onClick={() => {navigateTo("/my-dashboard")}} className={path === "my-dashboard" ? classNames(styles.newManuscriptLink, "mx-3", "rounded btn-light") : classNames(styles.headerButton, styles.headerLink, "mx-3 btn-light")}>My Dashboard</Button>
                        {/* <Button onClick={() => {navigateTo("/pricing-info")}} className={path === "pricing-info" ? classNames(styles.newManuscriptLink, "mx-3", "rounded btn-light") : classNames(styles.headerButton, styles.headerLink, "mx-3 btn-light")}>Pricing Information</Button> */}
                        { JSON.parse(localStorageService.getAuthDetails()) !== null && JSON.parse(localStorageService.getAuthDetails())?.authorities[0]?.authority === "Admin" &&
                            <Button onClick={() => {navigateTo("/admin-dashboard")}} className={path === "admin-dashboard" ? classNames(styles.newManuscriptLink, "mx-3", "rounded btn-light") : classNames(styles.headerButton, styles.headerLink, "mx-3 btn-light")}>Admin Dashboard</Button>
                        }
                    </div>
                    {loggedIn ?
                    <Dropdown className={styles.headerUserDropdown}>
                        <Dropdown.Toggle id="dropdown-basic" variant="light" size="sm" className={styles.headerButton}>
                            { logoutLoader ? 
                                    <Spinner animation='grow' size='sm'/> 
                                : 
                                    <div className='d-flex flex-row align-items-center'>
                                        <span className={classNames(styles.userBadge, "rounded-circle mx-1 p-2")}>
                                            { userName.first_name && userName.last_name ? 
                                                userName.first_name.charAt(0) + userName.last_name.charAt(0)
                                            : 
                                                "N/A"
                                            }
                                        </span>
                                        <span className='d-none d-sm-flex'>{userName.first_name}</span>
                                    </div>
                            }
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item as="button" onClick={() => {history.push('/profile')}}>
                                <FontAwesomeIcon icon={faUser} />
                                <span className='ml-2'>Profile</span></Dropdown.Item>

                            <Dropdown.Item as="button" onClick={(e) => logoutBtnClick(e)}
                            >
                                <FontAwesomeIcon icon={faSignOut} />
                                <span className='ml-2'>Logout</span></Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    :
                    <span onClick={() => dispatch(openLoginModal())}>
                        <span className={styles.loginButton}>
                            <img className={styles.customAvatar} src={loginIcon} title="User" alt="user" />
                            <span className={styles.link}>{logoutLoader ? <Spinner animation='grow' size='xs'/> : "Login"}</span>
                        </span>
                    </span>
                }
                </div>

            </div>
            
            {/* <button className={styles.reportLink} onClick={() => {window.open("https://www.surveymonkey.com/r/OrthoPublish", '_blank');}}>Feedback</button> */}
            
            {/* Navigation prompt to control user flow when navigating away from manuscript formatting steps */}
            {navigationGate && <Prompt
				message={(location, action) => {
						if (shouldAllowNavigation) {
							return true
						} else {
							if (location.search.includes("step")) {
								return true
							} else {
								setBlockedRoute(location.pathname+location.search)
                                setNavigationGate(false)
                                setShouldAllowNavigation(true)
								setBlockNavigationModal(true)
								return false
							}
						}
				}}
			/>}

            <Modal show={modalToggle} size="md" onHide={() => onHideForm()}>
                <Modal.Header closeButton>
                    <Modal.Title><b>Submit your feedback</b></Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-4">
                    <label htmlFor="email" className="m-0 font-weight-bold">Email Address</label>
                    <InputGroup className={classNames("mt-2", styles.customInput)}>
                        <InputGroup.Prepend>
                            <InputGroup.Text id="basic-addon1">
                                <i style={{ fontSize: '18px' }} className="material-icons">email</i>
                            </InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl
                            aria-label="Email"
                            type="email"
                            name="email"
                            value={formValues.email}
                            disabled={true}
                            readOnly={true}
                            onChange={event => handleChange(event)}
                            onBlur={event => handleValidation(event)}
                            aria-describedby="basic-addon1"
                        />
                    </InputGroup>
                    <span className={styles.formErrors}>{formErrors.email}</span>
       
                    <label htmlFor="type" className="m-0 mb-2 font-weight-bold">Please include the following content in Orthopub:</label>
                    <FormControl
                        as="select"
                        aria-label="Type"
                        name="type"
                        value={formValues.type}
                        onChange={event => handleChange(event)}
                        className={classNames(styles.customInput, "mb-4")}>
                            <option value="">Please select</option>
                            <option value="Journal">Journal</option>
                            <option value="Other content type">Other content type</option>
                    </FormControl>

                    <label htmlFor="description" className="m-0 font-weight-bold">Tell us what you think or what content you would like to see included:</label>
                        <FormControl
                            as="textarea"
                            aria-label="Description"
                            name="description"
                            rows="6"
                            value={formValues.description}
                            className={styles.customInput}
                            onChange={event => handleChange(event)}
                            onBlur={event => handleValidation(event)}
                        />
                    <span className={styles.formErrors}>{formErrors.description}</span>

                    <button onClick={(event) => submitResponse(event)} className={styles.reportButton} disabled={!isValidate()}>
                        {isLoading ? "Loading" : "Submit"}
                    </button>
                </Modal.Body>
            </Modal>
        
            {/* Warning modal for navigating to other pages while editing manuscript */}
			<Modal show={blockNavigationModal}  dialogClassName=""
				onHide={() => {
                    if(!navigateAwayStatus) {
                        setBlockNavigationModal(false)
                        setNavigationGate(true)
                        setShouldAllowNavigation(false)
                    } else {
                        setBlockNavigationModal(false)
                        setNavigationGate(true)
                        setShouldAllowNavigation(false)
                    }
                    setTimeout(() => {
                        dispatch(setDiscardTrue())
                    }, [300])
				}}
				onShow={() => {
                    if(!navigateAwayStatus) {
                        setNavigationGate(false)
                        setShouldAllowNavigation(true)
                    }
				}}
			>
				<Modal.Header closeButton className="h6">Warning!</Modal.Header>
				<Modal.Body className="pt-0">
					<p>You are leaving the document you are working on. The latest version with your changes can be found in 'Drafts' in your Dashboard </p>
					<button className="btnOutline w-100 mt-2" 
						onClick={(e) => {
							setBlockNavigationModal(false)
							setNavigationGate(true)
                            setShouldAllowNavigation(false)
                            if(logoutClicked) {
                                setLogoutClicked(false)
                            }
						}}
					>
                        {exitButtonLoading ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : "Cancel"}
                    </button>
					{ discardButtonStatus &&
                        <button 
                            onClick={(e) => {
                                if(logoutClicked) {
                                    onLogout(e)
                                    setLogoutClicked(false)
                                }
                                // if((params.get("step") === 1 || params.get("step") === "1") && stepOneInfo.manuscriptTitle.length < 1) {
                                if((params.get("step") === 1 || params.get("step") === "1") && false) { //Use above commented condition to enable this
                                    if(stepOneInfo.templateType === "Generic") {
                                        toast.warning("You have used 1 credit for this template. Please enter a title before leaving Step One. You can then return to this template from your Dashboard at any time.", {autoClose: 5000, position: "top-center"})
                                    } else {
                                        toast.warning("You have used 2 credits for this template. Please enter a title before leaving Step One. You will then be able to return to this template from your Dashboard at any time.", {autoClose: 5000, position: "top-center"})
                                    }
                                    setBlockNavigationModal(false)
                                    setNavigationGate(true)
                                    setShouldAllowNavigation(false)
                                } else {
                                    history.push(blockedRoute)
                                    setBlockNavigationModal(false)
                                    setNavigationGate(true)
                                }
					        }} 
                        className="w-100 mt-2"
                    >
                        {exitButtonLoading ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : "Discard changes & leave"}
                        </button>
                    }
                    <button onClick={(e) => {
                        setExitButtonLoading(true)
                        if(logoutClicked) {
                            onLogout(e)
                            setLogoutClicked(false)
                        }
						setNavigationGate(true)
                        exitFunction().then((res) => {
                            toast.success(res.result, {
                                position: "top-center",
                                autoClose: 3000
                            })
                            setBlockNavigationModal(false)
                            setExitButtonLoading(false)
                            history.push(blockedRoute)
                        }, (err) => {
                            setBlockNavigationModal(false)
                            setNavigationGate(true)
                            setShouldAllowNavigation(false)
                            setExitButtonLoading(false)
                            toast.error(err.response.data.message,{
								position: "top-center",
                                autoClose: 3000
							})
                        })
					}} className="w-100 mt-2">
                        {exitButtonLoading ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : "Save changes & leave"}
                    </button>
				</Modal.Body>
        	</Modal>

            <Modal size='sm' show={smallScreenModal.visible} onHide={() => {setSmallScreenModal({...smallScreenModal, visible: false})}}>
				<Modal.Header closeButton className="h6">Small screen size detected!</Modal.Header>
				<Modal.Body className="pt-0">
					<p>
                        AuthorEase has been optimised for a screen size of 576. We have detected that you are using a device with a screen size of {window.screen.width}. If you wish to format a manuscript, please use a larger device for a better experience. 
                    </p>
                    <div className='d-flex justify-content-end'>
                        <button className='w-25' onClick={() => {setSmallScreenModal({...smallScreenModal, visible: false})}}>Ok</button>
                    </div>
				</Modal.Body>
        	</Modal>
        </div>
    )
}

export default Header;