import React, { useState, useEffect } from 'react';
import { InputGroup, FormControl, Container, Row, Col, Spinner, Card, Button, Form } from 'react-bootstrap';
import { useDispatch } from "react-redux";
import { openLoginModal, closeLoginModal } from '../../store/actions/LoginModalActions';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import AxiosService from '../../utils/AxiosService';
import styles from './Register.module.scss';
import Login from '../Login/Login';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import history from '../../history';
import { Link } from 'react-router-dom';

function Register() {

    const dispatch = useDispatch();
    const apiService = new AxiosService();
    const [isLoading, setIsLoading] = useState(false);
    const [passwordToggle, setPasswordToggle] = useState(false);
    const [invalidCredentials, setInvalidCredentials] = useState('');

    const [isRegistered, setIsRegistered] = useState(false);
    const [isOrtho, setIsOrtho] = useState(false)
    const [isTermNCondition, setIsTermNCondition] = useState(false);
    const [isBnjQuaterly, setIsBnjQuaterly] = useState(false)


    const [formValues, setFormValues] = useState({ firstName: '', lastName: '', currentAffiliation: '', orcidId: '', email: '', password: '', phone: '', fax: '', designation:'', country: ''});
    const [formErrors, setFormErrors] = useState({ firstName: '', lastName: '', currentAffiliation: '', orcidId: '', email: '', password: '', ortho: '', bnjQuaterly: '', termNCondition:'', phone: '', fax: '', designation:'', country: ''});
    const validEmailRegex = RegExp(
        /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
    );
    const validPassword = RegExp("\\s");
    const validText = RegExp(/^([^\s\d]+)+(\s{1}[^\s\d]+)*$/);
    const validPhoneNo = RegExp(/\+?\d{1,4}?[-.\s]?\(?\d{1,3}?\)?[-.\s]?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}/)
    const noChar = RegExp(/^[^a-zA-Z]*$/)

    function handleChange(event) {
        event.preventDefault();
        const { name, value } = event.target;
        setFormValues({ ...formValues, [name]: value });
    }

    function handleLogin() {
        // dispatch(openLoginModal())
        history.push("/my-dashboard")
    }
 
    function handleValidation(event){
        const {name, value} = event.target;
        typeof value === "string" ?
            setFormValues({...formValues, [name]: value.trim()})
        :
            setFormValues({...formValues, [name]: value})
        switch (name) {
            case "email":
                setFormErrors({
                    ...formErrors,
                    email:
                        value.length > 0
                            ? validEmailRegex.test(value)
                                ? ""
                                : "* Please enter a valid Email"
                            : "* Email is required"
                });
                break;
            case "password":
                setFormErrors({
                    ...formErrors,
                    password:
                        value?.trim().length > 0
                            ? value?.trim().length > 5
                                ? validPassword.test(value) ? "*Whitespaces not allowed" : ""
                                : "* Password must be 6 characters"
                            : "* Password is required"
                });
                break;
            case "firstName":
                setFormErrors({
                    ...formErrors,
                    firstName:  value?.length === 0 ?"* First Name is required": value?.length > 0 && value?.length < 2 ?  "First Name must be min. 2 characters." : !validText.test(value.trim()) ? "Numericals or white space not accepted": value?.length > 15? "First Name must be max. 15 characters":""
                });
                break;
            case "lastName":
                setFormErrors({
                    ...formErrors,
                    lastName:   value?.length === 0 ? "* Last Name is required": !validText.test(value.trim()) ? "Numericals or white space not accepted": value?.length > 15? "Last Name must be max. 15 characters": ""
                });
                break;
            case "currentAffiliation":
                setFormErrors({
                    ...formErrors,
                    currentAffiliation:   value?.trim().length > 0 ? value?.trim().length > 2 ? "" : "Company/Institution must be min. 3 characters." : "* Company/Institution is required"
                });
                break;
            case "phone":
				let trimedPhoneVal = formValues?.phone?.split('').filter(v=> v !="-" && v!="+").join('')
                setFormErrors({
                    ...formErrors,
                    phone:   trimedPhoneVal.length>0 && trimedPhoneVal.length < 7 ?  "Phone number must be min. 7 digit." : formValues?.phone?.length > 0 ? !validPhoneNo.test(trimedPhoneVal) ? "Enter valid phone number" : !noChar.test(trimedPhoneVal) ? "Phone numbers must not include any alphabetical characters" : "" : ""
                });
                break;
            case "fax":
					let trimedFaxVal = formValues?.fax?.split('').filter(v=> v !="-" && v!="+").join('')
                    setFormErrors({
                            ...formErrors,
                            fax:  trimedFaxVal.length>0 && trimedFaxVal.length < 7 ? "Fax must be min. 7 digit.": ""
                    });
                    break;
            case "designation":
                setFormErrors({
                        ...formErrors,
                        designation: value?.trim().length >0 && value?.trim().length<3 ? "Job title must be min. 3 characters.": ""
                });
                break;
            case "country":
                setFormErrors({
                    ...formErrors,
                    country: value?.trim().length > 0 && value?.trim().length < 3 ? "Country must be min. 3 characters." : ""
                })
            default:
                break;
        }
    }

    function resetFormFields() {
        setFormValues({ ...formValues, email: '', password: '', firstName: '', lastName: '', currentAffiliation: '', orcidId: '', phone: '', fax: '', designation: '' });
    }

    function handleSubmit(event) {
        event.preventDefault();
        if(formErrors.designation || formErrors.phone || formErrors.fax || formErrors.country){
            toast.error("Form submission error!",  {
                autoClose: 3000,
            })
            setInvalidCredentials("");
        }
        else{
            if(formValues.email?.trim().length > 0 && validEmailRegex.test(formValues.email) && formValues.password?.trim().length > 5 && !validPassword.test(formValues.password) && formValues.firstName?.trim().length >= 2 && formValues.lastName?.trim().length > 0 && formValues.currentAffiliation?.trim().length > 2){
            setIsLoading(true);
            setInvalidCredentials('');
            const data = {
                username: formValues.email?.trim().toLowerCase(),
                email: formValues.email?.trim().toLowerCase(),
                password: formValues.password,
                metaData: {
                    first_name: formValues.firstName?.trim(), 
                    last_name: formValues.lastName?.trim(), 
                    affiliation: formValues.currentAffiliation?.trim(), 
                    orcid: formValues.orcidId?.trim(),
                    receive_news_on_orthopublish: isOrtho ? "Yes" : "No",
                    receive_news_and_offers_from_bnj: isBnjQuaterly ? "Yes" : "No",
                    phone: formValues.phone,
                    fax: formValues.fax,
                    designation: formValues.designation?.trim(),
                    country: formValues.country?.trim(),
                    terms_and_conditions: isTermNCondition ? "Yes" : "No"
                }
            }
            apiService.register(data).then((response) => {
                setIsLoading(false);
                resetFormFields();
                setIsRegistered(true);
            }).catch((error) => {
                setIsLoading(false);
                toast.error(error.response.data.message,  {
                    autoClose: 3000,
                })
            })
        }
        else{
            window.scrollTo({top: 0})
            
            setFormErrors({...formErrors, email: formValues.email.length ? validEmailRegex.test(formValues.email) ? '' : '*Enter a valid email' : '* Email is required',
                password: formValues.password?.trim().length ? formValues.password?.trim().length > 5 ? '' : '* Password must be 6 characters' : '* Password is required',
                firstName: formValues.firstName?.length === 0 ?"* First Name is required": formValues.firstName?.length > 0 && formValues.firstName?.length < 2 ?  "First Name must be min. 2 characters." : !validText.test(formValues.firstName) ? "Numericals or white space not accepted": formValues.firstName?.length>15? "First Name must be max. 15 characters":"",
                lastName: formValues.lastName?.length ===0 ? '* Last Name is required':  !validText.test(formValues.lastName) ? "Numericals or white space not accepted": formValues.lastName?.length>15? "Last Name must be max. 15 characters":"",
                currentAffiliation: formValues.currentAffiliation?.trim().length ? formValues.currentAffiliation?.trim().length > 2 ? '' : '* Company/Institution must be 3 characters' : '* Company/Institution is required', 
            })
            
            setInvalidCredentials("* fields are required");
            return
        }
        }
    }

    function handleNumberChange(event){
        event.preventDefault();
        const {name, value} = event.target
        if(event.target.validity.valid){
            setFormValues({...formValues,[name]: value})
        }
        else if (value === '' || value === '-') {
            setFormValues({...formValues,[name]: value})
        }
    }

    useEffect(() => {
        setIsRegistered(false);
        dispatch(closeLoginModal());
    }, [])

    return (
        <Container fluid >
            <Row className='min-vh-100'>
                <Col md={7} className="m-auto d-flex justify-content-center align-items-center">
                    <Card className='px-3 pb-3 my-3 w-100'>
                        <div className='align-items-center d-flex flex-column'>
                            <img className='my-2' src={process.env.PUBLIC_URL + '/logos/aeLogo.png'}  width="30%" alt='OrthoPublish Logo'></img>
                            <p className={styles.logoLabel}>Get your paper ready for submission</p>
                            <hr style={{width: "200px"}}/>
                        </div>
                        {isRegistered ?                        
                            <div className='m-4'>
                                <h6 style={{fontWeight: 400, margin: '1rem 0rem'}}>A verification email is on the way. If you don't receive it please check your junk folder.</h6>
                                <button className="w-100" onClick={() => {history.push("/search")}}>Go to Search</button>
                            </div>
                        : 
                        <>

                            <h4 className="text-center mb-3">Create an account</h4>

                            <form className={styles.registerForm}>

                                {invalidCredentials && <div className={styles.validators}><span>{invalidCredentials}</span><i className="fa fa-close float-right mt-sm-1" onClick={() => setInvalidCredentials('')}></i></div>}

                                <label htmlFor="fisrtName">First Name <span className="text-danger">*</span></label>
                                <FormControl
                                    type="text"
                                    aria-label="First Name"
                                    name="firstName"
                                    value={formValues.firstName}
                                    className={styles.customInput}
                                    placeholder="Enter first name"
                                    onChange={event => handleChange(event)}
                                    onBlur={event => handleValidation(event)}
                                />
                                <span className={styles.formErrors}>{formErrors.firstName}</span>

                                <label htmlFor="lastName">Last Name <span className="text-danger">*</span></label>
                                <FormControl
                                    type="text"
                                    aria-label="Last Name"
                                    name="lastName"
                                    value={formValues.lastName}
                                    className={styles.customInput}
                                    placeholder="Enter last name"
                                    onChange={event => handleChange(event)}
                                    onBlur={event => handleValidation(event)}
                                />
                                <span className={styles.formErrors}>{formErrors.lastName}</span>

                                <label htmlFor="currentAffiliation">Company/Institution  <span className="text-danger">*</span></label>
                                <FormControl
                                    type="text"
                                    aria-label="Company/Institution"
                                    name="currentAffiliation"
                                    value={formValues.currentAffiliation}
                                    className={styles.customInput}
                                    placeholder="Enter Company/Institution"
                                    onChange={event => handleChange(event)}
                                    onBlur={event => handleValidation(event)}
                                />
                                <span className={styles.formErrors}>{formErrors.currentAffiliation}</span>

                                <label htmlFor="designation">Job Title</label>
                                <FormControl
                                    type="text"
                                    aria-label="Job title"
                                    name="designation"
                                    value={formValues.designation}
                                    className={styles.customInput}
                                    placeholder="Enter job title"
                                    onChange={event => handleChange(event)}
                                    onBlur={event => handleValidation(event)}
                                />
                                <span className={styles.formErrors}>{formErrors.designation}</span>

                                <label htmlFor="orcidId">ORCID ID</label>
                                <FormControl
                                    type="text"
                                    aria-label="ORCID ID"
                                    name="orcidId"
                                    value={formValues.orcidId}
                                    className={styles.customInput}
                                    placeholder="Enter ORCID ID"
                                    onChange={event => handleChange(event)}
                                    // onBlur={event => handleValidation(event)}
                                />
                                <span className={styles.formErrors}>{formErrors.orcidId}</span>

                                <label htmlFor="email">Email Address <span className="text-danger">*</span></label>
                                <InputGroup>
                                    <FormControl
                                        aria-label="Email"
                                        type="email"
                                        name="email"
                                        value={formValues.email}
                                        onChange={event => handleChange(event)}
                                        placeholder="Enter login email address"
                                        onBlur={event => handleValidation(event)}
                                        aria-describedby="basic-addon1"
                                    />
                                </InputGroup>
                                <span className={styles.formErrors}>{formErrors.email}</span>
                                
                                <div className={styles.inputNumberStyle}>
                                    <label htmlFor="phone">Phone</label>
                                    <InputGroup>
                                        <FormControl
                                            aria-label="Phone"
                                            type="tel"
                                            pattern="^[0-9+-]*$"
                                            name="phone"
                                            value={formValues.phone}
                                            onChange={event => handleNumberChange(event)}
                                            placeholder="Enter phone number"
                                            onBlur={event => handleValidation(event)}
                                            // onKeyDown={(event)=> (event.keyCode === 69 || event.keyCode === 190 ) && event.preventDefault()}
                                            aria-describedby="basic-addon2"
                                        />
                                    </InputGroup>
                                    <span className={styles.formErrors}>{formErrors.phone}</span>
                                </div>

                                {/* <div className={styles.inputNumberStyle}>
                                <label htmlFor="fax">Fax</label>
                                <InputGroup className={classNames("mt-2")}>
                                    <FormControl
                                        aria-label="Fax"
                                        type="tel"
                                        pattern="^[0-9+-]*$"
                                        name="fax"
                                        value={formValues.fax}
                                        onChange={event => handleNumberChange(event)}
                                        placeholder="Enter your fax number"
                                        onBlur={event => handleValidation(event)}
										// onKeyDown={(event)=> (event.keyCode === 69 || event.keyCode === 190 ) && event.preventDefault()}
                                        aria-describedby="basic-addon3"
                                    />
                                </InputGroup>
                                <span className={styles.formErrors}>{formErrors.fax}</span>
                                </div> */}
                                <label htmlFor="country">Country</label>
                                <FormControl
                                    type="text"
                                    aria-label="Country"
                                    name="country"
                                    value={formValues.country}
                                    className={styles.customInput}
                                    placeholder="Enter country"
                                    onChange={event => handleChange(event)}
                                    onBlur={event => handleValidation(event)}
                                />
                                <span className={styles.formErrors}>{formErrors.country}</span>

                                <label htmlFor="password">Password <span className="text-danger">*</span></label>
                                <InputGroup>
                                    <FormControl
                                        type={passwordToggle ? "text" : "password"}
                                        aria-label="Password"
                                        name="password"
                                        value={formValues.password}
                                        placeholder="Min. 6 characters"
                                        onChange={event => handleChange(event)}
                                        onBlur={event => handleValidation(event)}
                                        aria-describedby="basic-addon2"
                                    />
                                    <InputGroup.Append>
                                        <InputGroup.Text>
                                            {passwordToggle ? <FontAwesomeIcon icon={faEye} onClick={() => setPasswordToggle(!passwordToggle)} /> : <FontAwesomeIcon icon={faEyeSlash} onClick={(event) => setPasswordToggle(!passwordToggle)} /> }
                                        </InputGroup.Text>
                                    </InputGroup.Append>
                                </InputGroup>
                                <span className={styles.formErrors}>{formErrors.password}</span>
                                <div>
                                    <h5>STAY UP-TO-DATE</h5>
                                    <p>Please join our mailing list if you would like  Molecular Connections to contact you about relevant news and special offers.</p>
                                    <Form className='my-2'>
                                        <span className='pr-3'>Opt-in to receive news and updates about AuthorEase from Molecular Connections via email</span>
                                        <Form.Check 
                                            // reverse
                                            className='form-switch d-inline'
                                            type="switch"
                                            id="ortho-switch"
                                            value={isOrtho}
                                            onChange={()=> setIsOrtho(v=>!v)}
                                        />
                                    </Form>
                                    <Form className='mb-2'>
                                        <span className='pr-3'>Opt-in to receive news, updates and special offers from Molecular Connections via email</span>
                                        <Form.Check 
                                            // reverse
                                            className='form-switch d-inline'
                                            type="switch"
                                            id="bnjQuaterly-switch"
                                            value={isBnjQuaterly}
                                            onChange={() => setIsBnjQuaterly(v=>!v)}
                                        />
                                    </Form>
                                    <p>You can unsubscribe from our mailing list any time.</p>
                                    {/* <Form className='mb-2'>
                                        <span className='pr-3 h6'>I have read and agree to the <Link to="/terms-and-conditions" target="_blank"> Terms and Conditions</Link></span>
                                        <Form.Check 
                                            // reverse
                                            className='form-switch d-inline'
                                            type="switch"
                                            id="termNCondition-switch"
                                            value={isTermNCondition}
                                            onChange={()=> setIsTermNCondition(v=>!v)}
                                        />
                                    </Form>
                                    <h6 className='my-3'>View our <Link to={'/privacy-policy'} target="_blank">Privacy Policy</Link></h6> */}
                                </div>
                                {isLoading ? 
                                    <button className='w-100' disabled={isLoading}>
                                        <span>Loading
                                            <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                            <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                            <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />
                                        </span>
                                    </button>
                                :   <button className='w-100' onClick={(event) => handleSubmit(event)}>Create account</button>}

                            </form>    
                            <span className='d-flex justify-content-center align-items-center mt-4'>Already have an account?&nbsp;<button className={styles.link} onClick={handleLogin}>Sign in instead</button></span>
                            <div className={classNames(styles.customLink, "text-center")}><Link to="/home" onClick={() => dispatch(closeLoginModal())}>Go to Homepage</Link></div>
                        </> }
                    </Card>
                    <Login />
                </Col>
            </Row>
        </Container>
    )
}

export default Register;