import React, { useEffect, useState } from 'react'
import classNames from "classnames";
import styles from "../../pages/AdminDashboard/AdminDashboard.module.scss"
import history from '../../history';
import { toast } from 'react-toastify';
import AxiosService from '../../utils/AxiosService';
import LocalStorageService from '../../utils/auth_service/AuthServiceStorage';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong, faEdit, faInfoCircle, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Col, Form, FormControl, InputGroup, Modal, OverlayTrigger, Row, Spinner, Tooltip } from 'react-bootstrap';
import { CircleLoader } from '../Loader/CommonLoader';
import ArrayInput from '../../pages/AddNewJournal/ArrayInput';
import Pagination from '../PaginationComponent/Pagination';
import style from '../../pages/JournalDetails/JournalDetails.module.scss';

const AddInappropriateWord = () => {
    const localStorageService = LocalStorageService.getService();
    const authDetail = JSON.parse(localStorageService.getAuthDetails());
    const [isLoading, setIsLoading] = useState(false);
    const [addModal, setAddModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteLoading, setIsDeleteLoading] = useState(false);
    const [isBuildLoading, setBuildLoading] = useState(false);
    const [isBlocking, setIsBlocking] = useState(false);
    const [InappropriateWordData, setInappropriateWordData] = useState([]);
    const [editInappropriateWordForms, setEditInappropriateWordForms] = useState([]);
    const [total, setTotal] = useState(0);
    const [current, setCurrent] = useState(1)
    const [totalRecords, setTotalRecords] = useState(0)
    const [limit, setLimit] = useState(10)
    const [page, setPage] = useState(1)
    const [searchTerm, setSearchTerm] = useState("");
    const [projectId, setProjectId] = useState("");
    const [InappropriateWordForms, setInappropriateWordForms] = useState([])
    const [formValues, setFormValues] = useState({});
    const [deleteModalBody, setDeleteModalBody] = useState({ key: "", term: "" });
    const [saveLoading, setSaveLoading] = useState(false);
    const [editLoading, setEditLoading] = useState(false);
    const [addValidated, setAddValidated] = useState(false);
    const [EditValidated, setEditValidated] = useState(false);
    const [sort, setSort] = useState("desc")
    const [sortKey, setSortKey] = useState("createDate")

    const apiService = new AxiosService()
    const handleResetValue =()=>{
        setInappropriateWordForms([])
        formValues({})
        editInappropriateWordForms([])
    }
    const fetchInappropriateWord = (term, start, limit) => {
        setIsLoading(true);
        apiService.getAdminInappropriateWord(term, start, limit,sortKey,sort).then((res) => {
            setInappropriateWordData(res.data);
            setTotal(res.total)
            setTotalRecords(res.total)
            setIsLoading(false);
        }).catch(err => {
            setIsLoading(false);
            toast.error(err.response.data.message, {
                autoClose: 3000,
            })
        })
    }
    const handleDelete = () => {
        setIsDeleteLoading(true);
        apiService.deleteAdminInappropriateWord(deleteModalBody.key).then((res) => {
            setTimeout(() => {
                toast.success("Deleted successfully", {
                    autoClose: 3000,
                })
                setIsDeleteLoading(false);
                setDeleteModal(false)
                setTotal(0)
                fetchInappropriateWord(searchTerm, (current - 1) * limit, limit)
                handleResetValue() 
            }, [3000])       
        }).catch(err => {
            setIsDeleteLoading(false);
            toast.error(err.response.data.message, {
                autoClose: 3000,
            })
        })
    }

    const handleGoToPagination = () => {
        let totalPage = Math.ceil(totalRecords / limit)
        if (page > 0 && page <= totalPage) {
            setPage(parseInt(page))
            setCurrent(parseInt(page))
        }
        else {
            toast.error("Please enter valid page number!", { autoClose: 3000 })
        }
    }
    const handleCurrentPage = (page) => {
        setCurrent(page)
        setPage(page)
    }
    function handleSearchTerm(event) {
        event.preventDefault();
        setSearchTerm(event.target.value)
    }
    function handleSubmit(event) {
        event.preventDefault();
        setCurrent(1)
        fetchInappropriateWord(searchTerm, 0, limit)
    }
    function getAdminInappropriateWordForm() {
        apiService.getAdminInappropriateWordForm().then((res) => {
            setInappropriateWordForms(res);
        }).catch(err => {
            toast.error(err.response.data.message, {
                autoClose: 3000,
            })
        })
    }
    function getEditAdminInappropriateWordForm(id) {
        apiService.getEditAdminInappropriateWordForm(id).then((res) => {
            setEditInappropriateWordForms(res.form);
        }).catch(err => {
            toast.error(err.response.data.message, {
                autoClose: 3000,
            })
        })
    }
    const handleAddWord = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setAddValidated(true);
            toast.warning("Please fill in all the required fields.", {
                autoClose: 3000,
            })
        } else {
            setSaveLoading(true)
            apiService.saveAdminInappropriateWordForm(formValues).then((res) => {
                setTimeout(() => {
                toast.success("Saved successfully", {
                    autoClose: 3000,
                })
                setSaveLoading(false);
                setAddModal(false)
                fetchInappropriateWord(searchTerm, (current - 1) * limit, limit)
                handleResetValue()
            }, [3000]) 
            }).catch(err => {
                setSaveLoading(false);
                toast.error(err.response.data.message, {
                    autoClose: 3000,
                })
            })
        }
    }
    const handleEditWord = (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        if (form.checkValidity() === false) {
            event.stopPropagation();
            setEditValidated(true)
            toast.warning("Please fill in all the required fields.", {
                autoClose: 3000,
            })
        } else {
            setEditLoading(true)
            apiService.saveEditAdminInappropriateWordForm(projectId, formValues).then((res) => {
                setTimeout(() => {
                toast.success("Saved changes successfully", {
                    autoClose: 3000,
                })
                setEditLoading(false);
                setEditModal(false)
                fetchInappropriateWord(searchTerm, (current - 1) * limit, limit)
                handleResetValue()
            }, [3000]) 
            }).catch(err => {
                setEditLoading(false);
                toast.error(err.response.data.message, {
                    autoClose: 3000,
                })
            })
        }
    }
    const handleChange = (event) => {
        const obj = { ...formValues }
        event.preventDefault();
        const { name, value } = event.target;
        if (obj.hasOwnProperty(name)) {
            obj[name] = value;
        } else {
            obj[name] = value;
        }
        setFormValues(obj)
    }

    const handleArrayChange = (attribute, value) => {
        const obj = { ...formValues }
        if (obj.hasOwnProperty(attribute)) {
            obj[attribute] = value;
        } else {
            obj[attribute] = value;
        }
        setFormValues(obj)
    }
    function handleDeleteModal(id, word) {
        setDeleteModal(true)
        setDeleteModalBody({ key: id, term: word })
    }
    function handleEditModal(id) {
        setEditModal(true)
        setProjectId(id)
        getEditAdminInappropriateWordForm(id)
    }
    function handleReset() {
        setSearchTerm("")
        setCurrent(1)
        setPage(1)
        fetchInappropriateWord("", 0, 10)
    }
    const handleBuildLibrary=()=>{
        setBuildLoading(true)
         apiService.saveBuildTagger().then((res) => {
            toast.success(res.message, {
                autoClose: 3000,
            }) 
            setBuildLoading(false) 
        }).catch(err => {
            toast.error(err.response.data.message, {
                autoClose: 3000,
            })
            setBuildLoading(false) 
        })
    }
    useEffect(() => {
        if (authDetail?.authorities[0]?.authority !== "Admin") {
            history.push("/404")
        }
        fetchInappropriateWord(searchTerm, (current - 1) * limit, limit)
    }, [current])
    return (
        <>
            <div className="card shadow">
                <div className="d-flex flex-column flex-sm-row justify-content-between mb-3 bg-white p-3 border-bottom">
                    <div className="rounded">
                        <h5>Inappropriate Word List ({total})</h5>
                        <p>Add, edit or delete inappropriate words for inclusion in the journal.</p>
                    </div>
                    <div className="align-self-end align-self-sm-center">
                        <button className={classNames(styles.buttonPrimary, "cursor-pointer ml-2 text-nowrap")} type="button" onClick={() => { setAddModal(true); getAdminInappropriateWordForm() }}>
                            <FontAwesomeIcon icon={faPlus} /> Add a New Word
                        </button>
                    </div>
                </div>
                <div className="d-flex justify-content-between row mx-0">
                <div className="col-sm-12 col-md-8">
                        <form className="form-inline my-2 my-lg-0" onSubmit={handleSubmit} >
                            <input className="form-control mr-sm-2 w-50" type="search" placeholder="Search word" value={searchTerm} onChange={handleSearchTerm} />
                            <button className="my-2 my-sm-0" type="submit" disabled={searchTerm === ""}>Search</button>
                            <button className="mx-2" disabled={searchTerm === "" && current === 1} onClick={handleReset}>Reset</button>
                        </form>
                    </div>
                    <div className="d-flex col-sm-12 col-md-4 justify-content-end">
                    {
                                !isBuildLoading ? <button onClick={handleBuildLibrary}>Build Library</button> : (
                                    <button disabled={isBuildLoading}>
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        <span>Building...</span>
                                    </button>)
                            }
                </div></div>
                <div>
                    <table className="table my-3">
                        <thead className="thead-light">
                            <tr className="text-secondary">
                                <th width="7%">S.no</th>
                                <th width="40%">TERM</th>
                                <th width="40%">VARIANTS</th>
                                <th width="20%"></th>
                            </tr>
                        </thead>
                        <tbody>

                            {
                                isLoading ? <tr className="my-4"><td></td><td></td><td className="my-4 w-50"><CircleLoader /></td><td></td></tr> : InappropriateWordData.length === 0 ?<tr  className="mt-3"><td></td><td></td><td>No Inappropriate word found!</td><td></td></tr> : InappropriateWordData.map((InappropriateWord, i) => (
                                    <tr key={i}>
                                        <td>{(i+1)+(current - 1) * limit}.</td>
                                        <td>{InappropriateWord.name}</td>
                                        <td >
                                            {InappropriateWord.variants.length === 0 ? "---" : InappropriateWord.variants.join("; ")}
                                        </td>
                                        <td className="text-right">
                                            <OverlayTrigger
                                                key="journalKeywords"
                                                placement="top"
                                                overlay={
                                                    <Tooltip id={`tooltip-journal`}>
                                                        Edit inappropriate word
                                                    </Tooltip>
                                                }
                                            >
                                                <button className={classNames(styles.buttonPrimary, "cursor-pointer mx-3")} type="button" onClick={() => handleEditModal(InappropriateWord.id)} ><FontAwesomeIcon icon={faEdit} /></button>
                                            </OverlayTrigger>
                                            <OverlayTrigger
                                                key="InappropriateWord"
                                                placement="top"
                                                overlay={
                                                    <Tooltip id={`tooltip-journal`}>
                                                        Delete inappropriate word
                                                    </Tooltip>
                                                }
                                            >
                                                <button className={classNames(styles.redButton, "cursor-pointer my-mb-0")} type="button" onClick={() => handleDeleteModal(InappropriateWord.id, InappropriateWord.formattedName)} >{deleteLoading && InappropriateWord?.checked ? <Spinner animation='grow' size='xs' /> : <FontAwesomeIcon icon={faTrash} />}</button>
                                            </OverlayTrigger>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>

                    </table>
                    <div className="float-right my-3 px-2">
                        <Pagination
                            className="p-1 my-1"
                            currentPage={current}
                            totalCount={totalRecords}
                            pageSize={limit}
                            onPageChange={(page) => handleCurrentPage(page)}
                            handleGoToPagination={handleGoToPagination}
                            pageNo={page}
                            setPageNo={setPage}
                        />
                    </div>
                </div>
                <Modal show={addModal} onHide={() => { setAddModal(false); setInappropriateWordForms([]); setAddValidated(false) }}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Header closeButton>
                        <div>
                            <h5>Add Inappropriate Word</h5>
                            <p className='fs-1'>Add a word inappropriate for inclusion in the journal.</p>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <Form noValidate validated={addValidated} onSubmit={handleAddWord}>
                            <Row className='mt-3'>
                                {
                                    InappropriateWordForms.length === 0 ? <Col className="text-center my-4"><CircleLoader /></Col> : InappropriateWordForms.map((rules, i) => (
                                        <Form.Group as={Col} md="12" controlId={`validationCustom${i}`} key={i}>
                                            <Form.Label className="mr-2 text-capitalize">{rules.name}{rules.mandatory && <span className="text-danger">*</span>}</Form.Label>
                                            {
                                                rules.dataType === "Array" && <OverlayTrigger
                                                    key="journalKeywords"
                                                    placement="top"
                                                    overlay={
                                                        <Tooltip id={`tooltip-journal`}>
                                                            Press ‘Enter’ to add term/ terms
                                                        </Tooltip>
                                                    }
                                                >
                                                    <FontAwesomeIcon icon={faInfoCircle} />
                                                </OverlayTrigger>
                                            }
                                            {rules.dataType === "Array" ? <ArrayInput rules={rules} handleArrayChange={handleArrayChange} intialValue={rules.value} isDisable={false} setIsBlocking={setIsBlocking}/> : rules.type === "textarea" ?
                                                <FormControl
                                                    required={rules.mandatory}
                                                    aria-label={rules.name}
                                                    placeholder={rules.name}
                                                    name={rules.attribute}
                                                    defaultValue={rules.value}
                                                    aria-describedby={`basic-addon${i}`}
                                                    as="textarea"
                                                    onChange={(e) => handleChange(e)}
                                                /> : <FormControl
                                                    required={rules.mandatory}
                                                    aria-label={rules.name}
                                                    placeholder={rules.name}
                                                    name={rules.attribute}
                                                    defaultValue={rules.value}
                                                    aria-describedby={`basic-addon${i}`}
                                                    type={rules.type}
                                                    onChange={(e) => handleChange(e)}
                                                />
                                            }
                                            <Form.Control.Feedback type='invalid'>
                                                {`Please provide a valid ${rules.name.toLowerCase()}.`}
                                            </Form.Control.Feedback>

                                        </Form.Group>
                                    ))
                                }
                            </Row>
                            {
                                !saveLoading ? <button type='submit'>Save Term </button> : (
                                    <button disabled={saveLoading}>
                                        <Spinner
                                            as="span"
                                            animation="grow"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        <span>Saving...</span>
                                    </button>)
                            }
                        </Form>
                    </Modal.Body>
                </Modal>
                <Modal show={editModal} onHide={() => { setEditModal(false); setEditInappropriateWordForms([]) }}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Header closeButton>
                        <div>
                            <h5>Edit Inappropriate Word</h5>
                            <p className='fs-1'>Edit an existing word that is inappropriate to be included in a journal</p>
                        </div>
                    </Modal.Header>
                    <Modal.Body>  <Form noValidate validated={EditValidated} onSubmit={handleEditWord}>
                        <Row className='mt-3'>
                            {
                                editInappropriateWordForms.length === 0 ? <Col className="text-center my-4"><CircleLoader /></Col> : editInappropriateWordForms.map((rules, i) => (
                                    <Form.Group as={Col} md="12" controlId={`validationCustom${i}`} key={i}>
                                        <Form.Label className="mr-2 text-capitalize">{rules.name}{rules.mandatory && <span className="text-danger">*</span>}</Form.Label>
                                        {
                                            rules.dataType === "Array" && <OverlayTrigger
                                                key="journalKeywords"
                                                placement="top"
                                                overlay={
                                                    <Tooltip id={`tooltip-journal`}>
                                                        Press ‘Enter’ to add term/ terms
                                                    </Tooltip>
                                                }
                                            >
                                                <FontAwesomeIcon icon={faInfoCircle} />
                                            </OverlayTrigger>
                                        }
                                        {rules.dataType === "Array" ? <ArrayInput rules={rules} handleArrayChange={handleArrayChange} intialValue={rules.value} isDisable={false} /> : rules.type === "textarea" ?
                                            <FormControl
                                                required={rules.mandatory}
                                                aria-label={rules.name}
                                                placeholder={rules.name}
                                                name={rules.attribute}
                                                defaultValue={rules.value}
                                                aria-describedby={`basic-addon${i}`}
                                                as="textarea"
                                                onChange={(e) => handleChange(e)}
                                            /> : <FormControl
                                                required={rules.mandatory}
                                                aria-label={rules.name}
                                                placeholder={rules.name}
                                                name={rules.attribute}
                                                defaultValue={rules.value}
                                                aria-describedby={`basic-addon${i}`}
                                                type={rules.type}
                                                onChange={(e) => handleChange(e)}
                                            />
                                        }
                                        <Form.Control.Feedback type='invalid'>
                                            {`Please provide a valid ${rules.name.toLowerCase()}.`}
                                        </Form.Control.Feedback>

                                    </Form.Group>
                                ))
                            }
                        </Row>
                        {
                            !editLoading ? <button type='submit'>Save changes </button> : (
                                <button disabled={editLoading}>
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    <span>Saving...</span>
                                </button>)
                        }
                    </Form>
                    </Modal.Body>
                </Modal>
                <Modal show={deleteModal} onHide={() => setDeleteModal(false)} aria-labelledby="contained-modal-title-vcenter"
                    centered>
                    <Modal.Header closeButton>
                        <div>
                            <h5>Delete Inappropriate Word</h5>
                            <p className='fs-1'>Delete an existing word that is inappropriate to be included in a journal</p>
                        </div>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                            <p>Inappropriate word / term</p>
                            <h5>{deleteModalBody.term}</h5>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            !deleteLoading ? <button onClick={handleDelete}>Delete term</button> : (
                                <button disabled={deleteLoading}>
                                    <Spinner
                                        as="span"
                                        animation="grow"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                    <span>Deleting...</span>
                                </button>)
                        }
                    </Modal.Footer>
                </Modal>

            </div>
        </>
    )
}

export default AddInappropriateWord
