import React, { useEffect } from 'react';
import { Row, Col, Modal, Spinner, Carousel, Card } from 'react-bootstrap';
import { hasJournalFilter, hasPublicationYearFilter, hasSubjectFilter } from '../../store/actions/FilterConfigAction';
import { useDispatch, useSelector } from 'react-redux';
import {PageView} from '../../tracking';
import classNames from 'classnames';
import styles from './Home.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faScroll, faUserPlus, faFileCircleCheck, faTable, faAngleRight, faArrowRight  } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { closeLoginModal } from '../../store/actions/LoginModalActions';
import LocalStorageService from '../../utils/auth_service/AuthServiceStorage'
import { useState } from 'react';
import { authLogout } from '../../utils/auth_service/AuthServiceHelper';
import { toast } from 'react-toastify';
import { tokenExpired } from '../../store/actions/RefreshTokenAction';
import history from '../../history';
import step1Icon from './assets/step1.svg'
import step2Icon from './assets/step2.svg'
import step3Icon from './assets/step3.svg'
import step4Icon from './assets/step4.svg'
import journalFinderButton from './assets/homepage button - journal finder.svg'
import formatManuscriptButton from './assets/homepage button - format manuscript.svg'
import titleBackground from './assets/titleBackground.svg'

function Home1() {
	const dispatch = useDispatch();
    const loginModalToggle = useSelector(state => state.login.loginModalToggle);
	const localService = LocalStorageService.getService()
	const authDetails = JSON.parse(localService.getAuthDetails())
	const [loggedInRegistermodal, setLoggedInRegisterModal] = useState(false)
	const [logoutModalLoader, setLogoutModalLoader] = useState(false)

	const loginCheck = () => {
		if(authDetails !== null) {
			setLoggedInRegisterModal(true)
		}
	}

	const loggedInRegisterAction = () => {
		const params = new URLSearchParams();
        params.append("access_token", localService.getAccessToken());
        const config = {
            url: "api/v1/auth/getLogout",
            method: "post",
            data: params
        };
		setLogoutModalLoader(true)
		authLogout(config).then(response => {
			localService.removeTokens()
			toast.success("Logged out!", {autoClose: 3000})
			dispatch(tokenExpired())
			history.push("/register")
			setLogoutModalLoader(false)
		}, err => {
			toast.error("Something went wrong!", {autoClose: 3000})
			setLogoutModalLoader(false)
		})
	}
 
	useEffect(() => {
		PageView();
		dispatch(hasSubjectFilter());
		dispatch(hasJournalFilter());
		dispatch(hasPublicationYearFilter());
	}, [])
	
    useEffect(() => {
		if(loginModalToggle) {
			dispatch(closeLoginModal())
		}
    }, []);

	const CardItem = ({icon, title, description, path, pathName, action}) => (
		<div className={classNames(styles.cardStyle,"card my-2 shadow-sm")} style={{width: "100%", height:"15rem"}}>
			<div className="card-body pb-0">
				<FontAwesomeIcon icon={icon} className={styles.iconStyle} />
				<h4 className="card-title mt-2">{title}</h4>
				<p className="card-text text-muted">{description}</p>
			</div>
			<div className='card-footer bg-white border-top-0 h6'>
				{ action && authDetails !== null ? 
					<span onClick={() => action()} className={styles.spanText}>{pathName} <FontAwesomeIcon icon={faAngleRight} /></span>
				:
					<Link to={path} className={styles.linkText}>{pathName} <FontAwesomeIcon icon={faAngleRight} /></Link>
				}
			</div>
		</div>
	)

	return (
		<div className='rounded container-xl'>
			<Col className={classNames(styles.fontLato, "mb-5 p-0 m-0")}>
				<div className={classNames(styles.titleBcakground, 'flex-wrap d-flex flex-column')}>
					
					<div className={classNames(styles.headingDivHeight,'d-none d-md-block px-4 mb-3')}> {/* Large screen text */}
						<h1 className='pt-3 mb-3'>
							<span className={classNames(styles.carouselHeading)}>Create and publish your <br/> manuscripts, </span> <span className={styles.hightlightText}> easily!</span>
						</h1>
						<p className={classNames(styles.carouselText, 'p-0 m-0')}>AuthorEase helps you find the right publication for your manuscripts</p>
						<p className={classNames(styles.carouselText, 'p-0 m-0')}>through our intuitive formatting tool, streamlining the whole process.</p>
					</div>
					
					<div className={classNames(styles.headingDivHeightMobile, 'd-sm-block d-md-none px-4')}> {/* Small screen text */}
						<h2 className='pt-5 mb-4'>
							<span className={classNames(styles.carouselHeading)}>Create and publish your manuscripts, </span> <span className={styles.hightlightText}> easily!</span>
						</h2>
						<p className={classNames(styles.carouselText, 'p-0 m-0')}>AuthorEase helps you find the right publication for your manuscripts through our intuitive formatting tool, streamlining the whole process.</p>
					</div>

					{/* <div className='d-sm-flex d-md-none p-0 px-0 my-5'>
						<img alt="Homepage Find Publication logo" align="right" src={titleBackground} height="298px"/>
					</div> */}
					
					<Row className={classNames('px-4')}> {/* Cards */}
						<Col lg={5} md={6} sm={12} className='ml-3 ml-md-0 my-5 my-md-0'>
							<Row className='text-center align-items-start p-2 mb-2'>
								<img alt="Homepage Journal finder logo" className={styles.btnImgMaxHeight} src={journalFinderButton}/>
							</Row>
							<Row>
								<Col lg={11} md={12} sm={12} className='d-flex flex-column'>
									<h3 className={classNames("mt-2",styles.hightlightText)}>Find your journal</h3>
									<span className={classNames(styles.carouselText, 'pb-3')}>With our easy to use journal finder, find the right journal for your manuscript.</span>
									<div><button className={styles.homePageButton} onClick={() => {history.push("/search")}}>Start finding your journal <FontAwesomeIcon icon={faArrowRight} className='ml-2'/></button></div>
								</Col>
							</Row>
						</Col>
						<Col lg={5} md={6} sm={12} className='ml-3 ml-md-0 mb-5 mb-md-0'>
							<Row className='text-center align-items-start p-2 mb-2'>
								<img alt="Homepage Format Manuscript logo" className={styles.btnImgMaxHeight} src={formatManuscriptButton}/>
							</Row>
							<Row>
								<Col lg={11} md={12} sm={12} className='d-flex flex-column'>
									<h3 className={classNames("mt-2",styles.hightlightText)}>Format your manuscript</h3>
									<span className={classNames(styles.carouselText, 'pb-3')}>Our intuitive manuscript editor checks your manuscript for errors and submits them.</span>
									<div><button className={styles.homePageButton} onClick={() => {history.push("/select-journal?sort=Rank")}}>Create your manuscript <FontAwesomeIcon icon={faArrowRight} className='ml-2'/></button></div>
								</Col>
							</Row>
						</Col>
					</Row>
				
				</div>
			</Col>
			<Col className={classNames(styles.fontLato, "align-content-center flex-wrap d-flex flex-column")}>
				<Row className='mb-3 d-none d-md-block mt-5'>
					<h1 className='text-center w-100'>Our manuscript formatter has been <br/> designed <span className={styles.quoteDivText}>intuitively</span></h1>
				</Row>
				<Row className='my-5 d-sm-block d-md-none'>
					<h1 className='text-center w-100'>Our manuscript formatter has been <br/> designed <span className={styles.quoteDivText}>intuitively</span></h1>
				</Row>
				<Row className='w-100'>
					<Col lg={3} md={6} xs={12} className='mt-5'>
						{/* <Row className='py-3'><span className={styles.nameBadge}>1</span></Row> */}
						<Row className='pt-3 d-flex justify-content-center'><Col md={8} className='d-flex justify-content-center'><img alt="How to use AuthorEase step 1" className={styles.journyDivIcons} src={step1Icon}/></Col></Row>
						<Row className={styles.journyDivText}><span>Our free journal finder helps you</span><span>find the best journal suited for</span><span>your manuscript.</span></Row>
					</Col>
					<Col lg={3} md={6} xs={12} className='mt-5'>
						{/* <Row className='py-3'><span className={styles.nameBadge}>2</span></Row> */}
						<Row className='pt-3 d-flex justify-content-center'><Col md={8} className='d-flex justify-content-center'><img alt="How to use AuthorEase step 2" className={styles.journyDivIcons} src={step2Icon}/></Col></Row>
						<Row className={styles.journyDivText}><span>Choose the right template from</span><span>amongst many for your</span><span>manuscript.</span></Row>
					</Col>
					<Col lg={3} md={6} xs={12} className='mt-5'>
						{/* <Row className='py-3'><span className={styles.nameBadge}>3</span></Row> */}
						<Row className='pt-3 d-flex justify-content-center'><Col md={8} className='d-flex justify-content-center'><img alt="How to use AuthorEase step 3" className={styles.journyDivIcons3} src={step3Icon}/></Col></Row>
						<Row className={styles.journyDivText}><span>Let the manuscript formatter help</span><span>you in the formatting process,</span> <span> step by step.</span></Row>
					</Col>
					<Col lg={3} md={6} xs={12} className='mt-5'>
						{/* <Row className='py-3'><span className={styles.nameBadge}>4</span></Row> */}
						<Row className='pt-3 d-flex justify-content-center'><Col md={8} className='d-flex justify-content-center'><img alt="How to use AuthorEase step 4" className={styles.journyDivIcons} src={step4Icon}/></Col></Row>
						<Row className={styles.journyDivText}><span>The dashboard helps you track</span><span>your manuscript journey all the</span> <span>way to publication.</span></Row>
					</Col>
				</Row>
				<Row className='justify-content-center mt-5 mb-5 pt-5'>
					<button className={styles.homePageButton} onClick={() => {history.push("/select-journal?sort=Rank")}}>GET STARTED NOW</button>
				</Row>
			</Col>
			<Col className={classNames(styles.fontLato, 'm-0 py-5 px-0', 'align-content-center flex-wrap d-flex flex-column')}>
				<Row className='d-flex flex-row flex-wrap align-items-center'>
					<Col lg={7} sm={12} className={classNames(styles.fontLato, 'flex-column flex-wrap mt-5 pl-5 d-none d-md-block')}> {/*Large Screen */}
						<h2 className='w-75 mb-4'>Brought to you by the trusted global leader in Life Sciences</h2>
						<div className={classNames(styles.quoteTextLineHeight, 'w-75 mb-4')}>AuthorEase is built by Molecular Connections, a company established in 2002 with a mission to simplify business processes and accelerate research through data and technology-driven solutions.</div>
					</Col>
					<Col lg={5} sm={12} className={classNames(styles.quoteDivText, "mt-5 flex-column flex-wrap pl-5 d-none d-md-block")}> {/*Large Screen */}
						<h2 className=' font-italic font-weight-light'>
							<b>An innovative publication tool, meticulously crafted to streamline workflows and maximize time savings.</b>
						</h2>
					</Col>

					<Col lg={7} sm={12} className={classNames(styles.fontLato, 'd-flex flex-column flex-wrap mt-5 d-sm-block d-md-none')}> {/*Small Screen */}
						<h2 className='mb-4'>Brought to you by the trusted global leader in Life Sciences</h2>
						<div className={classNames(styles.quoteTextLineHeight, 'mb-4')}>AuthorEase is built by Molecular Connections, a company established in 2002 with a mission to simplify business processes and accelerate research through data and technology-driven solutions.</div>
					</Col>
					<Col lg={5} sm={12} className={classNames(styles.quoteDivText, "mt-5 d-flex flex-column flex-wrap d-sm-block d-md-none")}> {/*Small Screen */}
						<h2 className=' font-italic font-weight-light'>
							<b>An innovative publication tool, meticulously crafted to streamline workflows and maximize time savings.</b>
						</h2>
					</Col>
					<Col lg={6} className='d-flex flex-column flex-wrap mt-md-3 mt-5 pl-5 pb-5'>
						<div className='w-100'><button className={styles.homePageButton} onClick={() => {history.push("/about-authorease")}}>FIND OUT MORE</button></div>
					</Col>
				</Row>
			</Col>
			<Modal show={loggedInRegistermodal} dialogClassName={classNames(styles.fontLato, 'w-25')} onHide={() => {setLoggedInRegisterModal(false)}}>
				<Modal.Header closeButton className="h6 d-flex align-items-center">Register new user</Modal.Header>
				<Modal.Body>
					<p>You are already logged in. Are you sure you want to create a new account?</p>
					<p>Please note that on proceeding with new account, current user will be logged out.</p>
					<button className='w-100 btn-outline' disabled={logoutModalLoader} onClick={() => {setLoggedInRegisterModal(false)}}>No</button>
					<button className='w-100 mt-2' disabled={logoutModalLoader} onClick={() => {loggedInRegisterAction()}}>
						{logoutModalLoader ? <Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> : "Yes"}
					</button>
				</Modal.Body>
			</Modal>
		</div>
	)
}

export default Home1;